import { Formik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { userRegister } from "../../redux/actions/userAuth";
import toast from "react-hot-toast";
import hideEye from "../../images/hide_password.svg";
import showEye from "../../images/show_password.svg"

const NewAccount = ({ data, onNext }) => {
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <>
      <h2 className="h-22">Create a New Account</h2>

      <span>
        Already Registered? <Link to="/SignIn">Log In</Link>
      </span>
      <handleChangePasword />
      <Formik
        initialValues={{
          email: "",
          password: "",
          confirmPassword: "",
        }}
        validate={(values) => {
          const errors = {};

          if (!values.email) {
            errors.email = "Required*";
          }
          if (!values.password) {
            errors.password = "Required";
          }
          if (!values.confirmPassword) {
            errors.confirmPassword = "Required";
          } else if (values.password !== values.confirmPassword) {
            errors.confirmPassword = "Passwords doesn't match";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          let userData = { ...data, ...values };
          const formData = new FormData();
          for (let key in userData) {
            if (key == "postcode") {
              formData.append("post_code", userData[key]);
            } else {
              formData.append(key, userData[key]);
            }
          }
          formData.append("web", 1);
          dispatch(userRegister(formData)).then((data) => {
            if (data?.payload?.status_code == 200) {
              resetForm();
              toast.success(data?.payload?.message);
              navigate(`/sign-up?id=${data?.payload?.data?._id}`);
              onNext(values);
            } else {
              toast.error(data?.payload?.message)
            }
            setSubmitting(false);
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form
            onSubmit={(e) => {
              // setValidateValue(true);
              handleSubmit(e);
            }}
          >
            <div class="form-group">
              <label for="exampleInputEmail1">Email</label>
              <input
                type="text"
                className="form-control"
                placeholder="Type your email here..."
                name="email"
                onChange={handleChange}
                value={values.email}
              />
              <span className="formik-errors">
                {errors.email && touched.email && errors.email}
              </span>
            </div>
            <div class="form-group mt-4 pt-2 position-relative">
              <label>Password</label>
              <input
                type={showPassword ? 'text' : 'password'}
                className="form-control"
                placeholder="********"
                name="password"
                onChange={handleChange}
                value={values.password}
              />
              <span className="input-eye-show" onClick={toggleShowPassword}>
                {showPassword ? <img src={showEye} /> :
                  <img src={hideEye} />}
              </span>
              <span className="formik-errors">
                {errors.password && touched.password && errors.password}
              </span>
            </div>
            <div class="form-group mt-4 pt-2 position-relative">
              <label>Confirm Password</label>
              <input
                type={showConfirmPassword ? 'text' : 'password'}
                className="form-control"
                placeholder="********"
                name="confirmPassword"
                onChange={handleChange}
                value={values.confirmPassword}
              />
              <span className="input-eye-show"  onClick={toggleShowConfirmPassword}>
                {showConfirmPassword ? <img src={showEye} /> :
                  <img src={hideEye} />}
              </span>
              <span className="formik-errors">
                {errors.confirmPassword &&
                  touched.confirmPassword &&
                  errors.confirmPassword}
              </span>
            </div>
            <button type="submit" class="common-btn">
              CONTINUE
            </button>
          </form>
        )}
      </Formik>
    </>
  );
};

export default NewAccount;
