import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Form, Link, useNavigate, useParams } from "react-router-dom";
// import ProgressBar from "@ramonak/react-progress-bar";
import { Rating } from "react-simple-star-rating";
import Modal from "react-bootstrap/Modal";
import Navbar from "react-bootstrap/Navbar";
import CreatorLayout from "../elements/CreatorLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  editCourse,
  getChapters,
  getCourseById,
} from "../redux/actions/userAction";
import VideoThumbnail from "react-video-thumbnail";
import ParseDuration from "../components/ParseDuration";
import parseDuration from "../utils/parseDuration ";
import { Formik } from "formik";
import toast from "react-hot-toast";
import { DndProvider } from "react-dnd";
import Example from "../components/DraggableCertificate/Example";
import { HTML5Backend } from "react-dnd-html5-backend";
import html2canvas from "html2canvas";
export default function SettingsCourseView() {
  return (
    <CreatorLayout>
      <Container>
        <div className="profile-top-box">
          <div className="box-left">
            <div className="backpage-arrow">
              <Link to="/settings-courses">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                  >
                    <path
                      d="M10 20.0003L8.93934 21.061L7.87868 20.0003L8.93934 18.9397L10 20.0003ZM30 18.5003C30.8284 18.5003 31.5 19.1719 31.5 20.0003C31.5 20.8288 30.8284 21.5003 30 21.5003V18.5003ZM15.606 27.7277L8.93934 21.061L11.0607 18.9397L17.7273 25.6063L15.606 27.7277ZM8.93934 18.9397L15.606 12.273L17.7273 14.3943L11.0607 21.061L8.93934 18.9397ZM10 18.5003H30V21.5003L10 21.5003L10 18.5003Z"
                      fill="#40413A"
                    />
                  </svg>
                </span>
                Back to the previous page.
              </Link>
            </div>
          </div>
        </div>
        <div className="edit-course-box">
          <div className="edit-cou-top">
            <div className="box-left">
              <h2>Course Overview</h2>
            </div>
          </div>
          <hr />
          <div className="edit-main">
            <button style={{ border: "none", background: "none" }}>
              <img src={require("../images/edit-btn.svg").default} />
            </button>
          </div>
          <div className="row">
            <div className="col-lg-5">
              <div className="edit-crse-tp-img">
                <img src={require("../images/user-img.png")} />
                <p>opipipioop</p>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="course-right-sec">
                <h3>Finishing The Brow</h3>
                <p>Category: Brows</p>

                <p>
                  This is the course description. This is the course
                  description. This is the course description. This is the
                  course description. This is the course description. This is
                  the course description. This is the course description. This
                  is the course description.{" "}
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className="edit-cou-top">
            <div className="box-left">
              <h2>Add Chapters</h2>
              <p className="m-0">Create and manage course here</p>
            </div>
            <div className="box-right">
              <div className="add-curse">
                <button type="buttom" className="add-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                  >
                    <path
                      d="M12.5 6.25L12.5 18.75"
                      stroke="#8B8B8B"
                      stroke-width="2"
                      stroke-linecap="square"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M18.75 12.5L6.25 12.5"
                      stroke="#8B8B8B"
                      stroke-width="2"
                      stroke-linecap="square"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <div className="mt-3">
            <div className="add-chapters-list ">
              <div className="left-s">
                <img src={require("../images/Videoimg1.png")} />
              </div>
              <div className="right-s">
                <h3>iopp</h3>
                jkh
              </div>
            </div>
          </div>
          <hr />
          <div className="edit-cou-top">
            <div className="box-left">
              <h2>Add a Certificate</h2>
              <p className="m-0">
                Create a certificate for successful completion of your course
              </p>
            </div>
          </div>
          <div className="create-certificate">
            <Link to="#" className="common-btn">
              Create Certificate
            </Link>
          </div>
        </div>
      </Container>
    </CreatorLayout>
  );
}
