import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Routes, Route, useNavigate } from "react-router-dom";
export default function Home() {
  const options = {
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,

    smartSpeed: 1000,

    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  const optionsNew = {
    responsiveClass: true,
    nav: true,
    dots: true,
    autoplay: false,
    smartSpeed: 1000,
    margin: 30,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  const navigate = useNavigate();

  const navigateHome = () => {
    // 👇️ navigate to /
    navigate("/sign-up");
  };
  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary landing-nav">
        <Container fluid>
          <Navbar.Brand href="#home" className="logo-main-nav">
            <img src={require("../images/logo-mian.png")} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Link to="/home">Home</Link>
              <Link href="#link">Contact Us</Link>
            </Nav>
            <div className="main-login-btn">
              <Link to="/SignIn">Login</Link>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="banner-section">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="banner-img-left">
                <img src={require("../images/banner-img.png")} />
              </div>
            </Col>
            <Col lg={6} className="d-flex align-items-center">
              <div className="banner-right-part">
                <h1>
                  The best online course platform for creators and Learners
                </h1>
                <p>
                  Online courses are an engaging way to scale your learning,
                  digital business and build a lasting source of income.
                </p>
                <Form className="banner-form">
                  <Form.Group className="" controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Type your email here..."
                    />
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={navigateHome}
                  >
                    Join
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
        <hr className="banner-line" />
      </div>
      <div className="course-section">
        <Container>
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="banner-right-part">
                <h2>Find the most popular courses</h2>
                <p>
                  Aspire and Accomplish! Find your interests by browsing our
                  online courses. Upgrade your skills, level up in life.
                </p>
              </div>
            </Col>
            <Col lg={6}>
              <Row>
                <Col lg={6}>
                  <div className="course-img-left">
                    <img src={require("../images/course-one.png")} />
                    <img src={require("../images/course-three.png")} />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="course-img-right">
                    <img src={require("../images/course-two.png")} />
                    <img src={require("../images/course-four.png")} />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <hr />
      <div className="features-section">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="features-heading">
                <h2>Creator and Learner-first features</h2>
                <p>
                  Learning and mastering new skills becomes seamless when you
                  have access to top-notch features and course creation tools
                  tailored to meet the diverse needs of every learner and
                  creator.
                </p>
              </div>
            </Col>
            <Col lg={12} className="mb-5 respo-m-set">
              <Row>
                <Col lg={6}>
                  <div className="features-first-img">
                    <img src={require("../images/creator-left-img.png")} />
                  </div>
                </Col>
                <Col lg={6} className="d-flex align-items-center">
                  <div className="features-first-text">
                    <h2>Flexibility at your fingertips</h2>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col lg={12} className="mt-5 respo-m-set">
              <Row>
                <Col lg={6} className="d-flex align-items-center">
                  <div className="features-second-text">
                    <h3>Growth made easy</h3>
                    <p className="mb-5">
                      Lorem Ipsum is simply dummy text of the
                      <br /> printing and typesetting
                    </p>
                    <h2>Meaningful audience engagement</h2>
                    <p>
                      Lorem Ipsum is simply dummy text of the
                      <br /> printing and typesetting
                    </p>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="features-first-img">
                    <img src={require("../images/creator-right-img.png")} />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <hr />
      <div className="features-section">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="features-heading">
                <h2>Course creator spotlight</h2>
                <p>
                  Get inspiration from members of the Teachable community and
                  discover their online courses.
                </p>
              </div>
              <div className="features-slide">
                <OwlCarousel
                  className="owl-theme"
                  loop={true}
                  margin={30}
                  items={5}
                  {...options}
                  nav={true}
                >
                  <div class="item">
                    <div className="spotlight-card">
                      <img src={require("../images/course-creator-one.png")} />
                      <div className="text-black-bg">
                        <div className="spotlight-name">
                          <h3>Jamilia Arkell</h3>
                          <p className="m-0">1st Place</p>
                        </div>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <div className="spotlight-card">
                      <img src={require("../images/course-creator-two.png")} />
                      <div className="text-black-bg">
                        <div className="spotlight-name">
                          <h3>Jamilia Arkell</h3>
                          <p className="m-0">1st Place</p>
                        </div>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <div className="spotlight-card">
                      <img
                        src={require("../images/course-creator-three.png")}
                      />
                      <div className="text-black-bg">
                        <div className="spotlight-name">
                          <h3>Jamilia Arkell</h3>
                          <p className="m-0">1st Place</p>
                        </div>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </Col>
          </Row>
        </Container>
        <hr className="banner-line" />
      </div>

      <div className="features-section">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="comment-slide">
                <OwlCarousel
                  className="owl-theme"
                  loop={true}
                  margin={30}
                  items={5}
                  {...optionsNew}
                  nav={true}
                >
                  <div class="item">
                    <Row>
                      <Col lg={5}>
                        <div className="comment-pro-img">
                          <img src={require("../images/commant-img.png")} />
                        </div>
                      </Col>
                      <Col lg={7}>
                        <div className="comment-pro-text">
                          <p>
                            Buff is a super easy-to-use platform that helps me
                            create and launch my online courses quickly. The
                            user-friendly interface makes the process feel
                            effortless, allowing me to focus on creating
                            valuable content for my students. Thanks to Buff,
                            I've been able to share my knowledge and connect
                            with students from all over the world. I highly
                            recommend it to anyone looking to create online
                            courses to learn or teach."
                          </p>
                          <h4>Funnel Gorgeous</h4>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div class="item">
                    <Row>
                      <Col lg={5}>
                        <div className="comment-pro-img">
                          <img src={require("../images/commant-img.png")} />
                        </div>
                      </Col>
                      <Col lg={7}>
                        <div className="comment-pro-text">
                          <p>
                            Buff is a super easy-to-use platform that helps me
                            create and launch my online courses quickly. The
                            user-friendly interface makes the process feel
                            effortless, allowing me to focus on creating
                            valuable content for my students. Thanks to Buff,
                            I've been able to share my knowledge and connect
                            with students from all over the world. I highly
                            recommend it to anyone looking to create online
                            courses to learn or teach."
                          </p>
                          <h4>Funnel Gorgeous</h4>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div class="item">
                    <Row>
                      <Col lg={5}>
                        <div className="comment-pro-img">
                          <img src={require("../images/commant-img.png")} />
                        </div>
                      </Col>
                      <Col lg={7}>
                        <div className="comment-pro-text">
                          <p>
                            Buff is a super easy-to-use platform that helps me
                            create and launch my online courses quickly. The
                            user-friendly interface makes the process feel
                            effortless, allowing me to focus on creating
                            valuable content for my students. Thanks to Buff,
                            I've been able to share my knowledge and connect
                            with students from all over the world. I highly
                            recommend it to anyone looking to create online
                            courses to learn or teach."
                          </p>
                          <h4>Funnel Gorgeous</h4>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </OwlCarousel>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <hr />

      <div className="features-section">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="change-your-future">
                <h2>
                  Change your future with one <br />
                  click
                </h2>
                <Link to="">Start</Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <hr />
      <div className="footer-section">
        <Container>
          <Row>
            <Col lg={8}>
              <div className="footer-left-side">
                <img src={require("../images/logo-mian.png")} />
                <p>
                  Join the more than 100,000 creators and learners who use Buff
                  to share and gain knowledge. Easily learn and sell courses,
                  and digital downloads with our powerful platform.
                </p>
              </div>
            </Col>
            <Col lg={4}>
              <div className="footer-right-side">
                <ul>
                  <li>
                    <p>Terms of use</p>
                  </li>
                  <li>
                    <p>Privacy Policy</p>
                  </li>
                  <li>
                    <p>Contact Us</p>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col lg={8}>
              <div className="footer-left-side">
                <p>
                  Copyright © 2024 Buff Education, Inc. All rights reserved.
                </p>
              </div>
            </Col>
            <Col lg={4}>
              <div className="footer-socail">
                <ul>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="29"
                      viewBox="0 0 28 29"
                      fill="none"
                    >
                      <path
                        d="M22.75 0C24.1962 0 25.4327 0.531793 26.4596 1.59538C27.4865 2.65896 28 3.93967 28 5.4375V23.5625C28 25.0603 27.4865 26.341 26.4596 27.4046C25.4327 28.4682 24.1962 29 22.75 29H19.3229V17.7663H22.9505L23.4974 13.3861H19.3229V10.5918C19.3229 9.88694 19.4657 9.35829 19.7513 9.00586C20.0369 8.65343 20.5929 8.47721 21.4193 8.47721L23.6432 8.45833V4.55013C22.8776 4.43685 21.796 4.38021 20.3984 4.38021C18.7457 4.38021 17.424 4.88368 16.4336 5.89062C15.4431 6.89757 14.9479 8.31988 14.9479 10.1576V13.3861H11.3021V17.7663H14.9479V29H5.25C3.80382 29 2.56727 28.4682 1.54036 27.4046C0.513455 26.341 0 25.0603 0 23.5625V5.4375C0 3.93967 0.513455 2.65896 1.54036 1.59538C2.56727 0.531793 3.80382 0 5.25 0H22.75Z"
                        fill="black"
                      />
                    </svg>
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="29"
                      height="29"
                      viewBox="0 0 29 29"
                      fill="none"
                    >
                      <path
                        d="M19.9264 0.15625H9.22363C6.85818 0.15625 4.58961 1.09592 2.91698 2.76855C1.24436 4.44117 0.304688 6.70974 0.304688 9.07519L0.304688 19.7779C0.304688 22.1434 1.24436 24.4119 2.91698 26.0846C4.58961 27.7572 6.85818 28.6969 9.22363 28.6969H19.9264C22.2918 28.6969 24.5604 27.7572 26.233 26.0846C27.9056 24.4119 28.8453 22.1434 28.8453 19.7779V9.07519C28.8453 6.70974 27.9056 4.44117 26.233 2.76855C24.5604 1.09592 22.2918 0.15625 19.9264 0.15625ZM26.1696 19.7779C26.1696 23.2206 23.3691 26.0212 19.9264 26.0212H9.22363C5.78092 26.0212 2.98037 23.2206 2.98037 19.7779V9.07519C2.98037 5.63248 5.78092 2.83193 9.22363 2.83193H19.9264C23.3691 2.83193 26.1696 5.63248 26.1696 9.07519V19.7779Z"
                        fill="black"
                      />
                      <path
                        d="M14.5766 7.2915C12.6842 7.2915 10.8693 8.04324 9.53124 9.38134C8.19314 10.7194 7.44141 12.5343 7.44141 14.4267C7.44141 16.319 8.19314 18.1339 9.53124 19.472C10.8693 20.8101 12.6842 21.5618 14.5766 21.5618C16.4689 21.5618 18.2838 20.8101 19.6219 19.472C20.96 18.1339 21.7117 16.319 21.7117 14.4267C21.7117 12.5343 20.96 10.7194 19.6219 9.38134C18.2838 8.04324 16.4689 7.2915 14.5766 7.2915ZM14.5766 18.8861C13.3943 18.8847 12.2608 18.4144 11.4248 17.5784C10.5888 16.7424 10.1185 15.6089 10.1171 14.4267C10.1171 11.9668 12.1185 9.96719 14.5766 9.96719C17.0346 9.96719 19.036 11.9668 19.036 14.4267C19.036 16.8847 17.0346 18.8861 14.5766 18.8861Z"
                        fill="black"
                      />
                      <path
                        d="M22.2437 7.70865C22.7688 7.70865 23.1945 7.28298 23.1945 6.75789C23.1945 6.2328 22.7688 5.80713 22.2437 5.80713C21.7186 5.80713 21.293 6.2328 21.293 6.75789C21.293 7.28298 21.7186 7.70865 22.2437 7.70865Z"
                        fill="black"
                      />
                    </svg>
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="29"
                      height="29"
                      viewBox="0 0 29 29"
                      fill="none"
                    >
                      <path
                        d="M26.8585 0H2.1428C0.959266 0 0 0.929933 0 2.07769V26.9213C0 28.0691 0.959588 29 2.1428 29H26.8585C28.042 29 29 28.0688 29 26.9213V2.07769C29 0.930256 28.042 0 26.8585 0ZM8.79193 24.2749H4.4103V11.1818H8.79193V24.2749ZM6.60144 9.3931H6.57212C5.10277 9.3931 4.15027 8.38809 4.15027 7.13013C4.15027 5.84672 5.1308 4.8691 6.62915 4.8691C8.12782 4.8691 9.04971 5.8464 9.07871 7.13013C9.07871 8.38841 8.12815 9.3931 6.60144 9.3931ZM24.5868 24.2749H20.2065V17.2698C20.2065 15.5092 19.5717 14.3083 17.9876 14.3083C16.7757 14.3083 16.0565 15.1187 15.7407 15.9007C15.6234 16.1804 15.5948 16.5709 15.5948 16.9615V24.2746H11.2151C11.2151 24.2746 11.2724 12.4094 11.2151 11.1814H15.5951V13.0378C16.1767 12.1458 17.2156 10.8734 19.542 10.8734C22.425 10.8734 24.5868 12.7436 24.5868 16.7672V24.2749ZM15.5667 13.0793C15.5745 13.0671 15.5848 13.0519 15.5951 13.0378V13.0793H15.5667Z"
                        fill="black"
                      />
                    </svg>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
