import React, { useEffect } from "react";
import Layout from "../elements/Layout";
import { Container, Row, Col, Figure } from "react-bootstrap";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Rating } from "react-simple-star-rating";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllCourses, getAllCreatorsWeb, getTopFreeCreator, getTopPaidCreator } from "../redux/actions/userAction";
import parseDuration from "../utils/parseDuration ";
export default function Creators() {
  const dispatch = useDispatch();
  const [rating, setRating] = useState(0);
  const navigate = useNavigate();
  const { allCreators, allCourses, topFreeCreators, topPaidCreators } = useSelector((state) => ({
    allCreators: state.userMgmtSlice.allCreators,
    topPaidCreators: state.userMgmtSlice.topPaidCreators,
    allCourses: state.userMgmtSlice.allCourses,
    topFreeCreators: state.userMgmtSlice.topFreeCreators,
  }));

  const handleClick = (id) => {
    navigate(`/course-overview/${id}`);
  };

  useEffect(() => {
    dispatch(getTopPaidCreator());
    dispatch(getTopFreeCreator());
    dispatch(getAllCreatorsWeb());
    dispatch(getAllCourses({ tags: "yes" }));
  }, []);

  console.log(topFreeCreators, "topFreeCreators");
  return (
    <Layout>
      <Container fluid>
        <div className="creators-main">
          <Row>
            <Col lg={12}>
              <div className="creators-profile">
                <h2 className="creator-hdng">Top Free Creators</h2>
                <p className="creator-subhdng">
                  Rated the best by you this month
                </p>
                <div className="mt-4 d-flex  creators-main-sec flex-wrap">
                  {
                    Array.isArray(topFreeCreators) && topFreeCreators?.length > 0 ?
                      topFreeCreators?.map((item) => {
                        // const freeExist = item?.courses?.find((data) => data?.cost == 0);

                        // console.log(freeExist, item?.courses
                        //   , "freeExist");
                        return (
                          <>
                            {
                              <div className="creator-user-profile">
                                <Link to={`/single-creator-detail/${item?._id}`}>
                                  <Figure>
                                    <Figure.Image
                                      alt="171x180"
                                      src={
                                        item?.creator?.profile_image
                                          ?
                                          item?.creator?.profile_image
                                          : require("../images/userico.png")
                                      }
                                    />
                                    <Figure.Caption>{item?.creator?.full_name}</Figure.Caption>
                                  </Figure>
                                </Link>
                                <div className="creator-top-desc">
                                  <p>1st Place</p>
                                  <p>{item?.uniqueCourse?.reduce((acc, curr) => {
                                    return acc + curr.course_views;
                                  }, 0)} Views</p>
                                </div>
                                <div className="creator-top-desc">
                                  <Rating initialValue={item?.overallAverageRating} />
                                  <p>({`${item?.courses?.filter((data) => data?.averageRating)?.length} ${(item?.courses?.filter((data) => data?.averageRating)?.length) == 1 ? `Review` : `Reviews`}`})</p>
                                </div>
                              </div>
                            }

                          </>
                        );
                      }) :
                      <p>No Data Available Yet!</p>
                  }
                </div>

                {/* <div className="creator-show-div">
                  <Link to="#">Show All</Link>
                </div> */}
              </div>
            </Col>
            <Col lg={12}>
              <div className="creators-profile mt-4">
                <h2 className="creator-hdng">Top Paid Creators</h2>
                <p className="creator-subhdng">
                  Rated the best by you this month
                </p>
                <div className="mt-4 d-flex  creators-main-sec flex-wrap">
                  {Array.isArray(topPaidCreators) && topPaidCreators?.length > 0 ?
                    topPaidCreators?.map((item) => {
                      // const paidExist = item?.courses?.find((data) => data?.cost > 0);
                      return (
                        <>
                          {
                            <div className="creator-user-profile">
                              <Link to={`/single-creator-detail/${item?._id}`}>
                                <Figure>
                                  <Figure.Image
                                    alt="171x180"
                                    src={
                                      item?.creator?.profile_image
                                        ?
                                        item?.creator?.profile_image
                                        : require("../images/userico.png")
                                    }
                                  />
                                  <Figure.Caption>{item?.creator?.full_name}</Figure.Caption>
                                </Figure>
                              </Link>
                              <div className="creator-top-desc">
                                <p>1st Place</p>
                                <p>{item?.totalViewCount} Views</p>
                              </div>
                              <div className="creator-top-desc">
                                <Rating initialValue={item?.overallAverageRating} />
                                <p>({`${item?.courses?.filter((data) => data?.averageRating)?.length} ${(item?.courses?.filter((data) => data?.averageRating)?.length) == 1 ? `Review` : `Reviews`}`})</p>
                              </div>
                            </div>
                          }

                        </>
                      )
                    }) :
                    <p>No Data Available Yet!</p>
                  }
                </div>
                <div className="creator-show-div">
                  {/* <p>Show All</p> */}
                </div>
              </div>
            </Col>

            <Col lg={12}>
              <div className="creators-profile mt-0">
                <h2 className="creator-hdng text-center">
                  <Link to="/creators-show-all"> See All of Our Creators</Link>
                </h2>
              </div>
              <div className="videos-sec-main mt-4">
                <h2 className="creator-hdng text-start mb-4">
                  Featured Courses
                </h2>
                {allCourses?.courses?.length == 0 && (
                  <p>No data available yet!</p>
                )}
                <div className="videos-view featured">
                  {allCourses?.courses?.map((item, i) => {
                    const totalRatingSum = item?.rates?.reduce(
                      (sum, rating) => sum + rating.rating,
                      0
                    );
                    const averageRating = totalRatingSum / item?.rates?.length;

                    return (
                      <>
                        <div className="video-inner">
                          <img
                            onClick={() => handleClick(item?._id)}
                            className="thumb-img"
                            src={
                              item?.cover_image
                                ?
                                item?.cover_image
                                : require("../images/imagesplaceholder.png")
                            }
                          />
                          <div className="video-dec">
                            <div className="user-logo">
                              <img
                                src={
                                  item?.creator_details?.profile_image
                                    ?
                                    item?.creator_details?.profile_image
                                    : require("../images/userico.png")
                                }
                              />
                            </div>
                            <div className="user-desc">
                              <h3>{item?.course_title}</h3>
                              <div className="bottom-desc">
                                <div className="bottom-first">
                                  <p>{item?.creator_name}</p>
                                  <p>{item?.viewcount} views</p>
                                  <p>
                                    {item?.cost == 0
                                      ? "Free"
                                      : `£ ${item?.cost}`}
                                  </p>
                                </div>
                                <div className="bottom-first">
                                  <p>
                                    {item?.chapters == 1
                                      ? item?.chapters + " Video,"
                                      : item?.chapters + " Videos,"}{" "}
                                    {parseDuration(item?.duration)}
                                  </p>
                                  <Rating initialValue={averageRating || 0} />
                                  <p>({`${item?.rates?.length} ${item?.rates?.length == 1 ? `Review` : `Reviews`}`})</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </Layout>
  );
}
