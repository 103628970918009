import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import { getProductList, getStatics, getUserById } from "../actions/userAuth";
import { channelcourselist, coursedetails, getAllCompletedCourses, getAllCourses, getAllCoursesById, getAllCreatorViewsAndRating, getAllCreators, getAllCreatorsWeb, getAllDownloads, getAllPurchase, getAllTransactions, getAllVideosLikes, getChannels, getChapterLikes, getChapters, getCompletedCourse, getCourseById, getCoursesByWatchCount, getCoursesLastSevenDays, getCreatorById, getHighestRatedCourse, getLikedCreatorCourses, getMostPurchasedCourse, getMostWatchedVideo, getMyCourses, getQuizlists, getTopFreeCreator, getTopPaidCreator, getVideoReport, moreLikeThis, quizzAnswerdetails, searchResults, setMultipleUploadLoader, setUploadProgress, uploadVideoInBackground, walletInfo } from "../actions/userAction";

const initialState = {
  userDetails: null,
  statics: null,
  getRegisterUser: null,
  allCourses: [],
  courseDetail: null,
  products: [],
  myCourses: [],
  chapters: [],
  allCreators: [],
  creatorDetail: null,
  moreLikeThisData: [],
  quizlists: [],
  courseDet: null,
  chapterLikes: 0,
  channels: null,
  channelCourseDetail: null,
  downloadArr: [],
  purchases: [],
  quizAnswer: null,
  videoReport: null,
  highestRatedCourse: null,
  mostWatchedVideo: null,
  mostPurchasedCourse: null,
  allCreatorViewsAndRating: [],
  uploadProgress: 0,
  topFreeCreators: [],
  topPaidCreators: [],
  allCompletedCourses: [],
  completeCourse: [],
  searchArr: [],
  wallet: [],
  wallet_transaction: [],
  multipleLoader: []
};

export const userMgmtSlice = createSlice({
  name: "userMgmtSlice",
  initialState,
  reducers: {
    removeUpload: (state, action) => {
      state.multipleLoader = state.multipleLoader.filter(upload => upload.video_title !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      // .addCase(getProfile.fulfilled, (state, { payload }) => {
      //   if (payload?.success) {
      //     state.userDetails = payload?.data;
      //   } else if (!payload?.success) {
      //     toast.error(payload?.message);
      //   }
      // })
      .addCase(getStatics.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.statics = payload?.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getUserById.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.getRegisterUser = payload?.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getAllCourses.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.allCourses = payload?.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getLikedCreatorCourses.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.allCourses = payload?.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getCoursesByWatchCount.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.allCourses = payload?.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getCoursesLastSevenDays.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.allCourses = payload?.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(coursedetails.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.courseDetail = payload?.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getProductList.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.products = payload?.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getMyCourses.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.myCourses = payload?.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getChapters.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.chapters = payload?.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getAllCreatorsWeb.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.allCreators = payload?.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getCreatorById.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.creatorDetail = payload?.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })

      .addCase(moreLikeThis.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.moreLikeThisData = payload?.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getQuizlists.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.quizlists = payload?.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getCourseById.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.courseDet = payload?.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getChapterLikes.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.chapterLikes = payload?.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getChannels.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.channels = payload?.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(channelcourselist.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.channelCourseDetail = payload?.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getAllDownloads.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.downloadArr = payload?.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getAllPurchase.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.purchases = payload?.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(quizzAnswerdetails.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.quizAnswer = payload?.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getVideoReport.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.videoReport = payload?.data;
        }
      })
      .addCase(getHighestRatedCourse.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.highestRatedCourse = payload?.data;
        }
      })
      .addCase(getMostWatchedVideo.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.mostWatchedVideo = payload?.data;
        }
      })
      .addCase(getMostPurchasedCourse.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.mostPurchasedCourse = payload?.data;
        }
      })
      .addCase(getAllCreatorViewsAndRating.fulfilled, (state, { payload }) => {
        console.log(payload, "payload?.data");
        if (payload?.success) {
          state.allCreatorViewsAndRating = payload?.data;
        }
      })
      .addCase(getAllVideosLikes.fulfilled, (state, { payload }) => {
        console.log(payload, "payload?.data");
        if (payload?.success) {
          state.alVideosLikes = payload?.data;
        }
      })
      .addCase(uploadVideoInBackground.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          toast.success('Video uploaded successfully');
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(setUploadProgress.fulfilled, (state, { payload }) => {
        state.uploadProgress = payload;
      })
      .addCase(setMultipleUploadLoader.fulfilled, (state, { payload }) => {
        const { percent, uploadTime, video_title } = payload;
        const existingUpload = state.multipleLoader.find(upload => upload.video_title === video_title);

        if (existingUpload) {
          existingUpload.percent = percent;
          existingUpload.uploadTime = uploadTime;
        } else {
          state.multipleLoader.push({ video_title, percent, uploadTime, status: 'uploading' });
        }
      })
      .addCase(getTopFreeCreator.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.topFreeCreators = payload?.data;
        }
      })
      .addCase(getTopPaidCreator.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.topPaidCreators = payload?.data;
        }
      })
      .addCase(getAllCompletedCourses.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.allCompletedCourses = payload?.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getCompletedCourse.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.completeCourse = payload?.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(searchResults.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.searchArr = payload?.data;
        }
      })
      .addCase(walletInfo.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.wallet = payload?.data;
        }
      })
      .addCase(getAllTransactions.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.wallet_transaction = payload?.data;
        }
      })

  },
});

export const { removeUpload } = userMgmtSlice.actions;
export default userMgmtSlice.reducer;
