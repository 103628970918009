import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Form, Link, useNavigate, useParams } from "react-router-dom";
// import ProgressBar from "@ramonak/react-progress-bar";
import Navbar from "react-bootstrap/Navbar";
import CreatorLayout from "../elements/CreatorLayout";
import { getChapters } from "../redux/actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import VideoThumbnail from "react-video-thumbnail";
export default function SettingsChapterView() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const { courseId, chapterId } = useParams();
  const [file, setFile] = useState();
  const { chapters } = useSelector((state) => ({
    chapters: state.userMgmtSlice.chapters,
  }));

  const filterChapter = chapters?.chapters?.find(
    (item) => item?._id == chapterId
  );

  const chapterIndex = chapters?.chapters?.findIndex(element => element._id === chapterId);

  console.log(filterChapter, "filterChapter");
  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  const navigateToContacts = () => {
    // 👇️ navigate to /contacts
    navigate("/settings-create-quiz");
  };

  useEffect(() => {
    dispatch(getChapters(courseId));
  }, [courseId]);

  const handleThumbnailClick = () => {
    setIsVideoVisible(true);
  };

  console.log(chapters, "chapters");

  return (
    <CreatorLayout>
      <Container>
        <div className="profile-top-box">
          <div className="box-left">
            <div className="backpage-arrow">
              <Link to={`/settings-edit-course/${courseId}`}>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                  >
                    <path
                      d="M10 20.0003L8.93934 21.061L7.87868 20.0003L8.93934 18.9397L10 20.0003ZM30 18.5003C30.8284 18.5003 31.5 19.1719 31.5 20.0003C31.5 20.8288 30.8284 21.5003 30 21.5003V18.5003ZM15.606 27.7277L8.93934 21.061L11.0607 18.9397L17.7273 25.6063L15.606 27.7277ZM8.93934 18.9397L15.606 12.273L17.7273 14.3943L11.0607 21.061L8.93934 18.9397ZM10 18.5003H30V21.5003L10 21.5003L10 18.5003Z"
                      fill="#40413A"
                    />
                  </svg>
                </span>
                Back to the previous page.
              </Link>
            </div>
          </div>
        </div>
        <div className="edit-course-box">
          <div className="vdo-view-banner">
            <div className="video-view">
              {filterChapter?.video ? (
                !isVideoVisible ? (
                  <div
                    onClick={handleThumbnailClick}
                    className="video-view-inner chapter-view"
                  >
                    <img
                      src={(filterChapter?.cover_image || filterChapter?.chapter_cover_image) ?? require("../images/YouTubeVideoPlayer.png")}
                    //showPlayIcon={true} // Display play icon overlay
                    />
                    <div className="play-icon">
                      <img src={require("../images/Subtract.png")} />{" "}
                      {/* Play icon */}
                    </div>
                  </div>
                ) : (
                  <div className="video-view-inner chapter-view">
                    <video controls autoPlay>
                      <source src={filterChapter?.video} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    {/* <div className="play-icon">
                          <img src={require("../images/Subtract.png")} />{" "}
                         
                        </div> */}
                  </div>
                )
              ) : (
                <img
                  className="img-fluid"
                  src={
                    (filterChapter?.cover_image || filterChapter?.chapter_cover_image) ?? require("../images/YouTubeVideoPlayer.png")
                  }
                />
              )}

              <h2>{chapters?.chapters?.course_title}</h2>
            </div>
          </div>

          <Row>
            <Col lg="8">
              <div className="chpter-view-txt">
                <li><span>{chapterIndex + 1 + "."}</span>{filterChapter?.chapter_title}</li>
                <div className="chpter-left-txt">
                  <p className="mb-4"> {filterChapter?.chapter_desc}</p>
                  <h3>What you will learn</h3>
                  <ul>
                    <li>{filterChapter?.learn}</li>
                  </ul>

                  <h3>Products Used</h3>
                  <ul>
                    {chapters?.products?.map((item) => {
                      return <li>{item?.name}</li>;
                    })}

                    {chapters?.products?.length == 0 && "N/A"}
                  </ul>
                </div>
              </div>
            </Col>
            <Col lg="4">
              <div className="chpter-view-btn">
                <Link
                  to={`/settings-add-chapter/${courseId}?id=${filterChapter?._id}`}
                  type="submit"
                  class="common-btn "
                >
                  EDIT CHAPTER
                </Link>
                <Link
                  to={`${filterChapter?.quiz ? `/settings-edit-quiz/${courseId}/${chapterId}` : `/settings-create-quiz/${courseId}/${chapterId}`}`}
                  type="submit"
                  class="common-btn mt-3"
                >
                  {filterChapter?.quiz ? `EDIT` : `CREATE`} QUIZ
                </Link>
              </div>
            </Col>
          </Row>

          {/* <div className="sign-form-inner">
            <form>
              <div class="form-group">
                <label for="exampleInputEmail1">Document Title</label>
                <input
                  type="text"
                  className="form-control"
                  aria-describedby="emailHelp"
                  placeholder="Type here..."
                />
              </div>
              <Link to="#" type="submit" class="common-btn ">
                UPLOAD
              </Link>
              <Link to="#" type="submit" class="common-btn mt-3">
                SAVE
              </Link>
            </form>
          </div> */}
        </div>
      </Container>
    </CreatorLayout>
  );
}
