import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import Api from "../../services/Api";
import axios from "axios";



const getMyCourses = createAsyncThunk(
  "getMyCourses",
  async (userId) => {
    const { data } = await Api.get(`/creator/getMyCourses?id=${userId}`);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);

const getAllCourses = createAsyncThunk(
  "getAllCourses",
  async ({ categoryid, search, role, purchases, likes, tags, certificate, channel_id }) => {
    const { data } = await Api.get(`/common/getAllCourses?categoryid=${categoryid}&search=${search || ''}&role=${role}&purchases=${purchases}&likes=${likes}&tags=${tags}&certificate=${certificate}&channel_id=${channel_id}`);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);

const getLikedCreatorCourses = createAsyncThunk(
  "getLikedCreatorCourses",
  async ({ userId }) => {
    const { data } = await Api.get(`/common/likeCreatorCourses?userId=${userId}`);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);

const getCoursesByWatchCount = createAsyncThunk(
  "getCoursesByWatchCount",
  async ({ userId }) => {
    const { data } = await Api.get(`/common/getCoursesByWatchCount?userId=${userId}`);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);

const getCoursesLastSevenDays = createAsyncThunk(
  "getCoursesLastSevenDays",
  async ({ userId }) => {
    const { data } = await Api.get(`/common/getCoursesLastSevenDays?userId=${userId}`);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);


const getAllCompletedCourses = createAsyncThunk(
  "getAllCompletedCourses",
  async ({ categoryid, search, role, purchases, likes, tags, certificate, channel_id }) => {
    const { data } = await Api.get(`/common/getAllCourses?categoryid=${categoryid}&search=${search || ''}&role=${role}&purchases=${purchases}&likes=${likes}&tags=${tags}&certificate=${certificate}&channel_id=${channel_id}`);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);

const getCompletedCourse = createAsyncThunk(
  "getCompletedCourse",
  async ({ categoryid, search, role, purchases, likes, tags, certificate, channel_id }) => {
    const { data } = await Api.get(`/common/getCompletedCourses`);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);

const coursedetails = createAsyncThunk(
  "coursedetails",
  async ({ courseId, userId }) => {
    const { data } = await Api.get(`/creator/coursedetails?id=${courseId}&user_id=${userId}`);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);

const createCourse = createAsyncThunk(
  "createCourse",
  async (obj) => {
    const { data } = await Api.post("/creator/createCourse", obj);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);

const editCourse = createAsyncThunk(
  "editCourse",
  async (obj) => {
    const { data } = await Api.post("/creator/editCourse", obj);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);

const successfullpayment = createAsyncThunk(
  "successfullpayment",
  async (obj) => {
    const { data } = await Api.post("/common/successfullpayment", obj);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);



const addChapter = createAsyncThunk(
  "addChapter",
  async (obj) => {
    const { data } = await axios.post(`${process.env.REACT_APP_HOST_NAME}api/creator/addChapter`, obj, {
      headers: {
        authorization: localStorage.getItem("accessToken"),
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        console.log(`Upload progress: ${percentCompleted}%`);
        //  setUploadProgress(percentCompleted);
      },
    });
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);

export const setUploadProgress = createAsyncThunk(
  'upload/setProgress',
  async (percent) => percent
);

export const setMultipleUploadLoader = createAsyncThunk(
  'upload/setMultipleUploadLoader',
  async (percent) => percent
);

const uploadVideoInBackground = createAsyncThunk(
  "uploadVideoInBackground",
  async ({ obj, video_title }, { dispatch }) => {
    console.log(video_title, "video_title");
    let startTime = null;
    let previousLoaded = 0;
    const { data } = await axios.post(`${process.env.REACT_APP_HOST_NAME}api/creator/uploadVideoInBackground`, obj, {
      headers: {
        authorization: localStorage.getItem("accessToken"),
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        // Set the start time when the first progress event occurs
        if (!startTime) {
          startTime = Date.now();
        }

        // Calculate the elapsed time in seconds
        const elapsedTime = (Date.now() - startTime) / 1000;

        // Calculate the upload speed in bytes per second
        const uploadSpeed = progressEvent.loaded / elapsedTime;

        // Calculate the remaining time in seconds
        const remainingTime = (progressEvent.total - progressEvent.loaded) / uploadSpeed;

        const minutes = Math.floor(remainingTime / 60);
        const seconds = Math.floor(remainingTime % 60);
        const remainingTimeFormatted = `${minutes}min ${seconds}sec`;

        console.log(`Upload progress: ${percentCompleted}%`);
        console.log(`Elapsed time: ${elapsedTime.toFixed(2)} seconds`);
        console.log(`Estimated remaining time: ${remainingTime.toFixed(2)} seconds`);

        console.log(`Upload progress--------->: ${remainingTimeFormatted}`);
        //  setUploadProgress(percentCompleted);
        dispatch(setUploadProgress({ percent: percentCompleted, uploadTime: remainingTimeFormatted, video_title: video_title?.chapter_title }));
        dispatch(setMultipleUploadLoader({
          percent: percentCompleted,
          uploadTime: remainingTimeFormatted,
          video_title: video_title.chapter_title
        }));
      },
    });
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);




const getChapterById = createAsyncThunk(
  "getChapterById",
  async (id) => {
    const { data } = await Api.get(`/creator/getChapterById?id=${id}`);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
)

const editChapter = createAsyncThunk(
  "editChapter",
  async (obj) => {
    const { data } = await Api.post("/creator/editChapter", obj);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);

const getChapters = createAsyncThunk(
  "getChapters",
  async (course_id) => {
    const { data } = await Api.get(`/common/getCourseDetails?id=${course_id}`);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
)
const getAllCreatorsWeb = createAsyncThunk(
  "getAllCreatorsWeb",
  async (course_id) => {
    const { data } = await Api.get(`/common/getAllCreatorsWeb`);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);
const getTopPaidCreator = createAsyncThunk(
  "getTopPaidCreator",
  async (course_id) => {
    const { data } = await Api.get(`/common/getTopPaidCreator`);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);

const accountChangeRequestAction = createAsyncThunk(
  "getTopPaidCreator",
  async () => {
    const { data } = await Api.get(`/common/accountChangeRequest`);
    return data;
  }
);

const getAllCreatorViewsAndRating = createAsyncThunk(
  "getAllCreatorViewsAndRating",
  async () => {
    const { data } = await Api.get(`/creator/getAllCreatorViewsAndRating`);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);

const getAllVideosLikes = createAsyncThunk(
  "getAllVideosLikes",
  async () => {
    const { data } = await Api.get(`/common/getAllLikedVideos`);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);


const getCreatorById = createAsyncThunk(
  "getCreatorById",
  async (id) => {
    const { data } = await Api.get(`/common/getCreatorById?id=${id}`);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);

const createStripePayment = (data) => async (dispatch) => {
  try {

    const res = await Api.post("/creator/createPayment", data);
    if (res.status == 200) {

      return res?.data
    } else {
      toast.error(res.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

const moreLikeThis = createAsyncThunk(
  "moreLikeThis",
  async ({ courseId }) => {
    const { data } = await Api.get(`/common/moreLikeThis?courseId=${courseId}`);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);

const likeCourse = createAsyncThunk(
  "likeCourse",
  async (obj) => {
    const { data } = await Api.post("/creator/likeCourse", obj);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);
const likeChapter = createAsyncThunk(
  "likeChapter",
  async (obj) => {
    const { data } = await Api.post("/creator/likeChapter", obj);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);
const likeCreator = createAsyncThunk(
  "likeCreator",
  async (obj) => {
    const { data } = await Api.post("/creator/likeCreator", obj);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);


const Addquiz = createAsyncThunk(
  "Addquiz",
  async (obj) => {
    const { data } = await Api.post("/creator/Addquiz", obj);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);


const Editquiz = createAsyncThunk(
  "Editquiz",
  async (obj) => {
    const { data } = await Api.put("/creator/editQuiz", obj);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);

const EditQuestion = createAsyncThunk(
  "EditQuestion",
  async (obj) => {
    const { data } = await Api.put("/creator/editQuestion", obj);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);



const quizzAnswerdetails = createAsyncThunk(
  "quizzAnswerdetails",
  async ({ chapterId }) => {
    const { data } = await Api.get(`/creator/quizzAnswerdetails?chapter_id=${chapterId}`);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);

const getQuizlists = createAsyncThunk(
  "getQuizlists",
  async ({ chapterId }) => {
    const { data } = await Api.get(`/creator/getQuizlists?chapter_id=${chapterId}`);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);


const quizzAnswersave = createAsyncThunk(
  "quizzAnswersave",
  async (obj) => {
    const { data } = await Api.post(`/creator/quizzAnswersave`, obj);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);

const getCourseById = createAsyncThunk(
  "getCourseById",
  async (id) => {
    const { data } = await Api.get(`/creator/getCourseById?id=${id}`);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);

const getChapterLikes = createAsyncThunk(
  "getChapterLikes",
  async (chapterId) => {
    const { data } = await Api.get(`/creator/getChapterLikes?chapter_id=${chapterId}`);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);

const getChannels = createAsyncThunk(
  "getChannels",
  async () => {
    const { data } = await Api.get(`/channel/getChannels`);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);



const channelcourselist = createAsyncThunk(
  "channelcourselist",
  async (id) => {
    const { data } = await Api.get(`/channel/channelcourselist?id=${id}`);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);


const downloadVideo = createAsyncThunk(
  "downloadVideo",
  async (obj) => {
    const { data } = await Api.post(`/creator/downloadVideo`, obj);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);





const getAllDownloads = createAsyncThunk(
  "getAllDownloads",
  async (obj) => {
    const { data } = await Api.get(`/creator/getAllDownloads`);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);

const getAllPurchase = createAsyncThunk(
  "getAllPurchase",
  async (obj) => {
    const { data } = await Api.get(`/creator/getAllPurchase`);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);


const createChapterView = createAsyncThunk(
  "createChapterView",
  async (obj) => {
    const { data } = await Api.post(`/creator/createChapterView`, obj);
    return data;
  }
);

const getVideoReport = createAsyncThunk(
  "getVideoReport",
  async (obj) => {
    const { data } = await Api.get(`/creator/getVideoReport`);

    return data;
  }
);

const getHighestRatedCourse = createAsyncThunk(
  "getHighestRatedCourse",
  async (obj) => {
    const { data } = await Api.get(`/creator/getHighestRatedCourse`);

    return data;
  }
);
const getMostWatchedVideo = createAsyncThunk(
  "getMostWatchedVideo",
  async (obj) => {
    const { data } = await Api.get(`/creator/getMostWatchedVideo`);

    return data;
  }
);
const getMostPurchasedCourse = createAsyncThunk(
  "getMostPurchasedCourse",
  async (obj) => {
    const { data } = await Api.get(`/creator/getMostPurchasedCourse`);

    return data;
  }
);
const uploadS3Image = createAsyncThunk(
  "getMostPurchasedCourse",
  async (obj) => {
    const { data } = await Api.post(`/creator/uploadS3Image`, obj);

    return data;
  }
);

const addrating = createAsyncThunk(
  "addrating",
  async (obj) => {
    const { data } = await Api.post(`/common/addrating`, obj);

    return data;
  }
);

const getTopFreeCreator = createAsyncThunk(
  "getTopFreeCreator",
  async (course_id) => {
    const { data } = await Api.get(`/common/getTopFreeCreator`);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);
const courseQuizPass = createAsyncThunk(
  "courseQuizPass",
  async (id) => {
    const { data } = await Api.get(`/common/courseQuizPass?courseId=${id}`);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);

const saveCertificate = createAsyncThunk(
  "saveCertificate",
  async (obj) => {
    const { data } = await Api.post(`/creator/saveCertificate`, obj);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);

const saveVideoWatch = createAsyncThunk(
  "saveVideoWatch",
  async (obj) => {
    const { data } = await Api.post(`/creator/saveVideoWatch`, obj);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);

const getMyCertificates = createAsyncThunk(
  "getMyCertificates",
  async (obj) => {
    const { data } = await Api.get(`/creator/getMyCertificates`, obj);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);


const autoSearchResults = createAsyncThunk(
  "autoSearchResults",
  async ({ search }) => {
    let url = search != "" ? `/common/autoSearchResults?search=${search}` : `/common/autoSearchResults`
    const { data } = await Api.get(url);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);

const searchResults = createAsyncThunk(
  "searchResults",
  async ({ search }) => {
    const { data } = await Api.get(`/common/searchResults?search=${search}`);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);

const Addaccount = createAsyncThunk(
  "Addaccount",
  async () => {
    const { data } = await Api.post(`/common/Addaccount`);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);

const walletInfo = createAsyncThunk(
  "walletInfo",
  async (obj) => {
    const { data } = await Api.get(`/common/walletInfo`);
    if (!data?.success) {
      //toast.error(data?.message);
    }
    return data;
  }
);

const transferMoney = createAsyncThunk(
  "transferMoney",
  async (obj) => {
    const { data } = await Api.get(`/common/transferMoney`);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);

const getAllTransactions = createAsyncThunk(
  "getAllTransactions",
  async (obj) => {
    const { data } = await Api.get(`/common/getAllTransactions`);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);

export {
  getAllTransactions,
  transferMoney,
  walletInfo,
  Addaccount,
  searchResults,
  autoSearchResults,
  getAllCompletedCourses,
  getMyCertificates,
  saveVideoWatch,
  saveCertificate,
  courseQuizPass,
  getTopPaidCreator,
  getTopFreeCreator,
  addrating,
  uploadS3Image,
  getAllCourses,
  coursedetails,
  createCourse,
  getMyCourses,
  addChapter,
  getChapters,
  successfullpayment,
  editCourse,
  editChapter,
  getAllCreatorsWeb,
  getCreatorById,
  createStripePayment,
  moreLikeThis,
  likeCourse,
  getChapterById,
  Addquiz,
  getQuizlists,
  getCourseById,
  likeChapter,
  getChapterLikes,
  getChannels,
  channelcourselist,
  downloadVideo,
  getAllDownloads,
  getAllPurchase,
  quizzAnswersave,
  quizzAnswerdetails,
  createChapterView,
  getVideoReport,
  getHighestRatedCourse,
  getMostWatchedVideo,
  getMostPurchasedCourse,
  uploadVideoInBackground,
  getAllCreatorViewsAndRating,
  likeCreator,
  Editquiz,
  EditQuestion,
  getAllVideosLikes,
  getCompletedCourse,
  getLikedCreatorCourses,
  getCoursesLastSevenDays,
  getCoursesByWatchCount,
  accountChangeRequestAction
};
