import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { uploadS3Image } from "../redux/actions/userAction";
export default function SettingsAddDocument({
  setFieldValue,
  values,
  setUploadDoc,
}) {
  console.log("props----", values)
  const dispatch = useDispatch();
  const handleAddImageBox = (setFieldValue, values) => {
    console.log("add  button -----", values)
    if (values?.doc?.length < 4) {
      setFieldValue("doc", [...values.doc, { title: "", docfilename: "" }]);
    }
  };

  const [filename, setFilename] = useState([]);

  return (
    <div className="edit-course-box add-doc">
      <div className="edit-cou-top">
        <div className="box-left">
          <h2>Add Document</h2>
        </div>
      </div>
      <hr />
      <div className="edit-cou-top">
        <div className="box-left">
          <p className="m-0">
            Add PDFs or Images for your students to download
          </p>
        </div>
        <div className="box-right">
          <div className="add-curse">
            <button
              type="buttom"
              className="add-icon"
              onClick={() => handleAddImageBox(setFieldValue, values)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
              >
                <path
                  d="M12.5 6.25L12.5 18.75"
                  stroke="#8B8B8B"
                  stroke-width="2"
                  stroke-linecap="square"
                  stroke-linejoin="round"
                />
                <path
                  d="M18.75 12.5L6.25 12.5"
                  stroke="#8B8B8B"
                  stroke-width="2"
                  stroke-linecap="square"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className="sign-form-inner">
        <>
          {values.doc?.length > 0 &&
            values.doc.map((expertise, index) => (
              <div key={index} className="additional-img-upload docs mt-4">
                <div class="form-group">
                  <label for="exampleInputEmail1">Document Title</label>
                  <input
                    type="text"
                    className="form-control"
                    aria-describedby="emailHelp"
                    placeholder="Type here..."
                    name={`doc.${index}.title`}
                    value={expertise.title}
                    onChange={(e) => {
                      setFieldValue(`doc.${index}.title`, e.target.value);
                    }}
                  />

                  <div className="form-group pt-2 mb-3">
                    <p className="doc-name">{filename[index] ? filename[index] : expertise.docfilename.split('--')[1]}</p>
                    <button type="button" class="common-btn ">
                      <input
                        type="file"
                        name={`doc.${index}`}
                        onChange={(e) => {
                          // setImagePreviews((prevState) => {
                          //   const newPreviews = [...prevState];
                          //   newPreviews[index] = URL.createObjectURL(
                          //     e.target.files[0]
                          //   );
                          //   return newPreviews;
                          // });
                          let formData = new FormData();
                          formData.append('image', e.target.files[0]);
                          dispatch(uploadS3Image(formData)).then((data) => {
                            console.log(data, "uploadS3Image");
                            if (data?.payload?.success) {
                              setFieldValue(`doc.${index}.docfilename`, data?.payload?.data);
                            }
                          })

                          setFilename([...filename, e.target.files[0].name]);

                        }}
                        className="form-control"
                        placeholder={`${index + 1}.`}
                      />{" "}
                      UPLOAD
                    </button>
                  </div>
                </div>
                {/* <div key={index} className="form-group pt-2 mb-3">
                              <img
                                className="show-img"
                                src={
                                  imagePreviews[index]
                                    ? imagePreviews[index]
                                    : process.env.REACT_APP_HOST_NAME +
                                      expertise
                                }
                              />
                              <p>
                                <b>Upload Image</b>
                              </p>
                            </div> */}
              </div>
            ))}

          {
            values.doc?.length > 0 &&
            <div
              onClick={() => setUploadDoc(false)}
              type="submit"
              class="common-btn mt-3"
            >
              SAVE
            </div>
          }

        </>
      </div>
    </div>
  );
}
