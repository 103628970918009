import React, { useEffect, useRef } from "react";
import PlaybackLayout from "../elements/PlaybackLayout";
import { Container, Row, Col } from "react-bootstrap";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  courseQuizPass,
  coursedetails,
  createChapterView,
  downloadVideo,
  getChapterLikes,
  likeChapter,
  moreLikeThis,
  quizzAnswerdetails,
  quizzAnswersave,
  saveCertificate,
  saveVideoWatch,
} from "../redux/actions/userAction";
import useAuthToken from "../Hooks/useAuthToken";
import { Suspense } from "react";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import html2canvas from "html2canvas";
import { Box } from "../components/DraggableCertificate/Box";
import { getUserById } from "../redux/actions/userAuth";
import moment from "moment";
const MyVideoThumbnail = React.lazy(() =>
  import("../components/MyVideoThumbnail")
);

export default function Playback() {
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const imageRef = useRef(null);
  const [show2, setShow2] = useState(false);
  const [downloadObj, setDownloadObj] = useState();

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const navigate = useNavigate();
  const tokenData = useAuthToken();
  const dispatch = useDispatch();
  const { courseId, chapterId } = useParams();
  const [rating, setRating] = useState(0);
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const [switchBtn, setSwitchBtn] = useState(1);
  const [likedStatus, setLikedStatus] = useState();
  const [totlaLikes, setTotalLikes] = useState();
  const [switchBtn2, setSwitchBtn2] = useState(1);
  const [passFail, setPassFail] = useState();
  const [quesizAnswerExist, setQuesizAnswerExist] = useState();
  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState(false);
  const [file, setFile] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { courseDetail, moreLikeThisData, chapterLikes, quizAnswer } =
    useSelector((state) => ({
      courseDetail: state.userMgmtSlice.courseDetail,
      moreLikeThisData: state.userMgmtSlice.moreLikeThisData,
      chapterLikes: state.userMgmtSlice.chapterLikes,
      quizAnswer: state.userMgmtSlice.quizAnswer,
    }));

  const filterQuizChapter = courseDetail?.quizes?.find(
    (item) => item?.chapter_id == chapterId
  );
  const filterChapter = courseDetail?.chaptersarr?.find(
    (item) => item?._id == chapterId
  );

  useEffect(() => {
    dispatch(getUserById(tokenData?._id));
  }, []);

  useEffect(() => {
    if (courseId) {
      dispatch(coursedetails({ courseId, userId: tokenData?._id })).then(
        (course) => {
          if (course?.payload?.data) {
            let courseData = course?.payload?.data;
            dispatch(quizzAnswerdetails({ chapterId })).then((item) => {
              if (item?.payload?.data) {
                const currentIndex = courseData?.chaptersarr?.findIndex(
                  (doc) => doc._id === chapterId
                );

                if (
                  currentIndex === -1 ||
                  currentIndex === courseData?.chaptersarr?.length - 1
                ) {
                  return null;
                }
                if (currentIndex > -1) {
                  const nextChapter = courseData?.chaptersarr[currentIndex + 1];

                  if (nextChapter && !activeTab && !type) {
                    navigate(`/playback/${courseId}/${nextChapter?._id}`);
                  }
                }
              }
            });
          }
        }
      );
      dispatch(moreLikeThis({ courseId }));
    }
    if (chapterId) {
      dispatch(getChapterLikes(chapterId));
    }
  }, [courseId, chapterId]);

  const handleThumbnailClick = () => {
    let obj = {
      chapter_id: chapterId,
      course_id: courseId,
    };
    dispatch(createChapterView(obj));
    setIsVideoVisible(true);
  };
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const handleLike = () => {
    // dispatch(likeCourse({course_id: courseId, status: !likedStatus})).then((data)=>{
    //   if(data?.payload?.success){
    //     setLikedStatus(!likedStatus);
    //     setTotalLikes(!likedStatus ? totlaLikes + 1 : totlaLikes -1)
    //   }
    // })

    dispatch(likeChapter({ chapter_id: chapterId, status: !likedStatus })).then(
      (data) => {
        if (data?.payload?.success) {
          setLikedStatus(!likedStatus);
          setTotalLikes(!likedStatus ? totlaLikes + 1 : totlaLikes - 1);
        }
      }
    );
  };

  useEffect(() => {
    if (chapterLikes) {
      setLikedStatus(chapterLikes?.userLiked?.status);
      setTotalLikes(chapterLikes?.totalLikes);
    }
  }, [chapterLikes]);

  const handleDownload = async (path) => {
    let obj = {
      chapter_id: filterChapter?._id,
      course_id: courseDetail?._id,
    };
    window.location.href = `${process.env.REACT_APP_HOST_NAME}api/download/${courseDetail?.chaptersarr[0]?._id}`;
    dispatch(downloadVideo(obj));
  };

  // Define initial form values
  const initialValues = {
    chapter_id: chapterId,
    quizzanswer: filterQuizChapter?.question?.map((item) => ({
      questionid: item?._id,
      answer: "",
    })),
  };

  useEffect(() => {
    dispatch(quizzAnswerdetails({ chapterId })).then((item) => {
      if (item?.payload?.data) {
        console.log(item?.payload?.data, "item?.payload?.data");
        setQuesizAnswerExist(item?.payload?.data);
      } else {
        setQuesizAnswerExist();
      }
    });
  }, [chapterId]);

  const findIndexBeforeChapterId = (arr, chapterId) => {
    // Find the index of the first occurrence of the specified chapterId
    const index = arr.findIndex((item) => item.chapterId === chapterId);

    if (index === -1) {
      // If chapterId is not found, return the entire array
      return arr;
    }

    // Return the slice of the array from the start to just before the found index
    return arr.slice(0, index);
  };

  const [show3, setShow3] = useState(false);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const handleDownload2 = async () => {
    try {
      let obj = {
        chapter_id: filterChapter?._id,
        course_id: courseDetail?._id,
        doc_id: downloadObj?._id,
        status: tokenData.role,
        filename: downloadObj?.docfilename,
      };
      let res = await dispatch(downloadVideo(obj));
      if (res?.payload?.status_code == 200) {
        if (downloadObj?.docfilename) {
          window.location.href = downloadObj?.docfilename;
        }
      }
      // handleClose3();
      // handleClose2();
    } catch (error) {
      console.error("Error downloading the file", error);
    }
  };

  function handleCapture(image) {
    // fetch(image)
    //   .then(response => response.blob())
    //   .then(blob => {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(blob);
    //     setFile(blob);

    //     setTimeout(() => {
    //       handleCapture2()
    //     }, 1000)
    //   });

    setFile(image);

    setTimeout(() => {
      handleCapture2();
    }, 1000);
  }

  function handleCapture2() {
    html2canvas(imageRef.current).then((canvas) => {
      // const link = document.createElement('a');
      // link.download = 'certificate.png';
      // link.href = canvas.toDataURL();
      // link.click();
      canvas.toBlob((blob) => {
        const formData = new FormData();

        const generateUniqueFilename = () => {
          const timestamp = Date.now();
          return `certificate-${timestamp}.png`;
        };
        formData.append("course_id", courseId);
        formData.append("certificate", blob, generateUniqueFilename());
        dispatch(saveCertificate(formData));
      });
    });
  }

  console.log("filterChapter", filterChapter);

  const handleEnded = () => {
    dispatch(
      saveVideoWatch({ course_id: courseId, chapter_id: chapterId })
    ).then((item) => {
      if (item?.payload?.status_code == 200) {
        dispatch(courseQuizPass(courseId)).then((data) => {
          if (data?.payload?.data?.quizPass) {
            if (data?.payload?.data?.getCourse?.certificate) {
              handleCapture(data?.payload?.data?.getCourse?.certificate);
            }
          }
        });
      }
    });
  };

  // Use Formik to manage form state and validation
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      console.log("values of handleSubmit", values)
      // Handle form submission here
      console.log(values, "useer_answer");
      console.log(filterQuizChapter, "values-filterQuizChapter");
      let correctArr = [];
      for (let userAns of values?.quizzanswer) {
        const findQuest = filterQuizChapter?.question?.find(
          (data) => data?._id?.toString() == userAns?.questionid?.toString()
        );
        const findCorrectAns = findQuest?.option?.find(
          (item) => item?.answer == true && item?.optiontitle == userAns?.answer
        );
        if (findCorrectAns) {
          correctArr?.push(findCorrectAns);
        }
      }
      console.log("correctAns", correctArr)
      let totalQuest = values?.quizzanswer?.length;
      let attemptQuest = correctArr?.length;
      let passPecent = filterQuizChapter?.minimumpercentagepassrate;
      let passFail = (attemptQuest / totalQuest) * 100;
      if (passFail >= passPecent) {
        //toast.success("You passed the test with" + passFail + "%");
        setPassFail({
          attempt: attemptQuest,
          total: totalQuest,
          success: passFail >= passPecent,
        });
        handleShow(true);
      } else {
        handleShow(true);
        return setPassFail({
          attempt: attemptQuest,
          total: totalQuest,
          success: passFail >= passPecent,
        });
        // toast.success("You failed this test with" + passFail + "% please try again.")
      }

      dispatch(quizzAnswersave(values)).then((data) => {
        if (data?.payload?.success) {
          toast.success(data?.payload?.message);
          setQuesizAnswerExist(data?.payload?.success);
          dispatch(courseQuizPass(courseId)).then((data) => {
            if (data?.payload?.data?.quizPass) {
              if (data?.payload?.data?.getCourse?.certificate) {
                handleCapture(data?.payload?.data?.getCourse?.certificate);
              }
            }
          });
        }
      });
    },
  });

  console.log(quesizAnswerExist, filterQuizChapter, "courseDetailcourseDetail");
  return (
    <PlaybackLayout>
      <Container fluid>
        <div className="videos-sec-main">
          <Row>
            <Col xxl={8} xl={8} lg={8}>
              <div ref={imageRef} className="docs-upload-view">
                {/* Display preview if a file is selected */}
                {file && <img src={file} alt="sdasda" width="100%" />}
                <div
                  style={{
                    position: "absolute",
                    transform: `translate3d(${courseDetail?.certificate_x_axis}px, ${courseDetail?.certificate_y_axis}px, 0px)`,
                  }}
                >
                  {/* {title} */}
                  <div>
                    <input
                      type="text"
                      className={`drag-input outside_tie_input`}
                      value={tokenData?.full_name}
                    />
                  </div>
                </div>

                <div
                  style={{
                    position: "absolute",
                    transform: `translate3d(${courseDetail?.certificate_x_axis2}px, ${courseDetail?.certificate_y_axis2}px, 0px)`,
                  }}
                >
                  <div>
                    <input
                      type="text"
                      value={moment(new Date()).format("YYYY-MM-DD")}
                      className={`drag-input outside_tie_input`}
                    />
                  </div>
                </div>
              </div>

              <div className="video-playback">
                <div className="video-view">
                  {courseDetail?.chaptersarr?.length > 0 ? (
                    !isVideoVisible ? (
                      <div
                        onClick={handleThumbnailClick}
                        className="video-view-inner"
                      >
                        <img
                          src={filterChapter?.cover_image || filterChapter?.chapter_cover_image}
                        // showPlayIcon={true} // Display play icon overlay
                        />
                        <div className="play-icon">
                          <img src={require("../images/Subtract.png")} />{" "}
                          {/* Play icon */}
                        </div>
                      </div>
                    ) : (
                      <div className="video-view-inner">
                        <video onEnded={handleEnded} controls autoPlay>
                          <source src={filterChapter?.video} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                        {/* <div className="play-icon">
                          <img src={require("../images/Subtract.png")} />{" "}
                         
                        </div> */}
                      </div>
                    )
                  ) : (
                    <img
                      className="img-fluid"
                      src={
                        courseDetail?.cover_image
                          ? courseDetail?.cover_image
                          : require("../images/YouTubeVideoPlayer.png")
                      }
                    />
                  )}

                  <h2>{filterChapter?.chapter_title}</h2>
                </div>
                <div className="video-desc">
                  <Row>
                    <Col lg={3} md={4}>
                      <div className="view-user">
                        <Link
                          to={`/single-creator-detail/${courseDetail?.creator_id?._id}`}
                        >
                          <img
                            className="img-fluid"
                            src={
                              courseDetail?.creator_id?.profile_image
                                ? courseDetail?.creator_id?.profile_image
                                : require("../images/yt-img-shadow.png")
                            }
                          />
                        </Link>
                        <div className="user-name">
                          <Link to="#" style={{ textDecoration: "none" }}>
                            <h3>{courseDetail?.creator_id?.full_name}</h3>
                          </Link>
                          <p>{courseDetail?.chaptersarr?.length} Courses</p>
                        </div>
                      </div>
                    </Col>
                    <Col lg={9} md={8} className="d-flex justify-content-end">
                      <div className="videos-tab">
                        <ul>
                          <li>
                            <Link
                              to="#"
                              onClick={() => setSwitchBtn2(1)}
                              className={`filt-tabs ${switchBtn2 == 1 && "active"
                                }`}
                            >
                              Info
                            </Link>
                          </li>
                          {filterQuizChapter?.question?.length > 0 &&
                            !quesizAnswerExist && (
                              <li>
                                <Link
                                  onClick={() => setSwitchBtn2(2)}
                                  to="#"
                                  className={`filt-tabs ${switchBtn2 == 2 && "active"
                                    }`}
                                >
                                  Quiz
                                </Link>
                              </li>
                            )}

                          {/* <li>
                            <Link to="#" className="filt-tabs">
                              Question
                            </Link>
                          </li> */}
                          {/* <li>
                            <Link
                              onClick={() =>
                                handleDownload(filterChapter?.video)
                              }
                              className="filt-tabs"
                            >
                              Downloads
                            </Link>
                          </li> */}

                          {filterChapter?.doc?.length > 0 && (
                            <li>
                              <Link onClick={handleShow2} className="filt-tabs">
                                Downloadables
                              </Link>
                            </li>
                          )}

                          <li>
                            {/* likestatus */}
                            <button
                              onClick={() => handleLike()}
                              type="button"
                              className="filt-tabs like-btn"
                            >
                              {likedStatus ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="15"
                                  viewBox="0 0 16 15"
                                  fill="none"
                                >
                                  <path
                                    d="M13.5303 6.17647H10.0119L11.2762 1.81765C11.5423 0.908823 10.8436 0 9.87878 0C9.39634 0 8.93054 0.211765 8.61447 0.573529L3.74022 6.17647H0.413086V15H3.74022H4.572H12.4157C13.2974 15 14.0626 14.4088 14.2373 13.5794L15.3519 8.28529C15.5765 7.19118 14.7031 6.17647 13.5303 6.17647ZM3.74022 14.1176H1.24487V7.05882H3.74022V14.1176ZM14.5368 8.09118L13.4222 13.3853C13.339 13.8088 12.9148 14.1176 12.4157 14.1176H4.572V6.52059L9.22999 1.17353C9.38803 0.988235 9.62924 0.882353 9.87878 0.882353C10.095 0.882353 10.2947 0.979412 10.4028 1.14706C10.461 1.23529 10.5276 1.37647 10.4777 1.56176L9.21335 5.92059L8.88064 7.05882H10.0035H13.522C13.863 7.05882 14.1874 7.20882 14.3787 7.46471C14.4869 7.59706 14.595 7.81765 14.5368 8.09118Z"
                                    stroke="#0F0F0F"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="15"
                                  viewBox="0 0 16 15"
                                  fill="none"
                                >
                                  <path
                                    d="M13.5303 6.17647H10.0119L11.2762 1.81765C11.5423 0.908823 10.8436 0 9.87878 0C9.39634 0 8.93054 0.211765 8.61447 0.573529L3.74022 6.17647H0.413086V15H3.74022H4.572H12.4157C13.2974 15 14.0626 14.4088 14.2373 13.5794L15.3519 8.28529C15.5765 7.19118 14.7031 6.17647 13.5303 6.17647ZM3.74022 14.1176H1.24487V7.05882H3.74022V14.1176ZM14.5368 8.09118L13.4222 13.3853C13.339 13.8088 12.9148 14.1176 12.4157 14.1176H4.572V6.52059L9.22999 1.17353C9.38803 0.988235 9.62924 0.882353 9.87878 0.882353C10.095 0.882353 10.2947 0.979412 10.4028 1.14706C10.461 1.23529 10.5276 1.37647 10.4777 1.56176L9.21335 5.92059L8.88064 7.05882H10.0035H13.522C13.863 7.05882 14.1874 7.20882 14.3787 7.46471C14.4869 7.59706 14.595 7.81765 14.5368 8.09118Z"
                                    fill="#0F0F0F"
                                  />
                                </svg>
                              )}

                              {totlaLikes}
                            </button>
                          </li>
                        </ul>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="info-sec">
                        <span>{courseDetail?.chapterViews || 0} views</span>
                        <p>
                          {filterChapter?.chapter_desc?.length < 200
                            ? filterChapter?.chapter_desc
                            : filterChapter?.chapter_desc.slice(0, 200)}

                          {showMore && filterChapter?.chapter_desc}

                          {filterChapter?.chapter_desc?.length > 200 && (
                            <button onClick={toggleShowMore}>
                              {showMore ? "...Show less" : "...Show more"}
                            </button>
                          )}
                        </p>
                      </div>
                    </Col>

                    {switchBtn2 == 2 && !quesizAnswerExist && (
                      <Col lg={12}>
                        {" "}
                        <div className="quiz-sec-main">
                          <Row>
                            <Col lg={12}>
                              <form onSubmit={formik.handleSubmit}>
                                {filterQuizChapter?.question?.map((item, i) => (
                                  <div key={i} className="quiz-sec">
                                    <h3>
                                      <span>{i + 1}.</span>{" "}
                                      {item?.questiontitle}
                                    </h3>

                                    <div className="quiz-options">
                                      <div className="mb-3 ms-4">
                                        {item?.option?.map((data, j) => (
                                          <div
                                            key={j}
                                            className="form-check mb-2"
                                          >
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              id={`Answer-${i}-${j}`} // Use unique IDs for inputs
                                              name={`quizzanswer[${i}].answer`} // Set radio input name to match formik field path
                                              value={data?.optiontitle}
                                              onChange={formik.handleChange}
                                              checked={
                                                formik.values.quizzanswer[i]
                                                  .answer === data?.optiontitle
                                              }
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor={`Answer-${i}-${j}`}
                                            >
                                              {data?.optiontitle}
                                            </label>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                                {filterQuizChapter?.question?.length > 0 && (
                                  <button type="submit" className="sub-btn">
                                    Submit
                                  </button>
                                )}
                              </form>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    )}
                  </Row>
                </div>
              </div>
            </Col>
            <Col xxl={4} xl={4} lg={4}>
              {" "}
              <div className="videos-tab">
                <ul>
                  <li>
                    <Link
                      to="#"
                      onClick={() => setSwitchBtn(1)}
                      className={`filt-tabs ${switchBtn == 1 && "active"}`}
                    >
                      Chapters
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      onClick={() => setSwitchBtn(2)}
                      className={`filt-tabs ${switchBtn == 2 && "active"}`}
                    >
                      More Like this
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      onClick={() => setSwitchBtn(3)}
                      className={`filt-tabs ${switchBtn == 3 && "active"}`}
                    >
                      Products Used
                    </Link>
                  </li>
                </ul>
              </div>
              {switchBtn == 1 && (
                <div className="playback-tabs-views">
                  {courseDetail?.chaptersarr?.map((item) => {
                    return (
                      <>
                        <div
                          className={
                            chapterId == item?._id
                              ? "click-active"
                              : "side-btns"
                          }
                          onClick={async () => {
                            const totalQuiz = courseDetail?.quizes;
                            if (totalQuiz?.length) {
                              let quizAnsArr = [];

                              for (let quiz of totalQuiz) {
                                if (quiz?.chapter_id) {
                                  const datarr = await dispatch(
                                    quizzAnswerdetails({
                                      chapterId: quiz?.chapter_id,
                                    })
                                  );

                                  if (datarr?.payload?.data) {
                                    quizAnsArr?.push({
                                      chapterId: quiz?.chapter_id,
                                      success: true,
                                    });
                                  } else {
                                    quizAnsArr?.push({
                                      chapterId: quiz?.chapter_id,
                                      success: false,
                                    });
                                  }
                                }
                              }
                              const result = findIndexBeforeChapterId(
                                quizAnsArr,
                                item?._id
                              );
                              const allSuccess = result?.every(
                                (item) => item.success === true
                              );
                              console.log(result, "result------");
                              if (!allSuccess) {
                                return toast.error(
                                  "Please complete your quiz before moving on to the next chapter!"
                                );
                              }
                            }
                            setIsVideoVisible(false);
                            setActiveTab(item?._id);
                            console.log("kuch bhi ");
                            navigate(
                              `/playback/${item?.course_id}/${item?._id}`
                            );
                          }}
                        >
                          <div className="playback-side-videos">
                            <div className="playback-side-inner">
                              {item?.cover_image || item?.chapter_cover_image ? (
                                <img
                                  className="thumb-img"
                                  src={(item?.cover_image || item?.chapter_cover_image)
                                    ?? require("../images/placeholder.png")
                                  }
                                />
                              ) : (
                                <Suspense fallback={<div>Loading...</div>}>
                                  <img
                                    className="thumb-img"
                                    src={(item?.cover_image || item?.chapter_cover_image)
                                      ?? require("../images/placeholder.png")
                                    }
                                  />
                                </Suspense>
                              )}
                              <div className="play-icon">
                                <img src={require("../images/Subtract.png")} />{" "}
                                {/* Play icon */}
                              </div>
                            </div>
                            {/* <img src={require("../images/vinner1.png")} /> */}
                            <div className="playback-v-desc">
                              <h2>{item?.chapter_title}</h2>
                              <h5>{courseDetail?.creator_id?.full_name}</h5>
                              {/* <p>6M views </p> */}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                  {courseDetail?.chaptersarr?.length == 0 && (
                    <>
                      <p className="no-available">No Chapters Available Yet!</p>
                    </>
                  )}
                </div>
              )}
              {switchBtn == 2 && (
                <div className="playback-tabs-views">
                  {moreLikeThisData?.map((item) => {
                    return (
                      <>
                        <Link
                          onClick={() => {
                            setIsVideoVisible(false);
                          }}
                          to={`/playback/${item?.course_id}/${item?._id}`}
                        >
                          <div className="playback-side-videos">
                            <div className="playback-side-inner">
                              {item?.cover_image ? (
                                <img
                                  className="thumb-img"
                                  src={
                                    item?.cover_image
                                      ? item?.cover_image
                                      : require("../images/placeholder.png")
                                  }
                                />
                              ) : (
                                <img
                                  src={item?.video}
                                //thumbnailHandler={(thumbnail) => console.log(thumbnail)}
                                //width={200}
                                //height={180}
                                //showPlayIcon={true}
                                />
                              )}
                              <div className="play-icon">
                                <img src={require("../images/Subtract.png")} />{" "}
                                {/* Play icon */}
                              </div>
                            </div>
                            {/* <img src={require("../images/vinner1.png")} /> */}
                            <div className="playback-v-desc">
                              <h2>{item?.chapter_title}</h2>
                              <h5>{courseDetail?.creator_id?.full_name}</h5>
                              {/* <p>6M views </p> */}
                            </div>
                          </div>
                        </Link>
                      </>
                    );
                  })}
                  {courseDetail?.chaptersarr?.length == 0 && (
                    <>
                      <p className="no-available">No Chapters Available Yet!</p>
                    </>
                  )}
                </div>
              )}
              {switchBtn == 3 && (
                <div className="playback-tabs-views">
                  {courseDetail?.products?.map((item) => {
                    return (
                      <>
                        <div className="playback-side-videos">
                          <div className="playback-side-inner">
                            <a href={item?.link} target="_blank" >
                              <img
                                className="thumb-img"
                                src={
                                  item?.product1
                                    ? item?.product1
                                    : require("../images/placeholder.png")
                                }
                              />
                            </a>

                            <div className="play-icon">
                              <img src={require("../images/Subtract.png")} />{" "}
                              {/* Play icon */}
                            </div>
                          </div>
                          {/* <img src={require("../images/vinner1.png")} /> */}
                          <div className="playback-v-desc">
                            <h2>{item?.name}</h2>
                            <h5>{item?.manufacturer_name}</h5>
                            <h3>£{parseFloat(item?.price).toFixed(2)}</h3>
                            {/* <p>6M views </p> */}
                          </div>
                        </div>
                      </>
                    );
                  })}
                  {courseDetail?.chaptersarr?.length == 0 && (
                    <>
                      <p className="no-available">No Chapters Available Yet!</p>
                    </>
                  )}
                </div>
              )}
            </Col>
          </Row>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton className="pb-0 quiz-popup"></Modal.Header>
          <Modal.Body>
            {passFail?.success && (
              <Col lg={12}>
                <div className="quiz-passed ">
                  <h2>Congratulations!</h2>
                  <p>You passed this quiz!</p>
                  <div className="answers-show-quiz">
                    <p>
                      {passFail?.attempt}/{passFail?.total}
                    </p>
                  </div>
                </div>
              </Col>
            )}
            {passFail?.success === false && (
              <Col lg={12}>
                <div className="quiz-passed failed ">
                  <h2>Quiz Failed!</h2>
                  <p>
                    Unfortunately you didn’t score high enough to pass this
                    time. Try Again
                  </p>
                  <div className="answers-show-quiz">
                    <p>
                      {passFail?.attempt}/{passFail?.total}
                    </p>
                  </div>
                </div>
              </Col>
            )}
          </Modal.Body>
          {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          
        </Modal.Footer> */}
        </Modal>

        <Modal
          show={show2}
          onHide={handleClose2}
          className="downloadables-main"
        >
          <Modal.Header closeButton>
            <Modal.Title> Course Downloadables</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {filterChapter?.doc?.map((item) => {
              return (
                <div
                  onClick={() => {
                    setDownloadObj(item)
                    handleDownload2()
                  }}
                  className="downloadables-show"
                >
                  <h2>{item?.title}</h2>
                  <button type="button">
                    <img src={require("../images/Download.svg").default} />
                  </button>
                </div>
              );
            })}
            {filterChapter?.doc?.length == 0 && <p>No docs available yet!</p>}
          </Modal.Body>
        </Modal>

        <Modal show={show3} onHide={handleClose3} className="validation-popup">
          <Modal.Body>
            Are you sure you want to download this chapter for offline viewing?
          </Modal.Body>
          <Modal.Footer className="flex-row justify-content-center">
            <Button variant="secondary" onClick={handleClose3}>
              No
            </Button>
            <Button variant="primary" onClick={handleDownload2}>
              YES
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </PlaybackLayout>
  );
}
