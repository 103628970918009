import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Form, Link } from "react-router-dom";
// import ProgressBar from "@ramonak/react-progress-bar";
import Navbar from "react-bootstrap/Navbar";
import CreatorLayout from "../elements/CreatorLayout";
import { Formik } from "formik";
import { getUserById, userEditProfile } from "../redux/actions/userAuth";
import { useDispatch, useSelector } from "react-redux";
import useAuthToken from "../Hooks/useAuthToken";
import toast from "react-hot-toast";
import moment from "moment";
export default function SettingsExpertise() {
  const dispatch = useDispatch();
  const tokenData = useAuthToken();
  const { getRegisterUser } = useSelector((state) => ({
    getRegisterUser: state.userMgmtSlice.getRegisterUser,
  }));
  useEffect(() => {
    dispatch(getUserById(tokenData?._id));
  }, []);
  return (
    <CreatorLayout>
      <Container>
        <div className="profile-top-box">
          <div className="box-left">
            <h2>Profile</h2>
            <p>Edit your Personal Details</p>
          </div>
          <div className="box-right">
            <div className="box-right">
              <p>{getRegisterUser?.full_name}</p>
              <span>
                Member Since:{" "}
                {moment(getRegisterUser?.createdAt).format("DD/MM/YYYY")}
              </span>
            </div>
          </div>
        </div>
        <div className="sign-forms-cmn-box">
          <div className="profile-s-step">
            <ul>
              <li>
                <Link to="/settings-profile">Profile Details</Link>
              </li>
              <li>
                <Link to="/settings-bio">Bio</Link>
              </li>
              <li>
                <Link to="/settings-expertise" className="active">
                  Your Expertise
                </Link>
              </li>
              <li>
                <Link to="/settings-additional">Additional Images</Link>
              </li>
            </ul>
          </div>
          <div className="sign-form-inner">
            <Formik
              enableReinitialize={true}
              initialValues={{
                specialisms: (getRegisterUser?.specialisms?.length &&
                  getRegisterUser?.specialisms) || ["", "", "", ""],
                x: getRegisterUser?.x || "",
                whatsapp: getRegisterUser?.whatsapp || "",
                youtube: getRegisterUser?.youtube || "",
                instagram: getRegisterUser?.instagram || "",
                facebook: getRegisterUser?.facebook || "",
              }}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                values.id = tokenData?._id;

                dispatch(userEditProfile(values)).then((data) => {
                  if (data?.payload?.success) {
                    resetForm();
                    dispatch(getUserById(tokenData?._id));
                    toast.success(data?.payload?.message);
                  }
                  setSubmitting(false);
                });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <form
                  onSubmit={(e) => {
                    // setValidateValue(true);
                    handleSubmit(e);
                  }}
                >
                  <div class="form-group mt-0 pt-2 mb-3">
                    <label>Expertise</label>
                    {values.specialisms?.length &&
                      values.specialisms.map((expertise, index) => (
                        <div key={index} className="form-group pt-2 mb-3">
                          <input
                            type="text"
                            name={`specialisms.${index}`}
                            value={expertise}
                            onChange={handleChange}
                            className="form-control"
                            placeholder={`${index + 1}.`}
                          />
                        </div>
                      ))}
                  </div>

                  <div class="form-group mt-0 pt-2 mb-3">
                    <label>Your Links</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Facebook"
                      name="facebook"
                      onChange={handleChange}
                      value={values.facebook}
                    />
                    <span className="formik-errors">
                      {errors.facebook && touched.facebook && errors.facebook}
                    </span>
                  </div>
                  <div class="form-group pt-2 mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Instagram"
                      name="instagram"
                      onChange={handleChange}
                      value={values.instagram}
                    />
                    <span className="formik-errors">
                      {errors.instagram &&
                        touched.instagram &&
                        errors.instagram}
                    </span>
                  </div>
                  <div class="form-group pt-2 mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="x(Twitter)"
                      name="x"
                      onChange={handleChange}
                      value={values.x}
                    />
                    <span className="formik-errors">
                      {errors.x && touched.x && errors.x}
                    </span>
                  </div>
                  <div class="form-group pt-2 mb-3">
                    <input
                      type="text"
                      name="youtube"
                      class="form-control"
                      placeholder="Youtube"
                      onChange={handleChange}
                      value={values.youtube}
                    />
                  </div>
                  <div class="form-group pt-2 mb-5">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Whatsapp"
                      name="whatsapp"
                      onChange={handleChange}
                      value={values.whatsapp}
                    />
                    <span className="formik-errors">
                      {errors.whatsapp && touched.whatsapp && errors.whatsapp}
                    </span>
                  </div>
                  <button to="#" type="submit" class="common-btn">
                    UPDATE
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </Container>
    </CreatorLayout>
  );
}
