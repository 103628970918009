import React, { useEffect } from "react";
import Layout from "../elements/Layout";
import { Container, Row, Col } from "react-bootstrap";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Rating } from "react-simple-star-rating";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { channelcourselist, getAllCourses } from "../redux/actions/userAction";
import parseDuration from "../utils/parseDuration ";
export default function ChannelsDetail() {

  const dispatch = useDispatch();
  const { id } = useParams();
  const { channelCourseDetail, allCourses } = useSelector((state) => ({
    channelCourseDetail: state.userMgmtSlice.channelCourseDetail,
    allCourses: state.userMgmtSlice.allCourses,
  }));

  console.log("allCourses", allCourses)

  const navigate = useNavigate();

  const handleClick = (id) => {
    navigate(`/course-overview/${id}`);
  };

  useEffect(() => {
    dispatch(channelcourselist(id))
  }, [id])

  useEffect(() => {
    dispatch(getAllCourses({ role: 'channel', channel_id: id }));
  }, []);

  console.log(channelCourseDetail, "channelCourseDetail");
  return (
    <Layout>
      <Container fluid>
        <div className="channel-detail-sec">
          <div className="banner">
            <img src={channelCourseDetail?.channel_icon ? channelCourseDetail?.channel_icon :
              require("../images/user-img.png")} />
          </div>
          <div className="banner-desc">
            <div className="details-logo">
              <img src={channelCourseDetail?.profile_image ? channelCourseDetail?.profile_image :
                require("../images/user-img.png")} />
            </div>
            <div className="details-desc">
              <h2>{channelCourseDetail?.full_name}</h2>
              <h4>{allCourses?.courses?.length} Courses, {allCourses?.courses?.reduce((sum, item) => sum + (item.chapters || 0), 0)} Videos</h4>
              <h4>0 Views</h4>
              <p style={{ whiteSpace: "pre-wrap" }}>
                {channelCourseDetail?.bio}
              </p>
            </div>
          </div>
        </div>
        <div className="details-videos">
          <div className="details-tab">
            <ul>
              <li>
                <Link to="#" className="active-tab-detail">
                  Courses
                </Link>
              </li>
              <li>
                {" "}
                <div class="form-group">
                  <img src={require("../images/search.svg").default} />
                  <input
                    type="search"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Search here..."
                  />
                </div>
              </li>
            </ul>
          </div>
          <div className="videos-sec-main mt-4">
            <div className="videos-view">
              {allCourses?.courses?.map((item, i) => {
                const totalRatingSum = item?.rates?.reduce(
                  (sum, rating) => sum + rating.rating,
                  0
                );
                const averageRating = totalRatingSum / item?.rates?.length;

                return (
                  <>
                    <div className="video-inner">
                      <img
                        onClick={() => handleClick(item?._id)}
                        className="thumb-img"
                        src={
                          item?.cover_image
                            ? item?.cover_image
                            : require("../images/Videoimg1.png")
                        }
                      />
                      <div className="video-dec">
                        <div className="user-logo">
                          <img
                            src={
                              item?.creator_details?.channel_icon
                                ?
                                item?.creator_details?.channel_icon
                                : require("../images/user-img.png")
                            }
                          />
                        </div>
                        <div className="user-desc">
                          <h3>{item?.course_title}</h3>
                          <div className="bottom-desc">
                            <div className="bottom-first">
                              <p>{item?.creator_name}</p>
                              <p>{item?.viewcount} views</p>
                              <p>
                                {item?.cost == 0 ? "Free" : `£ ${item?.cost}`}
                              </p>
                            </div>
                            <div className="bottom-first">
                              <p>
                                {item?.chapters == 1
                                  ? item?.chapters + " Video,"
                                  : item?.chapters + " Videos,"}{" "}
                                {parseDuration(item?.duration)}
                              </p>
                              <Rating initialValue={averageRating || 0} />
                              <p>({`${item?.rates?.length} ${item?.rates?.length == 1 ? `Review` : `Reviews`}`})</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}


            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
