import React, { useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import { Form, Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import { Formik } from "formik";
import { login } from "../redux/actions/userAuth";
import { useDispatch } from "react-redux";
import useFirebaseToken from "../Hooks/useFirebaseToken";
export default function SignIn() {
  const dispatch = useDispatch();
  const { getFireBaseToken } = useFirebaseToken();

  return (
    <div>
      <Navbar expand="lg" className="bg-body-tertiary landing-nav">
        <Container fluid>
          <Navbar.Brand href="#home" className="logo-main-nav">
            <img src={require("../images/logo-mian.png")} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          {/* <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="#home">Home</Nav.Link>
              <Nav.Link href="#link">Contact Us</Nav.Link>
            </Nav>
            <div className="main-login-btn">
              <Link to="/SignIn">Login</Link>
            </div>
          </Navbar.Collapse> */}
        </Container>
      </Navbar>
      <Container>
        <div className="sign-forms-cmn-box">
          <div className="sign-form-inner">
            <h2 className="h-22">Sign in to your account</h2>

            <span onClick={() => window.location.reload()}>
              New here? <Link to="/sign-up">Create an account</Link>
            </span>
            <Formik
              initialValues={{ email: "", password: "" }}
              validate={(values) => {
                const errors = {};
                if (!values.email) {
                  errors.email = "Required*";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Invalid email address";
                }

                if (!values.password) {
                  errors.password = "Required*";
                }

                return errors;
              }}
              onSubmit={async (values, { setSubmitting }) => {
                let fetchToken = await getFireBaseToken()
                console.log("fetchTOken", fetchToken)
                if (fetchToken) {
                  dispatch(login({ ...values, device_token: fetchToken, device_type: "2", device_modal: "web" }));
                }
                setSubmitting(false);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form
                  onSubmit={(e) => {
                    // setValidateValue(true);
                    handleSubmit(e);
                  }}
                >
                  <div class="form-group">
                    <label>Email</label>
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <span className="formik-errors">
                      {errors.email && touched.email && errors.email}
                    </span>
                  </div>
                  <div class="form-group mt-4 pt-2">
                    <label>Password</label>
                    <input
                      className="form-control"
                      name="password"
                      type="password"
                      placeholder="Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    <span className="formik-errors">
                      {errors.password && touched.password && errors.password}
                    </span>
                  </div>

                  <button type="submit" class="common-btn mt-10">
                    SIGN IN
                  </button>
                  <Link to="/forgot-password" className="forgot">
                    Forgot your password?
                  </Link>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </Container>
    </div>
  );
}
