import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Form, Link } from "react-router-dom";
// import ProgressBar from "@ramonak/react-progress-bar";
import Navbar from "react-bootstrap/Navbar";
import CreatorLayout from "../elements/CreatorLayout";
import { getAllCourses, getMyCertificates } from "../redux/actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import parseDuration from "../utils/parseDuration ";
import { Rating } from "react-simple-star-rating";
import moment from "moment";
import Lightbox from "../elements/LightBox";
import html2canvas from "html2canvas";
export default function CreatorCertificates() {
  const imageRef = useRef(null);
  const dispatch = useDispatch();
  const [file, setFile] = useState();
  const [cert, setAllCert] = useState([]);

  const { allCourses, getRegisterUser, allCompletedCourses } = useSelector((state) => ({
    allCourses: state.userMgmtSlice.allCourses,
    allCompletedCourses: state.userMgmtSlice.allCompletedCourses,
    getRegisterUser: state.userMgmtSlice.getRegisterUser,
  }));
  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  useEffect(() => {
    dispatch(getAllCourses({ certificate: "yes" }));

    dispatch(getMyCertificates()).then((data) => {

      if (data?.payload?.data?.length) {
        setAllCert(data?.payload?.data);
      }
    });
  }, []);


  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');


  const openLightbox = (image) => {
    setSelectedImage(image);
    setIsLightboxOpen(true);
  };

  const closeLightbox = () => {
    setIsLightboxOpen(false);
    setSelectedImage('');
  };



  return (
    <CreatorLayout>
      <Container>


        <div className="profile-detail-box">
          <div className="course-top-box ">
            <div className="box-left">
              {/* <input type="file" onChange={(e) => setFile(e.target.files[0])} /> */}
              <h2
              // onClick={handleCapture2}
              >My Certificates</h2>
              <p>View or Download your Certificates</p>
            </div>
            <div className="box-right">
              <p>{getRegisterUser?.full_name}</p>
              <span>
                Member Since:{" "}
                {moment(getRegisterUser?.createdAt).format("DD/MM/YYYY")}
              </span>
            </div>
          </div>

          <Row className="mt-5">
            <div className="videos-view">
              {/* {allCourses?.courses?.map((item, i) => {
                const totalRatingSum = item?.rates?.reduce(
                  (sum, rating) => sum + rating.rating,
                  0
                );
                const averageRating = totalRatingSum / item?.rates?.length;

                return (
                  item?.certificate &&
                  <>


                    <Col lg={4}>
                      <div className="certificates-card-box">
                        <img
                          className="certificates-min-img"
                          src={item?.certificate ? item?.certificate : require("../images/imagesplaceholder.png")}
                        />
                        <div className="certificates-user">
                          <img src={
                            item?.creator_details?.profile_image
                              ?
                              item?.creator_details?.profile_image
                              : require("../images/userico.png")
                          } />
                          <div className="certificates-user-text">
                            <h3>{item?.course_title}</h3>
                            <div className="certificates-time">
                              <span> {item?.chapters == 1
                                ? item?.chapters + " Video,"
                                : item?.chapters + " Videos,"}{" "}
                                {parseDuration(item?.duration)}</span>
                              <span>{item?.viewcount} views</span>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between mt-3">
                          <button onClick={() => {
                            // URL of the image to be downloaded
                            const imageUrl = item?.certificate;
                            const link = document.createElement('a');
                            link.href = imageUrl;
                            link.download = 'downloaded-image.jpg';
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                          }} type="button" className="btn-dwnld">
                            Download
                          </button>
                          <button type="button" className="btn-view" onClick={() => openLightbox(item?.certificate)}>
                            View
                          </button>
                        </div>
                      </div>
                    </Col>
                  </>
                );
              })} */}
              {/* {
                allCourses?.courses?.length == 0 && <div className="no-data"><p>No content available yet!</p></div>
              } */}

              {
                cert?.map((item) => {
                  return (
                    <>
                      <Col lg={4}>
                        <div className="certificates-card-box">
                          <img
                            className="certificates-min-img"
                            src={item?.course_id?.certificate ? item?.course_id?.certificate : require("../images/imagesplaceholder.png")}
                          />
                          <div className="certificates-user">
                            <img src={
                              item?.course_id?.creator_id?.profile_image
                                ?
                                item?.course_id?.creator_id?.profile_image
                                : require("../images/userico.png")
                            } />
                            <div className="certificates-user-text">
                              <h3>{item?.course_id?.course_title}</h3>
                              <div className="certificates-time">
                                <span> {item?.course_id?.total_video <= 1
                                  ? item?.course_id?.total_video + " Video"
                                  : item?.course_id?.total_video + " Videos"}
                                  {/* {parseDuration(item?.duration)} */}
                                  {", " + item?.course_id?.viewcount} views
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between mt-3">
                            <button onClick={() => {
                              // URL of the image to be downloaded
                              const imageUrl = item?.certificate;
                              const link = document.createElement('a');
                              link.href = imageUrl;
                              link.download = 'downloaded-image.jpg';
                              document.body.appendChild(link);
                              link.click();
                              document.body.removeChild(link);
                            }} type="button" className="btn-dwnld">
                              Download
                            </button>
                            <button type="button" className="btn-view" onClick={() => openLightbox(item?.certificate)}>
                              View
                            </button>
                          </div>
                        </div>
                      </Col>
                    </>
                  )
                })
              }

              {
                cert?.length == 0 && <div className="no-data"><p>No content available yet!</p></div>
              }
            </div>

            {/* <div className="image-gallery">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Thumbnail ${index + 1}`}
              className="thumbnail"
              onClick={() => openLightbox(image)}
            />
          ))}
        </div> */}

            <Lightbox image={selectedImage} isOpen={isLightboxOpen} onClose={closeLightbox} />

          </Row>
        </div>
      </Container>
    </CreatorLayout>
  );
}
