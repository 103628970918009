import axios from "axios";
console.log(localStorage.getItem("accessToken"), "localStorage.getIte");
const Api = axios.create({
  baseURL: process.env.REACT_APP_BUFF_BASE_URL,
  headers: {
    authorization: localStorage.getItem("accessToken"),
  },
});

Api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status == 401) {
      // sessionStorage.clear();
      // localStorage.clear();
      // window.location.href = '/'
    }
    return error.response;
  }
);

export default Api
