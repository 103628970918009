import React, { useEffect } from "react";
import Layout from "../elements/Layout";
import { Container } from "react-bootstrap";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getChannels } from "../redux/actions/userAction";

export default function Channels() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { channels } = useSelector((state) => ({
    channels: state.userMgmtSlice.channels,
  }));

  useEffect(() => {
    dispatch(getChannels());
  }, []);

  console.log(channels, "channels");

  return (
    <Layout>
      <Container fluid>
        <div className="videos-sec-main">
          <div className="videos-view">
            {channels?.channels?.map((item) => {
              return (
                <>
                  <div className="video-inner channel">
                    <Link to={`/channels-details/${item?._id}`}>
                      <img
                        className="thumb-img"
                        src={
                          item?.channel_icon
                            ? 
                              item?.channel_icon
                            : require("../images/imagesplaceholder.png")
                        }
                      />
                      <div className="video-dec">
                        <div className="user-logo">
                          <img
                            src={
                              item?.profile_image
                                ? 
                                  item?.profile_image
                                : require("../images/userico.png")
                            }
                          />
                        </div>
                        <div className="user-desc">
                          <h3>{item?.full_name}</h3>
                          <div className="bottom-desc">
                            <div className="bottom-first">
                              <p>0 views</p>

                              <p>{item?.courses?.length} Courses</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </>
              );
            })}
            {channels?.channels?.length == 0 && (
              <div className="no-data-div">
                <p>Currently there is no channel</p>
              </div>
            )}
          </div>
        </div>
      </Container>
    </Layout>
  );
}
