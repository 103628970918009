import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import Api from "../../services/Api";
import axios from "axios";



const userRegister = createAsyncThunk(
  "userRegister",
  async (signupObj) => {
    const { data } = await Api.post("/auth/register", signupObj);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);

const verifyOtp = createAsyncThunk(
  "verifyOtp",
  async (signupObj) => {
    const { data } = await Api.post("/auth/verifyOtp", signupObj);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);

const resendOtp = createAsyncThunk(
  "resendOtp",
  async (signupObj) => {
    const { data } = await Api.post("/auth/resendOtp", signupObj);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);

const updateEmail = createAsyncThunk(
  "updateEmail",
  async (signupObj) => {
    const { data } = await Api.post("/auth/updateEmail", signupObj);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);



const userEditProfile = createAsyncThunk(
  "userEditProfile",
  async (signupObj) => {
    console.log("signupObj", signupObj)

    const filteredSignupObj = Object.keys(signupObj)
      .filter(key => key !== 'token')
      .reduce((acc, key) => {
        acc[key] = signupObj[key];
        return acc;
      }, {});

    console.log("filteredSignupObj", filteredSignupObj)

    if (signupObj?.token) {
      const url = process.env.REACT_APP_BUFF_BASE_URL + `/creator/editMyprofile`;
      const config = {
        headers: {
          'authorization': signupObj?.token,
          'Content-Type': 'application/json'
        }
      };

      const { data } = await axios.post(url, filteredSignupObj, config);

      return data;

    } else {
      const { data } = await Api.post("/creator/editMyprofile", signupObj);
      if (!data?.success) {
        toast.error(data?.message);
      }
      return data;
    }


  }
);

const getStatics = createAsyncThunk(
  "getStatics",
  async () => {
    const { data } = await Api.get("/getStatics");
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);

const getProductList = createAsyncThunk(
  "getProductList",
  async () => {
    const { data } = await Api.get("/creator/getproductlist");
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);


const getUserById = createAsyncThunk(
  "getUserById",
  async (id) => {
    const { data } = await Api.get(`/auth/getUserById?id=${id}`);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);



const login = createAsyncThunk(
  "login",
  async (loginObj) => {
    const { data } = await Api.post("/auth/login", loginObj);
    if (data?.success && (data?.data?.role == 1 || data?.data?.role == 2)) {
      localStorage.setItem("accessToken", data?.data?.token);
      localStorage.setItem("userId", data?.data?._id);
      toast.success(data?.message);
      window.location.href = "/home";
    } else if (data?.success && data?.data?.email_verified == 1 && data?.data?.tages?.length && data?.data?.adminapproved == 1) {
      localStorage.setItem("accessToken", data?.data?.token);
      localStorage.setItem("userId", data?.data?._id);
      toast.success(data?.message);
      window.location.href = "/home";
    } else if (data?.data?.email_verified == 0) {
      toast.error("Please complete your form first before further proceed.");
      setTimeout(() => {
        window.location.href = `/sign-up?id=${data?.data?._id}`;
      }, 1000)

    } else if (data?.data?.tages?.length == 0) {
      toast.error("Please complete your form first before further proceed.");
      // localStorage.setItem("accessToken", data?.data?.token);
      setTimeout(() => {
        window.location.href = `/sign-up?id=${data?.data?._id}&accessToken=${data?.data?.token}`;
      }, 1000)
    } else if (data?.data?.adminapproved == 0) {
      toast.error("After approval by the administrator, you may proceed further.");
      // localStorage.setItem("accessToken", data?.data?.token);
      setTimeout(() => {
        window.location.href = `/sign-up?id=${data?.data?._id}&accessToken=${data?.data?.token}`;
      }, 1000)
    } else if (!data?.success) {
      toast.error(data?.message);
    }

  }
);

const forgotPassword = createAsyncThunk(
  "forgotPassword",
  async (data, thunkAPI) => {
    const response = await Api.post("/auth/forgotPass", data);
    return response.data;
  }
);

const resetPassword = createAsyncThunk(
  "resetPassword",
  async (data, thunkApi) => {
    const url = process.env.REACT_APP_BUFF_BASE_URL + `/auth/resetPass`;
    const config = {
      headers: {
        'authorization': data?.token,
        'Content-Type': 'application/json'
      }
    };
    const response = await axios.put(url, data, config);
    return response.data;
  }
);

const changePass = createAsyncThunk(
  "changePass",
  async (data, thunkAPI) => {
    const response = await Api.post("/common/updatePassword", data);
    return response.data;
  }
);


export {
  login,
  getStatics,
  getProductList,
  verifyOtp,
  resendOtp,
  userRegister,
  getUserById,
  updateEmail,
  changePass,
  userEditProfile,
  forgotPassword,
  resetPassword
};
