import React, { useEffect, useRef, useState } from "react";
import { Button, Container } from "react-bootstrap";
import {
  Form,
  Link,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
// import ProgressBar from "@ramonak/react-progress-bar";
import Navbar from "react-bootstrap/Navbar";
import CreatorLayout from "../elements/CreatorLayout";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  addChapter,
  editChapter,
  getChapterById,
  uploadVideoInBackground,
} from "../redux/actions/userAction";
import toast from "react-hot-toast";
import SettingsAddDocument from "./SettingsAddDocument";
import ProgressBar from "@ramonak/react-progress-bar";
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
export default function SettingsAddChapter() {
  const videoRef = useRef(null);
  let query = useQuery();
  let queryId = query.get("id");
  const navigate = useNavigate();
  const { courseId } = useParams();
  const dispatch = useDispatch();
  const [file, setFile] = useState();
  const [prevVideo, setPrevVideo] = useState();
  const [uploadDoc, setUploadDoc] = useState();
  const [prevImg, setPrevImg] = useState();
  const [loader, setLoader] = useState(false);
  const { uploadProgress, uploadTime } = useSelector((state) => ({
    uploadProgress: state.userMgmtSlice.uploadProgress.percent,
    uploadTime: state.userMgmtSlice.uploadProgress.uploadTime,
  }));

  const [videoName, setvideoName] = useState("");
  const [imgName, setImgName] = useState("");
  const [chapterDetail, setChapterDetail] = useState();
  const [progress, setProgress] = useState(0);
  const [progressVideo, setProgressVideo] = useState(0);

  useEffect(() => {
    if (queryId) {
      dispatch(getChapterById(queryId)).then((data) => {
        setChapterDetail(data?.payload?.data);
      });
    }
  }, [queryId]);

  console.log(chapterDetail, "chapterDetail");

  useEffect(() => {
    return () => {
      if (prevVideo) {
        URL.revokeObjectURL(prevVideo);
      }
    };
  }, [prevVideo]);

  return (
    <CreatorLayout>
      <Container>
        <Formik
          enableReinitialize={true}
          initialValues={{
            chapter_title: chapterDetail?.chapter_title || "",
            chapter_desc: chapterDetail?.chapter_desc || "",
            learn: chapterDetail?.learn || "",
            video: chapterDetail?.video || "",
            doc: chapterDetail?.doc || [{ title: "", docfilename: "" }],
            cover_image:
              chapterDetail?.cover_image ||
              chapterDetail?.chapter_cover_image ||
              "",
          }}
          validate={(values) => {
            const errors = {};

            if (!values.chapter_title) {
              errors.chapter_title = "Required*";
            }
            if (!values.chapter_desc) {
              errors.chapter_desc = "Required*";
            }
            if (!values.learn) {
              errors.learn = "Required*";
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setLoader(true);
            console.log(values, "chapter------------");
            values.course_id = courseId;
            const formData = new FormData();
            for (let key in values) {
              if (key == "doc") {
                const { emptyValues, filledValues } = values[key].reduce(
                  (acc, item) => {
                    if (!item.title || !item.docfilename) {
                      acc.emptyValues.push(item);
                    } else {
                      acc.filledValues.push(item);
                    }
                    return acc;
                  },
                  { emptyValues: [], filledValues: [] }
                );
                console.log("filevalues", filledValues);
                formData.append(key, JSON.stringify(filledValues));
              } else if (key !== "video") {
                formData.append(key, values[key]);
              }
            }
            if (chapterDetail) {
              formData.append(`chapter_id`, chapterDetail?._id);
              dispatch(editChapter(formData)).then((data) => {
                if (data?.payload?.success) {
                  const formData2 = new FormData();
                  formData2.append("chapter_id", data?.payload?.data?._id);
                  formData2.append(`video`, values?.video);
                  if (prevVideo) {
                    dispatch(
                      uploadVideoInBackground({
                        obj: formData2,
                        video_title: data?.payload?.data,
                      })
                    ).then((item) => {
                      console.log(item, "itemitemitemitem");
                    });
                  }
                  toast.success(data?.payload?.message);
                  navigate(`/settings-edit-course/${courseId}`);
                  setLoader(false);
                }
                setSubmitting(false);
              });
            } else {
              dispatch(addChapter(formData)).then((data) => {
                if (data?.payload?.success) {
                  const formData2 = new FormData();
                  formData2.append("chapter_id", data?.payload?.data?._id);
                  formData2.append(`video`, values?.video);
                  dispatch(
                    uploadVideoInBackground({
                      obj: formData2,
                      video_title: data?.payload?.data,
                    })
                  ).then((item) => {
                    console.log(item, "itemitemitemitem");
                  });
                  // setTimeout(() => {
                  toast.success(data?.payload?.message);
                  navigate(`/settings-edit-course/${courseId}`);
                  setLoader(false);
                  //    }, 2000)
                }
                setSubmitting(false);
              });
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) =>
            !uploadDoc ? (
              <>
                <div className="profile-top-box">
                  <div className="box-left">
                    <div className="backpage-arrow">
                      <Link to={`/settings-edit-course/${courseId}`}>
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                          >
                            <path
                              d="M10 20.0003L8.93934 21.061L7.87868 20.0003L8.93934 18.9397L10 20.0003ZM30 18.5003C30.8284 18.5003 31.5 19.1719 31.5 20.0003C31.5 20.8288 30.8284 21.5003 30 21.5003V18.5003ZM15.606 27.7277L8.93934 21.061L11.0607 18.9397L17.7273 25.6063L15.606 27.7277ZM8.93934 18.9397L15.606 12.273L17.7273 14.3943L11.0607 21.061L8.93934 18.9397ZM10 18.5003H30V21.5003L10 21.5003L10 18.5003Z"
                              fill="#40413A"
                            />
                          </svg>
                        </span>
                        Back to the previous page.
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="edit-course-box">
                  <div className="edit-cou-top">
                    <div className="box-left">
                      <h2>{chapterDetail ? "Edit" : "ADD"} Chapter</h2>
                    </div>
                    {/* <div className="box-right">
              <button type="button" className="add-cerft">
                Add Certificate
              </button>
            </div> */}
                  </div>
                  <hr />
                  <div className="sign-form-inner">
                    <form
                      onSubmit={(e) => {
                        // setValidateValue(true);
                        handleSubmit(e);
                      }}
                    >
                      <div className="video-upload-additional mt-4">
                        <div class="form-group mb-5 video-new-upload">
                          <h2 className="mt-3">Upload Cover Image</h2>
                          <div className="d-flex justify-content-center">
                            <button type="button">
                              <input
                                type="file"
                                name="cover_image"
                                onChange={(e) => {
                                  if (e.target.files.length != 0) {
                                    setPrevImg(
                                      URL.createObjectURL(e.target.files[0])
                                    );

                                    setFieldValue(
                                      "cover_image",
                                      e.target.files[0]
                                    );
                                    setImgName(e.target.files[0]?.name);

                                    const steps = [0, 100];
                                    steps.forEach((value, index) => {
                                      setTimeout(() => {
                                        setProgress(value);
                                      }, index * 1000);
                                    });
                                  }
                                }}
                              />
                              Upload Image
                            </button>
                          </div>
                          {imgName ? (
                            <div className="uploaded-video-view">
                              <h3 className="mb-2">{imgName}</h3>
                            </div>
                          ) : typeof values?.cover_image == "string" &&
                            values?.cover_image != "" ? (
                            <h6>{values?.cover_image?.split("/").pop()}</h6>
                          ) : (
                            <p className="text-center mt-4">No File Found</p>
                          )}
                          {prevImg ? (
                            <>
                              <button
                                className="cancel-btn"
                                type="button"
                                onClick={() => {
                                  setPrevImg();
                                  setImgName();
                                  setProgress(0);
                                  setFieldValue("cover_image", "");
                                }}
                              >
                                <img src={require("../images/close.png")} />
                              </button>
                              <img className="show-img" src={prevImg} />
                              <ProgressBar
                                className="progress-line"
                                completed={progress}
                                bgColor={progress == 100 ? "#65c365" : "black"}
                                height="5px"
                              />
                            </>
                          ) : (
                            values.cover_image && (
                              <>
                                <img
                                  className="show-img"
                                  src={values?.cover_image}
                                />

                                <button
                                  className="cancel-btn"
                                  type="button"
                                  onClick={() => {
                                    setPrevImg();
                                    setImgName();
                                    setProgress(0);
                                    setFieldValue("cover_image", "");
                                  }}
                                >
                                  <img src={require("../images/close.png")} />
                                </button>
                              </>
                            )
                          )}
                        </div>

                        {/* 
                        <div class="form-group ">
                          <div className="img-upload-additional">
                            <div className="additional-img-upload">
                              <div
                                className="upload-img">
                              </div>
                              <input
                                type="file"
                                name="cover_image"
                                onChange={(e) => {
                                  setPrevImg(
                                    URL.createObjectURL(e.target.files[0])
                                  );
                                  setFieldValue(
                                    "cover_image",
                                    e.target.files[0]
                                  );
                                }}
                              ></input>
                              {prevImg ? (
                                <img className="show-img" src={prevImg} />
                              ) : (
                                values.cover_image && (
                                  <img
                                    className="show-img"
                                    src={values.cover_image}
                                  />
                                )
                              )}
                            </div>
                            <p>
                              <b>Add a Cover Image</b>
                            </p>
                            <p className="dimension-label">
                              <b>400 * 200 (upto 1024kb)</b>
                            </p>
                          </div>
                        </div> */}

                        <div
                          className={`form-group mb-5 video-new-upload ${
                            values?.video || prevVideo
                              ? `img-upload-additional`
                              : ``
                          }`}
                        >
                          <h2 className="mt-3">Upload Video Here</h2>
                          <div className="d-flex justify-content-center">
                            <button type="button">
                              <input
                                type="file"
                                accept="video/*"
                                name="video"
                                onChange={(e) => {
                                  const file = e.target.files[0];
                                  if (file) {
                                    const url = URL.createObjectURL(file);
                                    setPrevVideo(url);
                                    setvideoName(file.name);
                                  }
                                  setFieldValue("video", e.target.files[0]);

                                  const steps = [0, 100];
                                  steps.forEach((value, index) => {
                                    setTimeout(() => {
                                      setProgressVideo(value);
                                    }, index * 1000);
                                  });
                                }}
                              />
                              Upload Video
                            </button>
                          </div>
                          {videoName ? (
                            <div className="uploaded-video-view">
                              <h3>{videoName}</h3>
                              {/* <ProgressBar completed={uploadProgress || 0} bgColor="black" /> */}
                              {/* <button type="button" className="cancel-btn"><img src={require("../images/cancelbtn.svg").default}/></button> */}
                            </div>
                          ) : typeof values?.video == "string" &&
                            values?.video != "" ? (
                            <h6>{values?.video?.split("/").pop()}</h6>
                          ) : (
                            <p className="text-center mt-4">No File Found</p>
                          )}

                          {prevVideo ? (
                            <>
                              <button
                                className="cancel-btn"
                                type="button"
                                onClick={() => {
                                  setPrevVideo();
                                  setvideoName();
                                  setProgressVideo(0);
                                  setFieldValue("video", "");
                                }}
                              >
                                <img src={require("../images/close.png")} />
                              </button>

                              <video
                                ref={videoRef}
                                src={prevVideo}
                                type="video/mp4"
                                onLoadedMetadata={() => {
                                  if (videoRef.current) {
                                    videoRef.current.currentTime = 5; // Set to the time you want for the preview
                                  }
                                }}
                                controls
                              ></video>
                              <ProgressBar
                                className="progress-line"
                                completed={progressVideo}
                                bgColor={
                                  progressVideo == 100 ? "#65c365" : "black"
                                }
                                height="5px"
                              />
                            </>
                          ) : (
                            values.video && (
                              <>
                                <video
                                  ref={videoRef}
                                  src={values?.video}
                                  type="video/mp4"
                                  onLoadedMetadata={() => {
                                    if (videoRef.current) {
                                      videoRef.current.currentTime = 5; // Set to the time you want for the preview
                                    }
                                  }}
                                  controls
                                ></video>

                                <button
                                  className="cancel-btn"
                                  type="button"
                                  onClick={() => {
                                    setPrevVideo();
                                    setvideoName();
                                    setProgressVideo(0);
                                    setFieldValue("video", "");
                                  }}
                                >
                                  <img src={require("../images/close.png")} />
                                </button>
                              </>
                            )
                          )}
                        </div>

                        {/*  */}
                      </div>

                      <div class="form-group">
                        <label for="exampleInputEmail1">Chapter Title</label>
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby="emailHelp"
                          placeholder="Type here..."
                          name="chapter_title"
                          onChange={handleChange}
                          value={values.chapter_title}
                        />
                        <span className="formik-errors">
                          {errors.chapter_title &&
                            touched.chapter_title &&
                            errors.chapter_title}
                        </span>
                      </div>
                      <div class="form-group mt-4 pt-2">
                        <label>Chapter Description</label>
                        <textarea
                          rows={5}
                          className="form-control"
                          aria-describedby="chapter_desc"
                          placeholder="Type here..."
                          name="chapter_desc"
                          onChange={handleChange}
                          value={values.chapter_desc}
                        />
                        <span className="formik-errors">
                          {errors.chapter_desc &&
                            touched.chapter_desc &&
                            errors.chapter_desc}
                        </span>
                      </div>
                      <div class="form-group mt-4 pt-2">
                        <label>What will your students learn</label>
                        <textarea
                          rows={5}
                          class="form-control"
                          placeholder="Type here..."
                          name="learn"
                          onChange={handleChange}
                          value={values.learn}
                        />
                        <span className="formik-errors">
                          {errors.learn && touched.learn && errors.learn}
                        </span>
                      </div>
                      <button
                        onClick={() => {
                          setUploadDoc(true);
                        }}
                        type="submit"
                        class="common-btn mt-5"
                      >
                        UPLOAD DOCUMENTS
                      </button>
                      <button
                        type="submit"
                        class={`common-btn revert-btn mt-3 ${
                          !chapterDetail && `revert-btn`
                        }`}
                        disabled={isSubmitting}
                      >
                        {chapterDetail ? "UPDATE" : "CREATE"} CHAPTER
                      </button>
                    </form>
                  </div>
                </div>
                {loader && (
                  <div className="loader-main">
                    <div class="loader"></div>
                  </div>
                )}
              </>
            ) : (
              <SettingsAddDocument
                setUploadDoc={setUploadDoc}
                setFieldValue={setFieldValue}
                values={values}
              />
            )
          }
        </Formik>
      </Container>
    </CreatorLayout>
  );
}
