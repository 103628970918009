import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Form, Link } from "react-router-dom";
// import ProgressBar from "@ramonak/react-progress-bar";
import Navbar from "react-bootstrap/Navbar";
import SettingLayout from "../elements/SettingLayout";
export default function SettingsCertificates() {
  const [file, setFile] = useState();
  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  return (
    <SettingLayout>
      <Container>
        <div className="profile-detail-box">
          <div className="course-top-box ">
            <div className="box-left">
              <h2>My Certificates</h2>
              <p>View or Download your Certificates</p>
            </div>
            <div className="box-right">
              <p>Megan Bulmer</p>
              <span>Member Since: 12/06/2023</span>
            </div>
          </div>
          <Row className="mt-5">
            <Col lg={4}>
              <div className="certificates-card-box">
                <img
                  className="certificates-min-img"
                  src={require("../images/ashed.png")}
                />
                <div className="certificates-user">
                  <img src={require("../images/Videoimg2.png")} />
                  <div className="certificates-user-text">
                    <h3>Eyebrow Tinting Basics</h3>
                    <div className="certificates-time">
                      <span>3 Videos, 23:32</span>
                      <span>5,230 views</span>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-3">
                  <button type="button" className="btn-dwnld">
                    Download
                  </button>
                  <button type="button" className="btn-view">
                    View
                  </button>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="certificates-card-box">
                <img
                  className="certificates-min-img"
                  src={require("../images/imagesplaceholder.png")}
                />
                <div className="certificates-user">
                  <img src={require("../images/userico.png")} />
                  <div className="certificates-user-text">
                    <h3>Eyebrow Tinting Basics</h3>
                    <div className="certificates-time">
                      <span>3 Videos, 23:32</span>
                      <span>5,230 views</span>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-3">
                  <button type="button" className="btn-dwnld">
                    Download
                  </button>
                  <button type="button" className="btn-view">
                    View
                  </button>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="certificates-card-box">
                <img
                  className="certificates-min-img"
                  src={require("../images/ashed.png")}
                />
                <div className="certificates-user">
                  <img src={require("../images/Videoimg2.png")} />
                  <div className="certificates-user-text">
                    <h3>Eyebrow Tinting Basics</h3>
                    <div className="certificates-time">
                      <span>3 Videos, 23:32</span>
                      <span>5,230 views</span>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-3">
                  <button type="button" className="btn-dwnld">
                    Download
                  </button>
                  <button type="button" className="btn-view">
                    View
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </SettingLayout>
  );
}
