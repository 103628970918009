import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react'
import { Container } from './Container.js'
import { CustomDragLayer } from './CustomDragLayer.js'
import html2canvas from 'html2canvas';
import { editCourse } from '../../redux/actions/userAction.js';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';

const Example = forwardRef(({ courseDetail, handleCloseView, handleSubmit, selectedFile, values, certStep, setSelectedFile, setFieldValue, type }, ref) => {
  const dispatch = useDispatch()
  const imageRef = useRef(null);
  const [snapToGridAfterDrop, setSnapToGridAfterDrop] = useState(false)
  const [snapToGridWhileDragging, setSnapToGridWhileDragging] = useState(false)
  const handleSnapToGridAfterDropChange = useCallback(() => {
    setSnapToGridAfterDrop(!snapToGridAfterDrop)
  }, [snapToGridAfterDrop])
  const handleSnapToGridWhileDraggingChange = useCallback(() => {
    setSnapToGridWhileDragging(!snapToGridWhileDragging)
  }, [snapToGridWhileDragging])



  useImperativeHandle(ref, () => ({
    handleCapture() {
      html2canvas(imageRef.current).then((canvas) => {
        // const link = document.createElement('a');
        // link.download = 'certificate.png';
        // link.href = canvas.toDataURL();
        canvas.toBlob((blob) => {
          console.log(values, "valuesvaluesvalues");
          const formData = new FormData();

          const generateUniqueFilename = () => {
            const timestamp = Date.now();
            return `certificate-${timestamp}.png`;
          };

          //  formData.append('certificate', blob, generateUniqueFilename());
          formData.append('certificate', values?.certificate);
          formData.append(`certificate_x_axis`, values?.certificate_x_axis);
          formData.append(`certificate_y_axis`, values?.certificate_y_axis);
          formData.append(`certificate_x_axis2`, values?.certificate_x_axis2);
          formData.append(`certificate_y_axis2`, values?.certificate_y_axis2);
          formData.append(`status`, 1);
          // if (type == "Add") {
          //   formData.append(`status`, 1);
          // } else {
          //   formData.append(`status`, 0);
          // }
          if (courseDetail) {
            formData.append(`id`, courseDetail?._id);
            dispatch(editCourse(formData)).then((data) => {
              if (data?.payload?.success) {

                //   resetForm();
                toast.success('Certificate added successfully.');
                setTimeout(()=>{
                  window.location.reload();
                },1000);
                // navigate('/settings-courses')
                handleCloseView();
                setSelectedFile()

              }

            });
          }
        })

      });
    }
  }));

  return (
    <div ref={imageRef}>
      {/* <button onClick={handleCapture} style={{ marginTop: '20px', padding: '10px 20px', fontSize: '16px' }}>
          Capture Certificate
        </button> */}
      <Container setSelectedFile={setSelectedFile} setFieldValue={setFieldValue} certStep={certStep} selectedFile={selectedFile} snapToGrid={snapToGridAfterDrop} values={values} />
      {/* <CustomDragLayer snapToGrid={snapToGridWhileDragging} /> */}
      {/* <p>
        <label htmlFor="snapToGridWhileDragging">
          <input
            id="snapToGridWhileDragging"
            type="checkbox"
            checked={snapToGridWhileDragging}
            onChange={handleSnapToGridWhileDraggingChange}
          />
          <small>Snap to grid while dragging</small>
        </label>
        <br />
        <label htmlFor="snapToGridAfterDrop">
          <input
            id="snapToGridAfterDrop"
            type="checkbox"
            checked={snapToGridAfterDrop}
            onChange={handleSnapToGridAfterDropChange}
          />
          <small>Snap to grid after drop</small>
        </label>
      </p> */}
    </div>
  )
})

export default Example
