import React from 'react';

import {io} from "socket.io-client";

export const socket = io.connect(process.env.REACT_APP_SOCKET_URL);

console.log(socket,"process.env.REACT_APP_SOCKET_URL");

socket.on('connect', (err) => {
  console.log(`I'm connected with sdsad backdc-end`);
});
socket.emit('new-user-add', localStorage.getItem("userId"));

export const SocketContext = React.createContext(null);      