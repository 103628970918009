import React from 'react';

const LoaderIconShow = ({ toggleIconShow }) => {
    return (
        <button className="close-btn" onClick={toggleIconShow}>
            <img src={require("../images/arrow-left.svg").default} alt="Toggle Icon" />
        </button>
    );
}

export default LoaderIconShow;
