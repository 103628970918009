import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Form, Link } from "react-router-dom";
// import ProgressBar from "@ramonak/react-progress-bar";
import Navbar from "react-bootstrap/Navbar";
import SettingLayout from "../elements/SettingLayout";
import CreatorLayout from "../elements/CreatorLayout";
import { useDispatch, useSelector } from "react-redux";
import { getAllPurchase, walletInfo } from "../redux/actions/userAction";
import moment from "moment";
import { getUserById } from "../redux/actions/userAuth";
import useAuthToken from "../Hooks/useAuthToken";
import { formatCurrency } from "../components/commonFunction";
export default function CreatorInvoice() {
  const dispatch = useDispatch();
  const tokenData = useAuthToken();
  const { purchases, getRegisterUser, wallet } = useSelector((state) => ({
    purchases: state.userMgmtSlice.purchases,
    getRegisterUser: state.userMgmtSlice.getRegisterUser,
    wallet: state.userMgmtSlice.wallet,
  }));


  useEffect(() => {
    dispatch(walletInfo());
    dispatch(getAllPurchase());
    dispatch(getUserById(tokenData?._id))
  }, [])

  console.log(wallet, "purchases");

  return (
    <CreatorLayout>
      <Container>
        <div className="profile-detail-box">
          <div className="course-top-box ">
            <div className="box-left">
              <h2>Invoice</h2>
              <p>See all of your purchases</p>
            </div>
            <div className="box-right">
              <p>{getRegisterUser?.full_name}</p>
              <span>Member Since: {moment(getRegisterUser?.createdAt).format("DD/MM/YYYY")}</span>
            </div>
          </div>
          <div className="invoice-list-box">
            {
              purchases?.map((item) => {
                return (
                  <>
                    <div className="invoice-list-item">
                      <div className="box-left">
                        <h2>{item?.course_id?.course_title}</h2>
                        <p>By {item?.course_id?.creator_id?.full_name}</p>
                      </div>
                      <div className="box-right">
                        <p>{formatCurrency((item?.total_amount || 0), "£")}</p>
                        {/* <p>£{item?.total_amount || 0}</p> */}
                        <span>{moment(item?.createdAt).format("DD/MM/YYYY")}</span>
                      </div>
                    </div>
                    <hr />
                  </>
                )
              })
            }
            {
              purchases?.length == 0 && <p>No data available yet!</p>
            }


          </div>
        </div>
      </Container>
    </CreatorLayout>
  );
}
