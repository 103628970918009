import React from "react";
import { Link } from "react-router-dom";

export default function SettingSidebar() {
  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = "/";
  };
  return (
    <div>
      <sidebar>
        {/* <div className="logo-main">
          <img src={require("../images/logo-mian.png")} />
        </div> */}
        <div className="btm-btns setting-sidebar">
          <div>
            <h3 className="mb-4">Settings</h3>
            <Link
              to="/user-profile-settings"
              className={
                window.location.pathname == "/user-profile-settings"
                  ? "menu-btn bar_active"
                  : "menu-btn "
              }
            >
              Profile
            </Link>
            <Link
              to="/settings-certificates"
              className={
                window.location.pathname == "/settings-certificates"
                  ? "menu-btn bar_active"
                  : "menu-btn "
              }
            >
              My Certificates
            </Link>
            <Link
              to="/settings-invoice"
              className={
                window.location.pathname == "/settings-invoice"
                  ? "menu-btn bar_active"
                  : "menu-btn "
              }
            >
              Invoices
            </Link>
            <Link
              to="/settings-downloadables"
              className={
                window.location.pathname == "/settings-downloadables"
                  ? "menu-btn bar_active"
                  : "menu-btn "
              }
            >
              Downloadable
            </Link>
            <Link
              to="/settings-preference"
              className={
                window.location.pathname == "/settings-preference"
                  ? "menu-btn bar_active"
                  : "menu-btn "
              }
            >
              Contact Preference
            </Link>
            <Link
              to="/settings-security"
              className={
                window.location.pathname == "/settings-security"
                  ? "menu-btn bar_active"
                  : "menu-btn "
              }
            >
              Password & Security
            </Link>
            <Link
              to="/SignIn"
              // onClick={handleLogout}
              className={
                window.location.pathname == "/SignIn"
                  ? "menu-btn bar_active"
                  : "menu-btn "
              }
            >
              Logout
            </Link>
          </div>
          <div></div>
        </div>
      </sidebar>
    </div>
  );
}
