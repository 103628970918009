import React, { useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Form, Link } from "react-router-dom";
// import ProgressBar from "@ramonak/react-progress-bar";
import CreatorLayout from "../elements/CreatorLayout";
import Navbar from "react-bootstrap/Navbar";
export default function QuizSetting({
  errors,
  touched,
  values,
  handleChange,
  setPage,
  handleSubmit,
}) {
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="profile-top-box">
          <div className="box-left">
            <div className="backpage-arrow">
              <Link
                onClick={() => {
                  setPage(0);
                }}
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                  >
                    <path
                      d="M10 20.0003L8.93934 21.061L7.87868 20.0003L8.93934 18.9397L10 20.0003ZM30 18.5003C30.8284 18.5003 31.5 19.1719 31.5 20.0003C31.5 20.8288 30.8284 21.5003 30 21.5003V18.5003ZM15.606 27.7277L8.93934 21.061L11.0607 18.9397L17.7273 25.6063L15.606 27.7277ZM8.93934 18.9397L15.606 12.273L17.7273 14.3943L11.0607 21.061L8.93934 18.9397ZM10 18.5003H30V21.5003L10 21.5003L10 18.5003Z"
                      fill="#40413A"
                    />
                  </svg>
                </span>
                Back to the previous page.
              </Link>
            </div>
          </div>
        </div>
        <div className="edit-course-box">
          <div className="edit-cou-top">
            <div className="box-left">
              <h2>Quiz Setting</h2>
            </div>
            {/* <div className="box-right">
              <button type="button" className="add-cerft">
                Add Certificate
              </button>
            </div> */}
          </div>
          <hr />

          <div className="sign-form-inner quiz-seting-main">
            <div className="">
              <p>Set the perimeters of your Quiz</p>
            </div>
            <div class="form-group mt-5 pt-2">
              <label for="exampleInputEmail1">
                Minimum Percentage Pass Rate
              </label>
              <select
                name="minimumpercentagepassrate"
                value={values.minimumpercentagepassrate}
                onChange={handleChange}
                class="form-select"
                aria-label="Default select example"
              >
                <option value="">Select</option>
                <option value="10">10%</option>
                <option value="20">20%</option>
                <option value="30">30%</option>
                <option value="40">40%</option>
                <option value="50">50%</option>
                <option value="60">60%</option>
                <option value="70">70%</option>
                <option value="80">80%</option>
                <option value="90">90%</option>
                <option value="100">100%</option>
              </select>
              <span className="formik-errors">
                {errors.minimumpercentagepassrate &&
                  touched.minimumpercentagepassrate &&
                  errors.minimumpercentagepassrate}
              </span>
            </div>
            <div class="form-group mt-5 pt-2">
              <label for="exampleInputEmail1">
                Allow students to proceed to the next chapter before completing
                the quiz?
              </label>
              <select
                class="form-select"
                aria-label="Default select example"
                name="canproceednext"
                value={values.canproceednext}
                onChange={handleChange}
              >
                <option value="">Select</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
              <span className="formik-errors">
                {errors.canproceednext &&
                  touched.canproceednext &&
                  errors.canproceednext}
              </span>
            </div>
            <button
              style={{ backgroundColor: "#000", color: "#fff" }}
              // to="/view-quiz-chapter"
              type="submit"
              class="common-btn mt-4"
            >
              SAVE
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
