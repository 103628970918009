import React, { useEffect } from "react";
import Layout from "../elements/Layout";
import { Container, Row, Col, Figure } from "react-bootstrap";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Rating } from "react-simple-star-rating";
import { Link, useNavigate } from "react-router-dom";
import { getAllCreatorViewsAndRating, likeCreator } from "../redux/actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
export default function CreatorsShowAll() {
  const dispatch = useDispatch();
  const {
    allCreatorViewsAndRating
  } = useSelector((state) => ({
    allCreatorViewsAndRating: state.userMgmtSlice.allCreatorViewsAndRating
  }));
  const [likedStatus, setLikedStatus] = useState();
  const [rating, setRating] = useState(0);
  const [likedCreator, setLikedCreator] = useState([]);

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/playback");
  };

  useEffect(() => {
    dispatch(getAllCreatorViewsAndRating());
  }, []);

  const handleLike = (id, status) => {
    dispatch(likeCreator({ creator_id: id, status: status })).then(
      (data) => {
        if (data?.payload?.success) {
          toast.success(data?.payload?.message)
          const arr = likedCreator?.map((item) => {
            if (item?._id == id) return { ...item, isLiked: status }
            else return item
          })
          setLikedCreator(arr);
        }
      }
    );
  };

  useEffect(() => {
    setLikedCreator(allCreatorViewsAndRating);
  }, [allCreatorViewsAndRating])

  console.log(allCreatorViewsAndRating, "allCreatorViewsAndRating");
  return (
    <Layout>
      <Container fluid>
        <div className="creators-main">
          <Row>
            <Col lg={12}>
              <div className="creators-profile">
                <h2 className="creator-hdng">All Creators</h2>
                <p className="creator-subhdng">
                  {likedCreator?.length} Specialists
                </p>
                <div className="mt-4 d-flex justify-content-between creators-main-sec flex-wrap">
                  {
                    likedCreator?.map((item) => {
                      return (
                        <div className="creator-user-profile">
                          {/* <button onClick={() => handleLike(item?._id, !item?.isLiked)} type="button">
                            {item?.isLiked ? (
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M13.3874 16C11.8146 16 10.2381 16 8.66527 16C8.62055 15.9926 8.57582 15.9739 8.5311 15.9739C7.12229 15.9032 5.75448 15.6313 4.41649 15.1881C4.30095 15.1508 4.28232 15.0949 4.28232 14.9869C4.28605 12.2308 4.28605 9.47842 4.28232 6.72229C4.28232 6.61801 4.31959 6.55097 4.39786 6.49138C5.01654 6.01464 5.61286 5.50811 6.13837 4.92336C6.57442 4.43546 6.9434 3.90658 7.16702 3.28086C7.42045 2.56576 7.69998 1.86183 7.97577 1.15418C8.05404 0.956782 8.14349 0.759384 8.26648 0.588058C8.57955 0.156017 9.01188 -0.0600039 9.5523 0.0144859C10.1188 0.0927002 10.525 0.390659 10.6704 0.956782C10.7487 1.26219 10.8008 1.57877 10.812 1.89535C10.8567 2.88607 10.689 3.85444 10.5176 4.82653C10.4691 5.09097 10.4132 5.35168 10.3573 5.63847C10.4319 5.63847 10.4915 5.63847 10.5474 5.63847C11.1139 5.63847 11.6841 5.63847 12.2506 5.63847C12.8171 5.63847 13.3874 5.61984 13.9539 5.65337C15.072 5.71668 15.7876 6.87127 15.3664 7.90668C15.3217 8.01469 15.2621 8.11898 15.2099 8.23071C15.7168 8.56592 16 9.03893 16 9.64229C16 10.2531 15.713 10.7261 15.2062 11.0576C15.7428 11.9031 15.4931 12.9832 14.5726 13.486C14.5912 13.5269 14.6098 13.5679 14.6322 13.6089C14.9788 14.2905 14.7999 15.1247 14.2073 15.6164C13.9688 15.8175 13.6855 15.9218 13.3874 16Z"
                                  fill="#BEBEBE"
                                />
                                <path
                                  d="M0 7.4076C0.11181 7.12454 0.320523 7.02398 0.622409 7.0277C1.46098 7.03888 2.29956 7.03143 3.13813 7.03143C3.19404 7.03143 3.25367 7.03143 3.32448 7.03143C3.32448 9.66464 3.32448 12.2867 3.32448 14.9311C3.26112 14.9311 3.20149 14.9311 3.13813 14.9311C2.29956 14.9311 1.46098 14.9236 0.622409 14.9348C0.316796 14.9385 0.0969019 14.838 0 14.5326C0 12.1563 0 9.7801 0 7.4076Z"
                                  fill="#BEBEBE"
                                />
                              </svg>
                            ) : (
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M13.3874 16C11.8146 16 10.2381 16 8.66527 16C8.62054 15.9926 8.57582 15.9739 8.5311 15.9739C7.12229 15.9032 5.75448 15.6313 4.41649 15.1881C4.30095 15.1508 4.28232 15.0949 4.28232 14.9869C4.28605 12.2308 4.28605 9.47842 4.28232 6.72229C4.28232 6.61801 4.31959 6.55097 4.39786 6.49138C5.01654 6.01464 5.61286 5.50811 6.13837 4.92336C6.57442 4.43546 6.9434 3.90658 7.16702 3.28086C7.42045 2.56576 7.69998 1.86183 7.97577 1.15418C8.05404 0.956782 8.14349 0.759384 8.26648 0.588058C8.57955 0.156017 9.01188 -0.0600039 9.5523 0.0144859C10.1188 0.0927002 10.525 0.390659 10.6704 0.956782C10.7487 1.26219 10.8008 1.57877 10.812 1.89535C10.8567 2.88607 10.689 3.85444 10.5176 4.82653C10.4691 5.09097 10.4132 5.35168 10.3573 5.63847C10.4319 5.63847 10.4915 5.63847 10.5474 5.63847C11.1139 5.63847 11.6841 5.63847 12.2506 5.63847C12.8171 5.63847 13.3874 5.61984 13.9539 5.65336C15.072 5.71668 15.7876 6.87127 15.3664 7.90668C15.3217 8.01469 15.2621 8.11898 15.2099 8.23071C15.7167 8.56592 16 9.03893 16 9.64229C16 10.2531 15.713 10.7261 15.2062 11.0576C15.7428 11.9031 15.4931 12.9832 14.5726 13.486C14.5912 13.5269 14.6098 13.5679 14.6322 13.6089C14.9788 14.2905 14.7999 15.1247 14.2073 15.6164C13.9688 15.8175 13.6855 15.9218 13.3874 16Z"
                                  fill="black"
                                />
                                <path
                                  d="M0 7.4076C0.11181 7.12454 0.320523 7.02398 0.622409 7.0277C1.46098 7.03888 2.29956 7.03143 3.13813 7.03143C3.19404 7.03143 3.25367 7.03143 3.32448 7.03143C3.32448 9.66464 3.32448 12.2867 3.32448 14.9311C3.26112 14.9311 3.20149 14.9311 3.13813 14.9311C2.29956 14.9311 1.46098 14.9236 0.622409 14.9348C0.316796 14.9385 0.0969019 14.838 0 14.5326C0 12.1563 0 9.7801 0 7.4076Z"
                                  fill="black"
                                />
                              </svg>
                            )}
                          </button> */}
                          {/* /single-creator-detail */}
                          <Link to={`/single-creator-detail/${item?._id}`}>
                            <Figure>
                              <Figure.Image
                                alt="171x180"
                                src={item?.profile_image ? item?.profile_image : require("../images/creator1.png")}
                              />
                              <Figure.Caption>{item?.full_name}</Figure.Caption>
                            </Figure>
                          </Link>
                          <div className="creator-top-desc">
                            <p>1st Place</p>
                            <p>{item?.total_views} Views</p>
                          </div>
                          <div className="creator-top-desc">
                            <Rating initialValue={item?.avgRate} />
                            <p>({item?.ratings?.length} Reviews)</p>
                          </div>
                        </div>
                      )
                    })
                  }
                  {
                    likedCreator?.length == 0 && <p>No Content Available Yet!</p>
                  }



                </div>
              </div>
            </Col>

          </Row>
        </div>
      </Container>
    </Layout>
  );
}
