import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { Form, Link } from "react-router-dom";
// import ProgressBar from "@ramonak/react-progress-bar";
import Navbar from "react-bootstrap/Navbar";
import SettingLayout from "../elements/SettingLayout";
import CreatorLayout from "../elements/CreatorLayout";
import { useDispatch, useSelector } from "react-redux";
import { getAllPurchase, getAllTransactions, transferMoney, walletInfo } from "../redux/actions/userAction";
import moment from "moment";
import { getUserById } from "../redux/actions/userAuth";
import useAuthToken from "../Hooks/useAuthToken";
export default function Wallet() {
  const dispatch = useDispatch();
  const tokenData = useAuthToken();
  const { purchases, getRegisterUser, wallet, wallet_transaction } = useSelector((state) => ({
    purchases: state.userMgmtSlice.purchases,
    getRegisterUser: state.userMgmtSlice.getRegisterUser,
    wallet: state.userMgmtSlice.wallet,
    wallet_transaction: state.userMgmtSlice.wallet_transaction,
  }));

  useEffect(() => {
    dispatch(getAllTransactions())
    dispatch(walletInfo());
    dispatch(getAllPurchase());
    dispatch(getUserById(tokenData?._id));
  }, []);
  console.log("wallet----", wallet)
  console.log(wallet_transaction, "purchases");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleWithdrawn = () => {
    dispatch(transferMoney()).then((data) => {
      console.log(data, "data----------------");

      handleClose();
    })
  }

  return (
    <CreatorLayout>
      <Container>
        <div className="profile-detail-box">
          <div className="course-top-box ">
            <div className="box-left">
              <h2>Wallet</h2>
              {/* <p>You’ve earned £1,203 since joining stumble!</p> */}

              <h3>
                £{wallet?.availableBalance && parseFloat(wallet?.availableBalance).toFixed('2') || 0} <span>Available for withdrawal</span>
              </h3>
              <h6>
                £{wallet?.pendingAmount && parseFloat(wallet?.pendingAmount).toFixed('2') || 0} <span>Pending</span>
              </h6>
            </div>

            <div className="box-right">
              <button onClick={handleShow}>Withdraw Funds</button>
            </div>
          </div>
          <div className="invoice-list-box">
            <h3>Transactions</h3>
            {
              wallet_transaction?.map((item) => {
                return (
                  <>
                    {
                      item?.payment_status == 'withdrawn' ? <div className="wallet-list-item">
                        <div className="box-left">
                          <h2>Withdrawal</h2>
                          <p>Withdrawn to bank</p>
                        </div>
                        <div className="box-right">
                          <p className="remove">- £{parseFloat(item?.amount).toFixed('2') || 0}</p>
                          <span>{item?.createdAt && moment(item?.createdAt).format("DD/MM/YYYY")}</span>
                        </div>
                      </div> : <div className="wallet-list-item">
                        <div className="box-left">
                          <h2>Course Purchase</h2>
                          <p>{item?.courseId?.course_title}</p>
                        </div>
                        <div className="box-right">
                          <p className="added">+ £{parseFloat(item?.amount).toFixed('2') || 0}</p>
                          <span>{item?.createdAt && moment(item?.createdAt).format("DD/MM/YYYY")}</span>
                        </div>
                      </div>
                    }

                  </>
                )
              })
            }
            {
              wallet_transaction?.length == 0 && <p>No data available yet!</p>
            }


          </div>
        </div>
      </Container>
      <Modal show={show} onHide={handleClose} className="withdrwal-popup">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to withdrawal?</p>
          {/* <h2>£{parseFloat(wallet?.availableBalance).toFixed('2') || 0}</h2> */}
          <h2>£{parseFloat(wallet?.pendingAmount).toFixed('2') || 0}</h2>
        </Modal.Body>
        <Modal.Footer className="flex-row justify-content-center">
          <Button variant="secondary" onClick={handleWithdrawn}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleClose}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </CreatorLayout>
  );
}
