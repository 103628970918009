import React from 'react';
import { Link } from 'react-router-dom';

const Thankyou = () => {
    return (
        <>
            <h2 className="h-22">Thank You!</h2>

            <span>Thank you for applying to Buff Education.</span>

            <span>
                We will review your application and get back to you as soon as we
                can.
            </span>
            <form>
                <Link to="/SignIn" type="submit" class="common-btn">
                    CONTINUE
                </Link>
            </form>
        </>
    )
}

export default Thankyou;