import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Form, Link, useNavigate } from "react-router-dom";
// import ProgressBar from "@ramonak/react-progress-bar";
import { Rating } from "react-simple-star-rating";
import Navbar from "react-bootstrap/Navbar";
import CreatorLayout from "../elements/CreatorLayout";
import { getMyCourses } from "../redux/actions/userAction";
import useAuthToken from "../Hooks/useAuthToken";
import { useDispatch, useSelector } from "react-redux";
import parseDuration from "../utils/parseDuration ";
import { getUserById } from "../redux/actions/userAuth";
export default function SettingsMyCourses() {
  const dispatch = useDispatch();
  const tokenData = useAuthToken();
  const [file, setFile] = useState();
  const { myCourses, getRegisterUser } = useSelector((state) => ({
    myCourses: state.userMgmtSlice.myCourses,
    getRegisterUser: state.userMgmtSlice.getRegisterUser,
  }));
  const navigate = useNavigate();

  const navigateToContacts = () => {
    // 👇️ navigate to /contacts
    navigate("/settings-create-courses");
  };

  useEffect(() => {
    dispatch(getMyCourses(tokenData?._id));
  }, []);

  useEffect(() => {
    dispatch(getUserById(tokenData?._id));
  }, []);
  console.log(getRegisterUser, "tokenData");
  return (
    <CreatorLayout>
      <Container fluid>
        <div className="course-top-box settings mt-4">
          <div className="box-left">
            <h2>My Courses</h2>
            <p>Create and Manage Your Courses</p>
          </div>
          <div className="box-right">
            <div className="add-course-st">
              <button
                type="buttom"
                className="add-icon"
                onClick={navigateToContacts}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                >
                  <path
                    d="M12.5 6.25L12.5 18.75"
                    stroke="#8B8B8B"
                    stroke-width="2"
                    stroke-linecap="square"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18.75 12.5L6.25 12.5"
                    stroke="#8B8B8B"
                    stroke-width="2"
                    stroke-linecap="square"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className="videos-sec-main mt-5">
          <div className="videos-view">
            {myCourses?.map((item) => {
              const totalRatingSum = item?.rates?.reduce(
                (sum, rating) => sum + rating.rating,
                0
              );
              const averageRating = totalRatingSum / item?.rates?.length;

              return (
                <>
                  <div className="video-inner add-only-four">
                    <Link to={`/settings-edit-course/${item?._id}`}>
                      <img
                        className="thumb-img"
                        src={
                          item?.cover_image
                            ?
                            item?.cover_image
                            : require("../images/imagesplaceholder.png")
                        }
                      />
                    </Link>
                    <div className="video-dec">
                      <div className="user-logo">
                        <img
                          src={
                            getRegisterUser?.profile_image
                              ?
                              getRegisterUser?.profile_image
                              : require("../images/userico.png")
                          }
                        />
                      </div>
                      <div className="user-desc">
                        <h3>{item?.course_title}</h3>
                        <div className="bottom-desc">
                          <div className="bottom-first">
                            <p>{item?.creator_name}</p>
                            <p>
                              {item?.cost == 0 || item?.cost == undefined
                                ? "Free"
                                : `£ ${item?.cost}`}
                            </p>
                            <p>{item?.chapterViews} views</p>
                          </div>
                          <div className="bottom-first">
                            <p>
                              {item?.chapters == 1 ? `${item?.chaptersarr?.length} Video,` : `${item?.chaptersarr?.length} Videos,`}{" "}
                              {parseDuration(item?.duration)}
                            </p>
                            <Rating initialValue={averageRating || 0} />
                            <p>({item?.rates?.length} Reviews)</p>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
            {
              myCourses?.length == 0 && <p>No data available yet!</p>
            }
          </div>
        </div>
      </Container>
    </CreatorLayout>
  );
}
