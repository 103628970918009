import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ProgressBar from '@ramonak/react-progress-bar';
import { removeUpload } from '../redux/reducers/userMgmtSlice';
import LoaderIconShow from "../comm/loaderIconShow";

const MultipleLoader = ({ uploads }) => {
    const dispatch = useDispatch();
    const [uploadStates, setUploadStates] = useState([]);
    const [iconShow, setIconShow] = useState({});

    useEffect(() => {
        setUploadStates(uploads);
    }, [uploads]);

    useEffect(() => {
        const initialIconShow = uploads.reduce((acc, _, index) => {
            acc[index] = true;
            return acc;
        }, {});
        setIconShow(initialIconShow);
    }, [])

    useEffect(() => {
        const completedUploads = uploadStates.filter(upload => upload.percent === 100 && upload.status === 'uploading');
        if (completedUploads.length > 0) {
            const timer = setTimeout(() => {
                setUploadStates(prevStates =>
                    prevStates.map(upload =>
                        upload.percent === 100 ? { ...upload, status: 'completed' } : upload
                    )
                );
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [uploadStates]);

    useEffect(() => {
        const timers = uploads.map((upload, index) => {
            if (upload.percent === 100) {
                return setTimeout(() => {
                    dispatch(removeUpload(upload.video_title));
                }, 2000);
            }
            return null;
        });

        return () => {
            timers.forEach(timer => {
                if (timer) {
                    clearTimeout(timer);
                }
            });
        };
    }, [uploads, dispatch]);

    const toggleIconShow = (index) => {
        setIconShow(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    return (
        <div className='side-popup-main'>
            {uploadStates?.map((upload, index) => (
                <div
                    className={iconShow[index] ? "chapter-upload hide" : "chapter-upload"}
                    key={index}
                >
                    <h6>{upload.video_title}</h6>
                    <LoaderIconShow
                        iconShow={iconShow[index]}
                        toggleIconShow={() => toggleIconShow(index)}
                    />
                    <div className="chapter-upload-header">
                        <h6>
                            {upload.status === 'completed'
                                ? "Video Uploaded Successfully."
                                : "Uploading Video"}
                        </h6>
                    </div>
                    <ProgressBar
                        completed={upload.percent}
                        bgColor={upload.status === 'completed' ? "#2b742b" : "black"}
                    />
                    <p>
                        Estimate Time Remaining: <b>{upload.uploadTime}</b>
                    </p>
                    <h3>{upload.percent?.originalname}</h3>
                </div>
            ))}
        </div>
    );
};

export default MultipleLoader;
