import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import CreatorLayout from "../elements/CreatorLayout";
import { getProductList, getStatics } from "../redux/actions/userAuth";
import { useDispatch, useSelector } from "react-redux";
import MultiProductSelect from "../components/CountrySelect/MultiProductSelect";
import ProgressBar from "@ramonak/react-progress-bar";
import {
  createCourse,
  editCourse,
  getCourseById,
} from "../redux/actions/userAction";
import { Formik, Form, Field } from 'formik';
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
export default function SettingsCreateCourse() {
  const dispatch = useDispatch();
  let query = useQuery();
  let queryId = query.get("id");
  const navigate = useNavigate();
  const [prevImg, setPrevImg] = useState();
  const [courseDetail, setCourseDetail] = useState();
  const [progress, setProgress] = useState(0);
  const [imgName, setImgName] = useState("");
  const [isDisabled, setIsDisable] = useState(false)

  const { statics, products } = useSelector((state) => ({
    statics: state.userMgmtSlice.statics,
    products: state.userMgmtSlice.products,
  }));

  useEffect(() => {
    dispatch(getStatics());
    dispatch(getProductList());
  }, []);

  useEffect(() => {
    if (queryId) {
      dispatch(getCourseById(queryId)).then((data) => {
        setCourseDetail(data?.payload?.data);
      });
    }
  }, [queryId]);

  const handleTagChange = (e, values, setFieldValue) => {
    const { checked, value } = e.target;
    let newTags = [...values.tags];

    if (checked) {
      if (newTags.length >= 3) {
        toast.error("Only select up to 3");
        return;
      }
      newTags.push(value);
    } else {
      newTags = newTags.filter(tag => tag !== value);
    }

    setFieldValue('tags', newTags);
    console.log("values", values)
  };



  console.log(courseDetail, "products");
  return (
    <CreatorLayout>
      <Container>
        <div className="profile-detail-box">
          <div className="course-top-box">
            <div className="box-left">
              <h2>{courseDetail ? "Edit" : "ADD"} a New Course</h2>
              <p>{courseDetail ? "Edit" : "Create"} a New Course here. </p>
              <p>You can add as many chapters to your course as you’d like.</p>
            </div>
            {/* <div className="box-right">
              <p>Megan Bulmer</p>
              <span>Member Since: 12/06/2023</span>
            </div> */}
          </div>
          <div className="sign-form-inner">
            <Formik
              enableReinitialize={true}
              initialValues={{
                course_title: courseDetail?.course_title || "",
                tags: courseDetail?.tags || [],
                products: courseDetail?.products || [],
                coursecategory: courseDetail?.coursecategory || "",
                cover_image: courseDetail?.cover_image || "",
                course_desc: courseDetail?.course_desc || "",
              }}
              validate={(values) => {
                const errors = {};

                if (!values.course_title) {
                  errors.course_title = "Required*";
                }
                if (!values.coursecategory) {
                  errors.coursecategory = "Required*";
                }
                if (!values.course_desc) {
                  errors.course_desc = "Required*";
                }
                if (!values.tags || values.tags.length === 0) {
                  errors.tags = "At least one tag must be selected";
                }

                return errors;
              }}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                console.log(values, "course------------");
                //  values.user_id = userId;
                setIsDisable(true)
                const formData = new FormData();
                for (let key in values) {
                  if (key == "tags" || key == "products") {
                    for (const element of values[key]) {
                      formData.append(key, element);
                    }
                  } else {
                    formData.append(key, values[key]);
                  }
                }
                if (courseDetail) {
                  formData.append(`id`, courseDetail?._id);
                  dispatch(editCourse(formData)).then((data) => {
                    if (data?.payload?.success) {
                      resetForm();
                      toast.success(data?.payload?.message);
                      navigate("/settings-courses");
                    }
                    setSubmitting(false);
                  });
                } else {
                  console.log("values----", values)
                  dispatch(createCourse(formData)).then((data) => {
                    if (data?.payload?.success) {
                      resetForm();
                      toast.success(data?.payload?.message);
                      navigate("/settings-courses");
                    }
                    setSubmitting(false);
                  });
                }
                setIsDisable(false)
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <form
                  onSubmit={(e) => {
                    // setValidateValue(true);
                    handleSubmit(e);
                  }}
                >


                  {/* <div class="form-group mb-4">
                    <div className="img-upload-additional">
                      <div className="additional-img-upload">
                        <input
                          type="file"
                          name="cover_image"
                          onChange={(e) => {
                            setPrevImg(URL.createObjectURL(e.target.files[0]));
                            setFieldValue("cover_image", e.target.files[0]);
                          }}
                        ></input>
                        {prevImg ? (
                          <img className="show-img" src={prevImg} />
                        ) : (
                          values.cover_image && (
                            <img
                              className="show-img"
                              src={

                                values.cover_image
                              }
                            />
                          )
                        )}
                      </div>
                      <p>
                        <b>Add a Cover Image</b>
                      </p>
                      <p className="dimension-label">
                        <b>400 * 200 (upto 1024kb)</b>
                      </p>
                    </div>
                  </div> */}


                  {/* Upload Image Edit and Add */}

                  <div className="video-upload-additional mt-4">
                    <div class="form-group mb-5 video-new-upload">
                      <h2 className="mt-3">Upload Cover Image</h2>
                      <div className="d-flex justify-content-center">
                        <button type="button"><input
                          type="file"
                          name="cover_image"
                          onChange={(e) => {
                            if (e.target.files.length != 0) {
                              setPrevImg(
                                URL.createObjectURL(e.target.files[0])
                              );

                              setFieldValue(
                                "cover_image",
                                e.target.files[0]
                              );
                              setImgName(e.target.files[0]?.name);

                              const steps = [0, 100];
                              steps.forEach((value, index) => {
                                setTimeout(() => {
                                  setProgress(value);
                                }, index * 1000);
                              });
                            }
                          }} />Upload Image</button></div>
                      {
                        imgName ? <div className="uploaded-video-view">
                          <h3 className="mb-2">{imgName}</h3>



                        </div>
                          : typeof values.cover_image == 'string' && values.cover_image != '' ?
                            <h6>{values?.cover_image?.split('/').pop()}</h6>
                            : <p className="text-center mt-4">No File Found</p>
                      }
                      {prevImg ? (
                        <>
                          <button className="cancel-btn" type="button" onClick={() => {
                            setPrevImg()
                            setImgName();
                            setProgress(0);
                            setFieldValue('cover_image', '')
                          }}><img src={require("../images/close.png")} /></button>
                          <img className="show-img" src={prevImg} />
                          <ProgressBar className="progress-line" completed={progress} bgColor={progress == 100 ? "#65c365" : "black"} height="5px" />
                        </>
                      ) : (
                        values.cover_image && (
                          <>
                            <img
                              className="show-img"
                              src={values.cover_image}
                            />

                            <button className="cancel-btn" type="button" onClick={() => {
                              setPrevImg()
                              setImgName();
                              setProgress(0);
                              setFieldValue('cover_image', '')
                            }}><img src={require("../images/close.png")} /></button>
                          </>
                        )
                      )}

                    </div>
                  </div>

                  {/* Title here */}
                  <div class="form-group">
                    <label for="exampleInputEmail1">Course Title</label>
                    <input
                      type="text"
                      className="form-control"
                      aria-describedby="course_title"
                      placeholder="Type here..."
                      name="course_title"
                      onChange={handleChange}
                      value={values.course_title}
                    />
                    <span className="formik-errors">
                      {errors.course_title &&
                        touched.course_title &&
                        errors.course_title}
                    </span>
                  </div>
                  <div class="form-group mt-4 pt-2">
                    <label>Select Category</label>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      name="coursecategory"
                      onChange={handleChange}
                      value={values.coursecategory}
                    >
                      <option value="">Select</option>
                      {statics?.coursecategories?.map((item, i) => {
                        return <option value={item?._id}>{item?.title}</option>;
                      })}
                    </select>
                    <span className="formik-errors">
                      {errors.coursecategory &&
                        touched.coursecategory &&
                        errors.coursecategory}
                    </span>
                  </div>
                  <div class="form-group mt-4 pt-2">
                    <label>Course Description</label>
                    <textarea
                      rows={3}
                      type="text"
                      class="form-control"
                      placeholder="Type here..."
                      name="course_desc"
                      onChange={handleChange}
                      value={values.course_desc}
                    />
                    <span className="formik-errors">
                      {errors.course_desc &&
                        touched.course_desc &&
                        errors.course_desc}
                    </span>
                  </div>
                  <div class="form-group mt-4 pt-2">
                    <label>List any products used in this tutorial</label>
                    <p>
                      Search and add products you’ll use in this
                      <br />
                      course
                    </p>
                    {/* <input
                  type="search"
                  class="form-control"
                  placeholder="Keyword Search"  
                /> */}
                    <MultiProductSelect setFieldValue={setFieldValue} />
                  </div>
                  <div className="select-product-box">
                    {/* <h3>Selected Products</h3> */}
                    {/* <div className="selected-produc">
                 
                      {
                      
                        (
                          products?.filter((product) =>
                            values?.products?.includes(product._id)
                          ) || []
                        ).map((item, index) => (
                          <React.Fragment key={item._id}>
                            {index > 0 && ", "}
                            {item?.name}
                          </React.Fragment>
                        ))
                      }
                    </div> */}

                    <div className="pick-product">
                      <h3>Tag#</h3>
                      <p>Select up to 3</p>
                    </div>
                    <div className="select-hashtag-box">
                      <div className="select-inters-box">
                        <ul>
                          {statics?.courseTagslists?.map((item, index) => {
                            return (
                              <>
                                <div class="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="tags"
                                    id={item._id}
                                    value={item._id}
                                    checked={values?.tags?.includes(item._id)}
                                    onChange={(e) => handleTagChange(e, values, setFieldValue)}
                                    // onChange={(e) => {
                                    //   handleChange(e);
                                    //   console.log(e.target.value, "checkboxed")
                                    //   console.log("values", values?.tags)
                                    //   if (values.tags.length > 3) {
                                    //     return toast.error(
                                    //       "Only select up to 3"
                                    //     );
                                    //   }
                                    // }}
                                  />
                                  <label
                                    className="form-check-label"
                                    for="flexCheckDefault"
                                  >
                                    {item?.title}
                                  </label>
                                </div>
                              </>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <button type="submit" class="common-btn" disabled={isDisabled}>
                    {courseDetail ? "UPDATE" : "CREATE"} COURSE
                  </button>
                  {isDisabled && (
                    <div className="loader-main">
                      <div class="loader"></div>
                    </div>
                  )}
                </form>
              )}
            </Formik>
          </div>
        </div>
      </Container>
    </CreatorLayout>
  );
}
