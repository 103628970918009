import React, { useEffect } from "react";
import Layout from "../elements/Layout";
import { Container, Row, Col } from "react-bootstrap";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Rating } from "react-simple-star-rating";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import parseDuration from "../utils/parseDuration ";
import { getAllVideosLikes } from "../redux/actions/userAction";

export default function LikesVideos() {
    const dispatch = useDispatch();
    const [rating, setRating] = useState(0);
    const navigate = useNavigate();
    const { alVideosLikes } = useSelector((state) => ({
        alVideosLikes: state.userMgmtSlice.alVideosLikes,
    }));

    const handleClick = (courseId, chapterId) => {
        navigate(`/playback/${courseId}/${chapterId}?type=likedvideo`);
    };
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    console.log("alVideosLikes", alVideosLikes)

    useEffect(() => {
        dispatch(getAllVideosLikes());
    }, []);
    return (
        <Layout>
            <Container fluid>
                <div className="details-videos">
                    <h2>Likes</h2>
                    <div className="details-tab">
                        <ul className="overview-set">
                            <li>
                                <Link to="/likes-courses">Courses</Link>
                            </li>
                            <li>
                                <Link to="/likes-creators">
                                    Creators
                                </Link>
                            </li>
                            <li>
                                <Link to="/likes-videos" className="active-tab-detail">
                                    Videos
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="videos-sec-main mt-4">
                        <div className="videos-view likes-courses">
                            {
                                Array.isArray(alVideosLikes?.likedVideos) && alVideosLikes?.likedVideos
                                    ?.length > 0 ?
                                    alVideosLikes?.likedVideos?.map((item, i) => {
                                        const totalRatingSum = item?.rates?.reduce(
                                            (sum, rating) => sum + rating.rating,
                                            0
                                        );
                                        const averageRating = totalRatingSum / item?.rates?.length;

                                        return (
                                            <>
                                                <div className="video-inner">
                                                    <img
                                                        onClick={() => handleClick(item?.chapter?.course_id, item?.chapter_id)}
                                                        className="thumb-img"
                                                        src={
                                                            item?.chapter?.cover_image
                                                                ?
                                                                item?.chapter?.cover_image
                                                                : require("../images/imagesplaceholder.png")
                                                        }
                                                    />
                                                    <div className="video-dec">
                                                        <div className="user-logo">
                                                            <img
                                                                src={
                                                                    item?.chapter?.course?.creator_id?.profile_image
                                                                        ?
                                                                        item?.chapter?.course?.creator_id?.profile_image
                                                                        : require("../images/userico.png")
                                                                }
                                                            />
                                                        </div>
                                                        <div className="user-desc">
                                                            <h3>{item?.chapter?.chapter_title}</h3>
                                                            <div className="bottom-desc">
                                                                <div className="bottom-first">
                                                                    <p>{item?.chapter?.course?.creator_id?.full_name}</p>
                                                                    <p>{item?.chapter?.course?.viewcount} views</p>
                                                                    <p>
                                                                        {item?.chapter?.course?.cost == 0 ? "Free" : `£ ${item?.chapter?.course?.cost}`}
                                                                    </p>
                                                                </div>
                                                                <div className="bottom-first">
                                                                    <p>
                                                                        {item?.chapter?.course?.total_video == 1
                                                                            ? item?.chapter?.course?.total_video + " Video,"
                                                                            : item?.chapter?.course?.total_video + " Videos,"}{" "}
                                                                        {parseDuration(item?.chapter?.duration)}
                                                                    </p>
                                                                    {/* <Rating initialValue={averageRating || 0} />
                                                                    <p>({item?.rates?.length} Reviews)</p> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    })
                                    : <p>No Content Available Yet!</p>
                            }
                        </div>
                    </div>
                </div>
            </Container>
            <Modal show={show} onHide={handleClose} className="social-detail-popup">
                <Modal.Header closeButton>
                    <Modal.Title>About</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="creator-bio-desc">
                        <p>
                            This is a sample bio. Lorem ipsum dolor sit amet, consectetur
                            adipiscing elit. Pellentesque id commodo nunc. Fusce enim ligula,
                            consectetur et velit nec, rutrum hendrerit risus. Proin non
                            tristique ipsum. Quisque nec ante dignissim, lobortis tortor at,
                            bibendum tellus. Praesent ultrices odio urna, et volutpat ex
                            placerat id. Aliquam ante eros, scelerisque et leo id, convallis
                            rhoncus odio. Donec elit nibh, eleifend sit amet dignissim sed,
                            semper vitae leo. Lorem Ipsum is simply dummy text of the printing
                            and typesetting industry. Lorem Ipsum has been the industry's
                            standard dummy text ever since the 1500s, when an unknown printer
                            took a galley of type and scrambled it to make a type specimen
                            book. It has survived not only five centuries, but also the leap
                            into electronic typesetting, remaining essentially unchanged. It
                            was popularised in the 1960s with the release of Letraset sheets
                            containing Lorem Ipsum passages, and more recently with desktop
                            publishing software like Aldus PageMaker including versions of
                            Lorem Ipsum.
                        </p>

                        <h3>Expertise</h3>
                        <ul>
                            <li>Brow Lamination</li>
                            <li>HD Brows</li>
                            <li>Combination Brows</li>
                        </ul>

                        <div className="social-links mt-5">
                            <h4>Links</h4>
                            <div className="social-inner">
                                <img src={require("../images/instas.svg").default} />

                                <div className="social-desc">
                                    <h4>Instagram</h4>
                                    <Link to="#">Instagram.com/alleybostan</Link>
                                </div>
                            </div>
                            <div className="social-inner">
                                <img src={require("../images/faceb.svg").default} />

                                <div className="social-desc">
                                    <h4>Facebook</h4>
                                    <Link to="#">Facebook.com/alleybostan</Link>
                                </div>
                            </div>
                            <div className="social-inner">
                                <img src={require("../images/social.svg").default} />

                                <div className="social-desc">
                                    <h4>Instagram</h4>
                                    <Link to="#">Instagram.com/alleybostan</Link>
                                </div>
                            </div>
                        </div>

                        <div className="status">
                            <h3>Stats</h3>
                            <ul>
                                <li>Joined: June 2023</li>
                                <li>Videos: 23</li>
                                <li>Views: 23,312 </li>
                            </ul>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </Layout>
    );
}
