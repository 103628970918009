import React, { useEffect, useRef } from "react";
import Layout from "../elements/Layout";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { searchResults } from "../redux/actions/userAction";
import moment from "moment";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}


export default function SearchResults() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let query = useQuery();
  let search = query.get("search");

  const { searchArr } = useSelector((state) => ({
    searchArr: state.userMgmtSlice.searchArr
  }));

  const videoContainerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (videoContainerRef.current) {
        const width = videoContainerRef.current.offsetWidth;
        videoContainerRef.current.style.height = `${(width * 9) / 16}px`;
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(searchResults({ search: search }))
  }, [search]);

  console.log(searchArr, "searchAll");

  const handleNavigate = (item) => {
    if (item?.type == 'channel') {
      navigate(`/channels-details/${item?.id}`)
    }
    if (item?.type == 'chapter') {
      navigate(`/playback/${item?.couserId}/${item?.id}`)
    }
    if (item?.type == 'course') {
      navigate(`/course-overview/${item?.id}`)
    }
  }

  return (
    <Layout>
      <Container fluid>
        <div className="search-results-sec">
          {
            searchArr?.map((item) => {
              var duration = moment.duration(
                moment().diff(item?.createDate)
              );
              var days = duration.asDays();
              var hours = duration.asHours();
              var minutes = duration.asMinutes();
              return (
                <>
                  <Row >
                    <Col lg={9} className="mx-auto">
                      <Row onClick={() => handleNavigate(item)} className="mt-4" style={{ cursor: "pointer" }}>
                        <Col lg={6}>
                          <div className="video-container" ref={videoContainerRef}>
                            <img
                              className="img-fluid"
                              src={item?.coverImage ? item?.coverImage : require("../images/Videoimg2.png")}

                            />
                          </div>
                        </Col>

                        <Col lg={6}>
                          <div className="search-result-right-sec">
                            <h2>
                              {item?.title}
                            </h2>
                            <h6>{item?.totalPageViews || 0} views &#8226; 
                            <span className="time">
                                {" "}
                                {hours > 24
                                  ? `${Math.floor(days)} days ago`
                                  : minutes > 60
                                  ? `${Math.floor(hours)} hours ago`
                                  : Math.floor(minutes) == 0
                                  ? "Just now"
                                  : `${Math.floor(minutes)} minutes ago`}
                              </span>
                            </h6>
                            {/* <div className="channel-profile">
                  <img src={require("../images/Videoimg1.png")} />
                  <h4>user Action</h4>
                </div> */}
                            <p>
                              {
                                item?.bio
                              }
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </>
              )
            })
          }
          {
            searchArr?.length == 0 && <p>No results found!</p>
          }
        </div>
      </Container>
    </Layout>
  );
}
