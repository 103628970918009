import React from "react";
import Layout from "../elements/Layout";
import { Container, Row, Col } from "react-bootstrap";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Rating } from "react-simple-star-rating";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
export default function CourseOverviewProducts() {
  const [rating, setRating] = useState(0);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/course-overview-paid");
  };
  return (
    <Layout>
      <Container fluid>
        <div className="course-purchase">
          <Row>
            <Col lg={3}>
              <div className="course-left">
                <img src={require("../images/imagesplaceholder.png")} />
              </div>
            </Col>
            <Col lg={6}>
              <div className="course-center">
                <h2>Eyebrow Tinting Basics with Ash’ed & Dan Watts</h2>
                <div className="bottom-desc">
                  <div className="bottom-first">
                    <p>Ash’ed</p>
                    <p>5,230 views</p>
                    <p>£ Free</p>
                  </div>
                  <div className="bottom-first">
                    <p>3 Videos, 23:32</p>
                  </div>
                  <div className="bottom-first">
                    <p>(3 Reviews)</p>
                    <Rating />
                  </div>
                  <div className="bottom-first">
                    <p>
                      So you want to try your hand at Eyebrow Tinting? You’ve
                      come to the right place. In this course, I’ll guide you
                      through the.. <span>Read More</span>
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={3}>
              <div className="course-right">
                <div className="top-buttons">
                  <button type="button">
                    <img src={require("../images/like.svg").default} />
                  </button>
                  <button type="button">
                    <img src={require("../images/share.svg").default} />
                  </button>
                </div>
                <button type="button" onClick={handleClick}>
                  Purchase Course
                </button>
              </div>
            </Col>
          </Row>
        </div>
        <div className="details-videos">
          <div className="details-tab">
            <ul className="overview-set">
              <li>
                <Link to="/course-overview${id}">Chapters</Link>
              </li>
              <li>
                <Link to="#">More Like This</Link>
              </li>
              <li>
                <Link
                  to="/course-overview-products"
                  className="active-tab-detail"
                >
                  Products Used
                </Link>
              </li>
            </ul>
          </div>
          <div className="videos-sec-main mt-4">
            <div className="videos-view">
              <div className="video-inner">
                <img
                  className="thumb-img"
                  src={require("../images/imagesplaceholder.png")}
                />
                <div className="video-dec">
                  <div className="user-desc">
                    <h3>Laminated Kit</h3>
                    <div className="bottom-desc">
                      <div className="bottom-first">
                        <p>£14.00</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="video-inner">
                <img
                  className="thumb-img"
                  src={require("../images/imagesplaceholder.png")}
                />
                <div className="video-dec">
                  <div className="user-desc">
                    <h3>Laminated Kit</h3>
                    <div className="bottom-desc">
                      <div className="bottom-first">
                        <p>£14.00</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="video-inner">
                <img
                  className="thumb-img"
                  src={require("../images/imagesplaceholder.png")}
                />
                <div className="video-dec">
                  <div className="user-desc">
                    <h3>Laminated Kit</h3>
                    <div className="bottom-desc">
                      <div className="bottom-first">
                        <p>£14.00</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="video-inner">
                <img
                  className="thumb-img"
                  src={require("../images/imagesplaceholder.png")}
                />
                <div className="video-dec">
                  <div className="user-desc">
                    <h3>Laminated Kit</h3>
                    <div className="bottom-desc">
                      <div className="bottom-first">
                        <p>£14.00</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
