import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import ProgressBar from "@ramonak/react-progress-bar";
import Navbar from "react-bootstrap/Navbar";
import PersonalDetail from "../components/Forms/PersonalDetail";
import NewAccount from "../components/Forms/NewAccount";
import Otp from "../components/Forms/Otp";
import YourInterests from "../components/Forms/YourInterests";
import Thankyou from "../components/Forms/Thankyou";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserById } from "../redux/actions/userAuth";
import UpdateEmail from "../components/Forms/UpdateEmail";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function SignUp() {
  const dispatch = useDispatch();
  let query = useQuery();
  let userId = query.get("id");
  const [file, setFile] = useState();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});
  const { getRegisterUser } = useSelector((state) => ({
    getRegisterUser: state.userMgmtSlice.getRegisterUser
  }));

  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  const handleNext = (data, step_status) => {
    setFormData({ ...formData, ...data });
    setStep(step_status ? step_status : step + 1);
  };

  useEffect(() => {
    if (getRegisterUser?.email_verified) {
      setStep(4);
    }
    if (getRegisterUser?.adminapproved == 0 && getRegisterUser?.tages?.length) {
      setStep(5);
    }
    if (getRegisterUser && !getRegisterUser?.email_verified) {
      setStep(3);
    }
  }, [userId, getRegisterUser]);

  useEffect(() => {
    if (userId) {
      dispatch(getUserById(userId))
    }
  }, [userId]);



  return (
    <div>
      <Navbar expand="lg" className="bg-body-tertiary landing-nav">
        <Container fluid>
          <Navbar.Brand href="#home" className="logo-main-nav">
            <img src={require("../images/logo-mian.png")} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          {/* <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="#home">Home</Nav.Link>
              <Nav.Link href="#link">Contact Us</Nav.Link>
            </Nav>
            <div className="main-login-btn">
              <Link to="/SignIn">Login</Link>
            </div>
          </Navbar.Collapse> */}
        </Container>
      </Navbar>
      <Container>
        <div className="sign-forms-cmn-box">
          <div className="sign-form-inner">
            <div className="progress-bar">
              <ProgressBar completed={20 * step} labelClassName="label" />
            </div>

            {step == 1 && <PersonalDetail onNext={handleNext} file={file} handleChange={handleChange} />}
            {step == 2 && <NewAccount data={formData} onNext={handleNext} />}
            {step == 3 && <Otp onNext={handleNext} getRegisterUser={getRegisterUser} />}
            {step == 4 && <YourInterests onNext={handleNext} />}
            {step == 5 && <Thankyou />}
            {step == 6 && <UpdateEmail onNext={handleNext} />}
          </div>
        </div>
      </Container>
    </div>
  );
}
