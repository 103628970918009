import { memo, useEffect, useRef, useState } from "react";
const styles = {
  border: "1px dashed gray",
  padding: "0.5rem 1rem",
  cursor: "move",
};
export const Box = memo(function Box({ title, yellow, preview }) {
  const [isOutsideClicked, setIsOutsideClicked] = useState(false);
  const inputRef = useRef(null);
  const backgroundColor = yellow ? "yellow" : "white";

  useEffect(() => {
    function handleClickOutside(event) {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        if (inputRef.current.value == "") {
          return setIsOutsideClicked(false);
        }
        {
          setIsOutsideClicked(true);
        }
      } else {
        setIsOutsideClicked(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div role={preview ? "BoxPreview" : "Box"}>
      {/* {title} */}
      <input
        type="text"
        className={`drag-input ${isOutsideClicked ? "outside_tie_input" : ""}`}
        ref={inputRef}
      ></input>
    </div>
  );
});
