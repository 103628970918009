import { useState, useCallback, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { toast } from 'react-toastify';

const firebaseConfig = {
    apiKey: "AIzaSyBzSAjC4eQN1teyzQKmxye5WomTQRrVpiw",
    authDomain: "buff-educatioin.firebaseapp.com",
    projectId: "buff-educatioin",
    storageBucket: "buff-educatioin.appspot.com",
    messagingSenderId: "755487534661",
    appId: "1:755487534661:web:ccb557438779c929a77d2c",
    measurementId: "G-D63C5J809M"
  };

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

const useFirebaseToken = () => {
    const [token, setToken] = useState(null);

    const getFireBaseToken = useCallback(async () => {
        try {
            const response = await Notification.requestPermission();
            if (response === "granted") {
                console.log('Notification permission granted.');
                try {
                    const fetchedToken = await getToken(messaging, {
                        vapidKey: 'BMsmBNxZHUa_CZvYWkZJ4XncT8RxQnnK9BkCp1v3grwSnS-V4sQh6eMyaYLSbjQnEmek1ncPrxAtRWFwZMWiJ98'
                    });
                    if (fetchedToken) {
                        setToken(fetchedToken);
                        console.log('FCM Token:', fetchedToken);
                        return fetchedToken;
                    } else {
                        console.log('No registration token available. Request permission to generate one.');
                    }
                } catch (error) {
                    console.error('An error occurred while retrieving token. ', error);
                }
            } else {
                console.log('Unable to get permission to notify.');
            }
        } catch (error) {
            console.error("Error requesting notification permission.", error);
        }
    }, []);

    useEffect(() => {
        const unsubscribe = onMessage(messaging, (payload) => {
            console.log('Message received. ', payload);
            toast.info(`${payload.notification.title}: ${payload.notification.body}`);
        });
        return () => {
            unsubscribe();
        };
    }, []);

    return { token, getFireBaseToken };
};

export default useFirebaseToken;
