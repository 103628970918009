import React, { useState } from "react";
import CreatorSidebar from "./CreatorSidebar";
import { Container } from "react-bootstrap";
import TopBar from "./TopBar";
import Sidebar from "./Sidebar";

const CreatorLayout = ({ children }) => {
  const [isActive, setActive] = useState(false);
  const [isActiveNew, setActiveNew] = useState(false);
  return (
    <>
      <section className="cmn-side">
        <Container fluid className="px-0">
          <div className={isActive ? "new main" : "main"}>
            <div>
              <div
                className={
                  isActiveNew
                    ? "setting-main-sidebar phone"
                    : "setting-main-sidebar"
                }
              >
                <CreatorSidebar />
              </div>
              <div className="setting-inner-sidebar">
                <Sidebar />
              </div>
            </div>
            <div className="right-side creator">
              <TopBar
                setActive={setActive}
                isActive={isActive}
                setActiveNew={setActiveNew}
                isActiveNew={isActiveNew}
              />
              {children}
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default CreatorLayout;
