import { Formik } from "formik";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import CountrySelect from "../CountrySelect/CountrySelect";
import useGetAddressIO from "../../Hooks/getAddressIo";
import { Col, InputGroup } from "react-bootstrap";

const PersonalDetail = ({ onNext, handleChange, file }) => {
  const { addressData, getAddress } = useGetAddressIO();
  const [prevImg, setPrevImg] = useState();
  const [postcode, setPostcode] = useState();

  const handleSearch = (e, setFieldValue) => {
    const data = addressData?.addresses?.find(
      (element) =>
        element?.formatted_address?.filter((item) => item !== "").join(", ") ==
        e.target.value?.toString()
    );

    setFieldValue("city", data?.town_or_city);
    //setFieldValue("country", data?.country);
    setFieldValue("lat", addressData?.latitude);
    setFieldValue("long", addressData?.longitude);
  };

  const handleKeyEnter = () => {
    getAddress(postcode);
  };

  return (
    <>
      <h2 className="h-22">Your Personal Details</h2>

      <span>
        Already Registered? <Link to="/SignIn">Log In</Link>
      </span>
      <Formik
        initialValues={{
          full_name: "",
          dob: "",
          phone_number: "",
          address: "",
          profile_image: "",
          city: "",
          country: "",
          lat: "",
          long: "",
        }}
        validate={(values) => {
          const errors = {};

          if (!values.full_name) {
            errors.full_name = "Required*";
          }
          if (!values.dob) {
            errors.dob = "Required*";
          }

          if (!values.phone_number) {
            errors.phone_number = "Required*";
          }
          if (!values.address) {
            errors.address = "Required*";
          }
          if (!values.country) {
            errors.country = "Required*";
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          onNext(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form
            onSubmit={(e) => {
              // setValidateValue(true);
              handleSubmit(e);
            }}
          >
            <div class="form-group mb-4">
              <div className="img-upload-main">
                <div className="img-upload">
                  <img
                    className="upload-img"
                    src={require("../../images/imageupload.svg").default}
                  />
                  <input
                    type="file"
                    name="profile_image"
                    onChange={(e) => {
                      setPrevImg(URL.createObjectURL(e.target.files[0]));
                      setFieldValue("profile_image", e.target.files[0]);
                    }}
                  ></input>
                  {prevImg ? (
                    <img className="show-img" src={prevImg} />
                  ) : (
                    values.profile_image && (
                      <img className="show-img" src={values.profile_image} />
                    )
                  )}
                </div>
                <p>Add a profile picture </p>
              </div>
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">First Name</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                placeholder="Type your name here..."
                name="full_name"
                onChange={handleChange}
                value={values.full_name}
              />
              <span className="formik-errors">
                {errors.full_name && touched.full_name && errors.full_name}
              </span>
            </div>
            <div class="form-group mt-4 pt-2">
              <label>Date of Birth</label>
              <input
                type="date"
                class="form-control"
                placeholder="--/--/----"
                name="dob"
                onChange={handleChange}
                value={values.dob}
                max={new Date().toISOString().split("T")[0]}
              />
              <span className="formik-errors">
                {errors.dob && touched.dob && errors.dob}
              </span>
            </div>

            <div class="form-group mt-4 pt-2">
              <label>Mobile Number</label>
              <input
                type="number"
                class="form-control"
                placeholder="(+44)"
                name="phone_number"
                onChange={handleChange}
                value={values.phone_number}
              />
              <span className="formik-errors">
                {errors.phone_number &&
                  touched.phone_number &&
                  errors.phone_number}
              </span>
            </div>
            <Col lg={12} className="mt-4">
              <div className="sign-detail-input" style={{ textAlign: "left" }}>
                <label>Postcode</label>
                <div className="d-flex">
                  <InputGroup className="">
                    <input
                      type="search"
                      className="form-control post-code-srch"
                      placeholder="Type Here..."
                      name="postcode"
                      onChange={(e) => {
                        setPostcode(e.target.value);
                        handleChange(e);
                      }}
                      value={values.postcode}
                    />

                    <span className="formik-errors">
                      {errors.postcode && touched.postcode && errors.postcode}
                    </span>
                  </InputGroup>
                  <button type="button" onClick={handleKeyEnter}>
                    Search
                  </button>
                </div>
                {/* <Form.Select
                                  aria-label="Default select example"
                                  className="form-select"
                                >
                                  <option>Services type (e.g hair)</option>
                                  <option>Services type (e.g hair)</option>
                                  <option>Services type (e.g hair)</option>
                                </Form.Select> */}
              </div>
            </Col>
            <div class="form-group mt-4 pt-2">
              <div className="sign-detail-input post-code-types">
                <label>Address</label>
                <select
                  name="address"
                  aria-label="Default select example"
                  className="form-control form-select"
                  onChange={(e) => {
                    handleChange(e);
                    handleSearch(e, setFieldValue);
                  }}
                  value={values.address}
                >
                  <option>Select Address From List</option>
                  {addressData?.addresses?.map((item) => {
                    return (
                      <option
                        value={item?.formatted_address
                          ?.filter((item) => item !== "")
                          .join(", ")}
                      >
                        {item?.formatted_address
                          ?.filter((item) => item !== "")
                          .join(", ")}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div class="form-group mt-4 pt-2">
              <label>Country</label>
              <CountrySelect
                setFieldValue={setFieldValue}
                placeholder="Please Select"
              />

              <span className="formik-errors">
                {errors.country && touched.country && errors.country}
              </span>
            </div>
            <button type="submit" class="common-btn">
              CONTINUE
            </button>
          </form>
        )}
      </Formik>
    </>
  );
};

export default PersonalDetail;
