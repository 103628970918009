import React from 'react';


const Lightbox = ({ image, isOpen, onClose, imageUrl }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="lightbox-overlay" onClick={onClose}>
      <div className="lightbox-content" onClick={(e) => e.stopPropagation()}>
        <span className="close-button" onClick={onClose}>&times;</span>
        <img src={image} alt="Enlarged view" className="lightbox-image" />
      </div>
    </div>
  );
};

export default Lightbox;