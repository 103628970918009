import React from "react";
import Layout from "../elements/Layout";
import { Container, Row, Col } from "react-bootstrap";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Rating } from "react-simple-star-rating";
import { Link } from "react-router-dom";
export default function Quiz() {
  const [rating, setRating] = useState(0);
  return (
    <Layout>
      <Container fluid>
        <div className="videos-sec-main">
          <Row>
            <Col xxl={7} xl={7} lg={7}>
              <div className="video-playback">
                <div className="video-view">
                  <img
                    className="img-fluid"
                    src={require("../images/YouTubeVideoPlayer.png")}
                  />
                  <h2>Eyebrow Tinting Basics with Ash’ed and Dan Watts</h2>
                </div>
                <div className="video-desc">
                  <Row>
                    <Col lg={3} md={4}>
                      <div className="view-user">
                        <img src={require("../images/yt-img-shadow.png")} />
                        <div className="user-name">
                          <h3>Ash’ed</h3>
                          <p>16 Courses</p>
                        </div>
                      </div>
                    </Col>
                    <Col lg={9} md={8} className="d-flex justify-content-end">
                      <div className="videos-tab">
                        <ul>
                          <li>
                            <Link to="/playback" className="filt-tabs">
                              Info
                            </Link>
                          </li>
                          <li>
                            <Link to="/quiz" className="filt-tabs active">
                              {" "}
                              Quiz
                            </Link>
                          </li>
                          <li>
                            <Link to="/questions" className="filt-tabs">
                              {" "}
                              Question
                            </Link>
                          </li>
                          <li>
                            <Link to="/downloads" className="filt-tabs">
                              {" "}
                              Downloads
                            </Link>
                          </li>
                          <li>
                            <button
                              type="button"
                              className="filt-tabs like-btn"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="15"
                                viewBox="0 0 16 15"
                                fill="none"
                              >
                                <path
                                  d="M13.5303 6.17647H10.0119L11.2762 1.81765C11.5423 0.908823 10.8436 0 9.87878 0C9.39634 0 8.93054 0.211765 8.61447 0.573529L3.74022 6.17647H0.413086V15H3.74022H4.572H12.4157C13.2974 15 14.0626 14.4088 14.2373 13.5794L15.3519 8.28529C15.5765 7.19118 14.7031 6.17647 13.5303 6.17647ZM3.74022 14.1176H1.24487V7.05882H3.74022V14.1176ZM14.5368 8.09118L13.4222 13.3853C13.339 13.8088 12.9148 14.1176 12.4157 14.1176H4.572V6.52059L9.22999 1.17353C9.38803 0.988235 9.62924 0.882353 9.87878 0.882353C10.095 0.882353 10.2947 0.979412 10.4028 1.14706C10.461 1.23529 10.5276 1.37647 10.4777 1.56176L9.21335 5.92059L8.88064 7.05882H10.0035H13.522C13.863 7.05882 14.1874 7.20882 14.3787 7.46471C14.4869 7.59706 14.595 7.81765 14.5368 8.09118Z"
                                  fill="#0F0F0F"
                                />
                              </svg>
                              11
                            </button>
                          </li>
                        </ul>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="info-sec">
                        <span>5,230 views</span>
                        <p>
                          Hey loves😘 I thought I would drop this quick updated
                          brow routine✨Please like! ALMOST AT 20k AHHHHH👏🏽😩
                          love yall!!! I wear these brows ONLY when wearing a
                          full face of makeup btw❤️{" "}
                        </p>
                        <h6>...more</h6>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="quiz-sec-main">
                        <Row>
                          <Col lg={12}>
                            <div className="quiz-sec">
                              <h3>
                                <span>1.</span>
                                This is a question in the quiz. This is a
                                question in the quiz?{" "}
                              </h3>
                              <div className="quiz-options">
                                <form>
                                  <div className="mb-3 ms-4">
                                    <div className="form-check mb-2">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="question1"
                                        id="Answer A"
                                        value="Answer A"
                                      />
                                      <label
                                        className="form-check-label"
                                        for="Answer A"
                                      >
                                        Answer A
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="question1"
                                        id="Answer B"
                                        value="Answer B"
                                      />
                                      <label
                                        className="form-check-label"
                                        for="Answer B"
                                      >
                                        Answer B
                                      </label>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>

                            <div className="quiz-sec">
                              <h3>
                                <span>1.</span>
                                This is a question in the quiz. This is a
                                question in the quiz?{" "}
                              </h3>
                              <div className="quiz-options">
                                <form>
                                  <div className="mb-3 ms-4">
                                    <div className="form-check mb-2">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="question1"
                                        id="Answer A"
                                        value="Answer A"
                                      />
                                      <label
                                        className="form-check-label"
                                        for="Answer A"
                                      >
                                        Answer A
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="question1"
                                        id="Answer B"
                                        value="Answer B"
                                      />
                                      <label
                                        className="form-check-label"
                                        for="Answer B"
                                      >
                                        Answer B
                                      </label>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </Col>
                          {/* <Col lg={3}>
                            <div className="quiz-passed">
                              <h2>Congratulations!</h2>
                              <p>You passed this quiz!</p>
                              <div className="answers-show-quiz">
                                <p>2/2</p>
                              </div>
                            </div>
                          </Col> */}
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col xxl={5} xl={5} lg={5}>
              {" "}
              <div className="videos-tab">
                <ul>
                  <li>
                    <Link to="/playback" className="filt-tabs active">
                      Chapters
                    </Link>
                  </li>
                  <li>
                    <Link to="" className="filt-tabs">
                      More Like this
                    </Link>
                  </li>
                  <li>
                    <Link to="/playback-Products" className="filt-tabs">
                      Products Used
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="playback-tabs-views">
                <div className="playback-side-videos">
                  <img src={require("../images/vinner1.png")} />
                  <div className="playback-v-desc">
                    <h2>Chapter 1: The best eyebrow tutorial you’ll</h2>
                    <h5>Ash’ed</h5>
                    <p>6M views </p>
                  </div>
                </div>
                <div className="playback-side-videos">
                  <img src={require("../images/vinner2.png")} />
                  <div className="playback-v-desc">
                    <h2>Chapter 1: The best eyebrow tutorial you’ll</h2>
                    <h5>Ash’ed</h5>
                    <p>6M views </p>
                  </div>
                </div>
                <div className="playback-side-videos">
                  <img src={require("../images/vinner3.png")} />
                  <div className="playback-v-desc">
                    <h2>Chapter 1: The best eyebrow tutorial you’ll</h2>
                    <h5>Ash’ed</h5>
                    <p>6M views </p>
                  </div>
                </div>
                <div className="playback-side-videos">
                  <img src={require("../images/vinner4.png")} />
                  <div className="playback-v-desc">
                    <h2>Chapter 1: The best eyebrow tutorial you’ll</h2>
                    <h5>Ash’ed</h5>
                    <p>6M views </p>
                  </div>
                </div>
                <div className="playback-side-videos">
                  <img src={require("../images/vinner5.png")} />
                  <div className="playback-v-desc">
                    <h2>Chapter 1: The best eyebrow tutorial you’ll</h2>
                    <h5>Ash’ed</h5>
                    <p>6M views </p>
                  </div>
                </div>
                <div className="playback-side-videos">
                  <img src={require("../images/vinner1.png")} />
                  <div className="playback-v-desc">
                    <h2>Chapter 1: The best eyebrow tutorial you’ll</h2>
                    <h5>Ash’ed</h5>
                    <p>6M views </p>
                  </div>
                </div>
                <div className="playback-side-videos">
                  <img src={require("../images/vinner2.png")} />
                  <div className="playback-v-desc">
                    <h2>Chapter 1: The best eyebrow tutorial you’ll</h2>
                    <h5>Ash’ed</h5>
                    <p>6M views </p>
                  </div>
                </div>
                <div className="playback-side-videos">
                  <img src={require("../images/vinner3.png")} />
                  <div className="playback-v-desc">
                    <h2>Chapter 1: The best eyebrow tutorial you’ll</h2>
                    <h5>Ash’ed</h5>
                    <p>6M views </p>
                  </div>
                </div>
                <div className="playback-side-videos">
                  <img src={require("../images/vinner4.png")} />
                  <div className="playback-v-desc">
                    <h2>Chapter 1: The best eyebrow tutorial you’ll</h2>
                    <h5>Ash’ed</h5>
                    <p>6M views </p>
                  </div>
                </div>
                <div className="playback-side-videos">
                  <img src={require("../images/vinner5.png")} />
                  <div className="playback-v-desc">
                    <h2>Chapter 1: The best eyebrow tutorial you’ll</h2>
                    <h5>Ash’ed</h5>
                    <p>6M views </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </Layout>
  );
}
