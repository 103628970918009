import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Form, Link, useNavigate, useParams } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import CreatorLayout from "../elements/CreatorLayout";
import SettingsAddQuestion from "./SettingsAddQuestion";
import QuizSetting from "./QuizSetting";
import { Formik } from "formik";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Addquiz, EditQuestion, Editquiz, getQuizlists } from "../redux/actions/userAction";
import SettingsEditQuestion from "./SettingsEditQuestion";
export default function SettingsEditQuiz() {
  const dispatch = useDispatch();
  const { chapterId, courseId } = useParams();
  const [file, setFile] = useState();
  const [page, setPage] = useState(0);
  const { quizlists, chapters } = useSelector((state) => ({
    quizlists: state.userMgmtSlice.quizlists,
    chapters: state.userMgmtSlice.chapters,
  }));
  const [quesById, setQuesById] = useState({})

  console.log("page----", page)
  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  const navigate = useNavigate();
  const navigateToContacts = () => {
    // 👇️ navigate to /contacts
    navigate("/settings-add-question");
  };

  console.log("quizlists?.question", quizlists?.question)

  useEffect(() => {
    dispatch(getQuizlists({ chapterId }));
  }, [chapterId])

  return (
    <CreatorLayout>
      <Container>
        <Formik
          //enableReinitialize={true}
          initialValues={{
            minimumpercentagepassrate: "",
            canproceednext: "",
            question: [],
          }}
          validate={(values) => {
            const errors = {};

            if (!values.canproceednext) {
              errors.canproceednext = "Required*";
            }
            if (!values.minimumpercentagepassrate) {
              errors.minimumpercentagepassrate = "Required*";
            }
            // if (!values.question || values.question.length === 0) {
            //   errors.question = 'At least one question is required';
            // }

            return errors;
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            console.log(values, "values");
            values.chapter_id = chapterId;
            if (values?.question?.length == 0) {
              setPage(1);
              return toast.error("At least one question is required!");
            }

            let payload = {
              canproceednext: values?.canproceednext,
              chapter_id: values?.chapter_id,
              minimumpercentagepassrate: values?.minimumpercentagepassrate
            }
            dispatch(Editquiz(payload)).then((data) => {
              if (data?.payload?.success) {
                toast.success(data?.payload?.message);
                navigate(`/view-quiz-chapter/${courseId}/${chapterId}`);
              }
            });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) =>
            page == 0 ? (
              <>
                <form
                  onSubmit={(e) => {
                    // setValidateValue(true);
                    handleSubmit(e);
                  }}
                >
                  <div className="profile-top-box">
                    <div className="box-left">
                      <div className="backpage-arrow">
                        <Link to={`/settings-chapter-view/${courseId}/${chapterId}`}>
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="40"
                              height="40"
                              viewBox="0 0 40 40"
                              fill="none"
                            >
                              <path
                                d="M10 20.0003L8.93934 21.061L7.87868 20.0003L8.93934 18.9397L10 20.0003ZM30 18.5003C30.8284 18.5003 31.5 19.1719 31.5 20.0003C31.5 20.8288 30.8284 21.5003 30 21.5003V18.5003ZM15.606 27.7277L8.93934 21.061L11.0607 18.9397L17.7273 25.6063L15.606 27.7277ZM8.93934 18.9397L15.606 12.273L17.7273 14.3943L11.0607 21.061L8.93934 18.9397ZM10 18.5003H30V21.5003L10 21.5003L10 18.5003Z"
                                fill="#40413A"
                              />
                            </svg>
                          </span>
                          Back to the previous page.
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="edit-course-box">
                    <div className="edit-cou-top">
                      <div className="box-left">
                        <h2>Edit Quiz</h2>
                      </div>
                      {/* <div className="box-right">
              <button type="button" className="add-cerft">
                Add Certificate
              </button>
            </div> */}
                    </div>
                    <hr />
                    <div className="edit-cou-top">
                      <div className="box-left">
                        <p className="m-0">Edit your quiz questions here</p>
                      </div>
                      <div className="box-right mb-3">
                        <div className="add-curse">
                          {/* <button
                            type="buttom"
                            className="add-icon"
                            onClick={() => {
                              setPage(1);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              viewBox="0 0 25 25"
                              fill="none"
                            >
                              <path
                                d="M12.5 6.25L12.5 18.75"
                                stroke="#8B8B8B"
                                stroke-width="2"
                                stroke-linecap="square"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M18.75 12.5L6.25 12.5"
                                stroke="#8B8B8B"
                                stroke-width="2"
                                stroke-linecap="square"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </button> */}
                        </div>
                      </div>
                    </div>

                    {quizlists?.question?.map((item, i) => {
                      return (
                        <>
                          <div className="quiz-sec mt-3">
                            <button type="button" className="edit-btn-quiz" onClick={() => {
                              setQuesById(item)
                              setPage(1);
                            }}><img src={require("../images/edit-btn.svg").default} /></button>
                            <h3>
                              <span>{i + 1}.</span>
                              {item?.questiontitle}{" "}
                            </h3>
                            <div className="quiz-options">
                              <div className="mb-3 ms-4">
                                {item?.option?.map((data) => {
                                  return (
                                    <>
                                      <div className="form-check mb-2">
                                        <label
                                          className="form-check-label"
                                          for="Answer A"
                                        >
                                          {data?.optiontitle}
                                        </label>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}

                    {/* <div className="sign-form-inner">
                      <button
                        onClick={() => {
                          setPage(2);
                        }}
                        class="common-btn mt-3"
                      >
                        UPDATE
                      </button>
                    </div> */}
                  </div>
                </form>
              </>
            ) : page == 1 ? (
              <SettingsEditQuestion
                quesById={quesById}
                values={values}
                setFieldValue={setFieldValue}
                setPage={setPage}
              />
            ) : (
              page == 2 && (
                <QuizSetting
                  values={values}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  setPage={setPage}
                  errors={errors}
                  touched={touched}
                />
              )
            )
          }
        </Formik>
      </Container>
    </CreatorLayout>
  );
}
