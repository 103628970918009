import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";

export default function SettingsAddQuestion({
  setPage,
  setFieldValue,
  values,
}) {
  const { courseId, chapterId } = useParams();
  const [stateVal, setStateVal] = useState({
    questiontitle: "",
    option: [{ optiontitle: "", answer: 0 }],
  });

  const [errors, setErrors] = useState({
    questiontitle: "",
    option: [{ title: "", error: "" }],
  });

  const handleStateVal = (e) => {
    const { name, value } = e.target;
    setStateVal((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value.trim() ? "" : "Question title is required.",
    }));
  };

  const handleOptionChange = (index, value) => {
    const updatedOptions = [...stateVal.option];
    updatedOptions[index].optiontitle = value;
    setStateVal((prevState) => ({
      ...prevState,
      option: updatedOptions,
    }));
    setErrors((prevErrors) => {
      const updatedOptionErrors = [...prevErrors.option];
      updatedOptionErrors[index].title = value;
      updatedOptionErrors[index].error = value.trim()
        ? ""
        : "Option is required.";
      return { ...prevErrors, option: updatedOptionErrors };
    });
  };

  const handleRadioChange = (index) => {
    const updatedOptions = stateVal.option.map((opt, i) => ({
      ...opt,
      answer: i === index,
    }));
    setStateVal((prevState) => ({
      ...prevState,
      option: updatedOptions,
    }));
  };

  const addOption = () => {
    setStateVal((prevState) => ({
      ...prevState,
      option: [...prevState.option, { optiontitle: "", answer: 0 }],
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      option: [...prevErrors.option, { title: "", error: "" }],
    }));
  };

  const removeOption = (index) => {
    const updatedOptions = [...stateVal.option];
    updatedOptions.splice(index, 1);
    setStateVal((prevState) => ({
      ...prevState,
      option: updatedOptions,
    }));
    setErrors((prevErrors) => {
      const updatedOptionErrors = [...prevErrors.option];
      updatedOptionErrors.splice(index, 1);
      return { ...prevErrors, option: updatedOptionErrors };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate question title and all options
    const newErrors = {
      questiontitle: stateVal.questiontitle.trim()
        ? ""
        : "Question title is required.",
      option: stateVal.option.map((opt) => ({
        title: opt.optiontitle,
        error: opt.optiontitle.trim() ? "" : "Option is required.",
      })),
    };

    if (
      newErrors.questiontitle ||
      newErrors.option.some((opt) => opt.error !== "")
    ) {
      setErrors(newErrors);
      return;
    }

    // Check if at least one option is marked as correct
    if (!stateVal.option.some((opt) => opt.answer)) {
      alert("Please select a correct answer.");
      return;
    }

    // Proceed with form submission
    console.log("Form submitted:", stateVal);
    setFieldValue(`question`, [...values.question, stateVal]);
    // Reset form fields or navigate to another page
    setStateVal({
      questiontitle: "",
      option: [{ optiontitle: "", answer: 0 }],
    });
    setErrors({
      questiontitle: "",
      option: [{ title: "", error: "" }],
    });
    setPage(0); // Navigate to another page, if needed
  };

  return (
    <>
      <div className="profile-top-box">
        <div className="backpage-arrow">
          <Link to={`/settings-chapter-view/${courseId}/${chapterId}`}>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
              >
                <path
                  d="M10 20.0003L8.93934 21.061L7.87868 20.0003L8.93934 18.9397L10 20.0003ZM30 18.5003C30.8284 18.5003 31.5 19.1719 31.5 20.0003C31.5 20.8288 30.8284 21.5003 30 21.5003V18.5003ZM15.606 27.7277L8.93934 21.061L11.0607 18.9397L17.7273 25.6063L15.606 27.7277ZM8.93934 18.9397L15.606 12.273L17.7273 14.3943L11.0607 21.061L8.93934 18.9397ZM10 18.5003H30V21.5003L10 21.5003L10 18.5003Z"
                  fill="#40413A"
                />
              </svg>
            </span>
            Back to the previous page.
          </Link>
        </div>
      </div>
      <div className="edit-course-box">
        <div className="edit-cou-top">
          <div className="box-left">
            <h2>Add Question</h2>
          </div>
        </div>
        <hr />
        <div className="edit-cou-top">
          <div className="box-left">
            <p className="m-0">Create a new question for your Quiz</p>
          </div>
          <div className="box-right"></div>
        </div>
        <div className="sign-form-inner">
          <form onSubmit={handleSubmit}>
            <div className="form-group mt-4 pt-2">
              <label htmlFor="questionTitle">Question Title</label>
              <input
                type="text"
                className="form-control"
                id="questionTitle"
                placeholder="Type here..."
                name="questiontitle"
                value={stateVal.questiontitle}
                onChange={handleStateVal}
              />
              {errors.questiontitle && (
                <small className="text-danger">{errors.questiontitle}</small>
              )}
            </div>
            {stateVal.option.map((option, index) => (
              <div className="form-group mt-4 pt-2" key={index}>
                <label htmlFor={`option${index + 1}`}>{`Answer Option ${
                  index + 1
                }`}</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    id={`option${index + 1}`}
                    placeholder="Type here..."
                    value={option.optiontitle}
                    onChange={(e) => handleOptionChange(index, e.target.value)}
                  />

                  <div className="form-check ml-3 check-correct">
                    <input
                      type="radio"
                      className="form-check-input correct-input"
                      id={`correctOption${index + 1}`}
                      checked={option.answer}
                      onChange={() => handleRadioChange(index)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`correctOption${index + 1}`}
                    >
                      Correct
                    </label>
                  </div>
                  {stateVal.option.length > 1 && (
                    <button
                      type="button"
                      className="remove-btn"
                      onClick={() => removeOption(index)}
                    >
                      <svg
                        enable-background="new 0 0 512 512"
                        height="512px"
                        id="Layer_1"
                        version="1.1"
                        viewBox="0 0 512 512"
                        width="512px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M256,7C118.467,7,7,118.468,7,256.002C7,393.533,118.467,505,256,505s249-111.467,249-248.998  C505,118.468,393.533,7,256,7z M256,485.08c-126.31,0-229.08-102.771-229.08-229.078C26.92,129.692,129.69,26.92,256,26.92  c126.309,0,229.08,102.771,229.08,229.082C485.08,382.309,382.309,485.08,256,485.08z"
                          fill="#425661"
                        />
                        <polygon
                          fill="#425661"
                          points="368.545,157.073 354.461,142.988 255.863,241.587 157.733,143.456 143.648,157.54 241.78,255.672   143.648,353.809 157.733,367.893 255.863,269.75 354.461,368.361 368.545,354.275 269.947,255.672 "
                        />
                      </svg>
                    </button>
                  )}
                  {stateVal?.option?.length == index + 1 && (
                    <div className="add-curse">
                      <button
                        type="button"
                        className="add-icon list"
                        onClick={addOption}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="25"
                          viewBox="0 0 25 25"
                          fill="none"
                        >
                          <path
                            d="M12.5 6.25L12.5 18.75"
                            stroke="#8B8B8B"
                            stroke-width="2"
                            stroke-linecap="square"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M18.75 12.5L6.25 12.5"
                            stroke="#8B8B8B"
                            stroke-width="2"
                            stroke-linecap="square"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                  )}
                </div>

                {errors.option[index].error && (
                  <small className="text-danger">
                    {errors.option[index].error}
                  </small>
                )}
              </div>
            ))}
            <button type="submit" className="common-btn">
              CREATE QUESTION
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
