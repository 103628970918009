import React from "react";
import { Link } from "react-router-dom";
import useAuthToken from "../Hooks/useAuthToken";
import { useDispatch } from "react-redux";
import { Addaccount } from "../redux/actions/userAction";

export default function CreatorSidebar() {
  const dispatch = useDispatch();
  const tokenData = useAuthToken();
  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = "/";
  };

  const handleBankDetails = () => {
    dispatch(Addaccount())
      .then((res) => {
        console.log(res, "Addaccount");
        if (res.payload.success) {
          window.location.href = res?.payload?.data?.url;
        }
      })
      .catch((error) => console.log(error));
  };
  return (
    <div>
      <sidebar className="creator-sidebar">
        {/* <div className="logo-main">
          <img src={require("../images/logo-mian.png")} />
        </div> */}
        <div className="btm-btns setting-sidebar">
          <div>
            <h3 className="mb-4">Settings</h3>
            <Link
              to="/settings-profile"
              className={
                window.location.pathname == "/settings-profile"
                  ? "menu-btn bar_active"
                  : "menu-btn "
              }
            >
              Profile
            </Link>
            <Link
              to="/creator-certificates"
              className={
                window.location.pathname == "/creator-certificates"
                  ? "menu-btn bar_active"
                  : "menu-btn "
              }
            >
              My Certificates
            </Link>
            {tokenData?.role != 0 && (
              <Link
                to="/settings-courses"
                className={
                  window.location.pathname == "/settings-courses"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
              >
                My Courses
              </Link>
            )}

            <Link
              to="/creator-invoice"
              className={
                window.location.pathname == "/creator-invoice"
                  ? "menu-btn bar_active"
                  : "menu-btn "
              }
            >
              Invoices
            </Link>
            {
               tokenData?.role != 0 && (
                <Link
                to="/wallet"
                className={
                  window.location.pathname == "/wallet"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
              >
                Wallet
              </Link>
               )
            }
           
            {tokenData?.role != 0 && (
              <Link
                onClick={handleBankDetails}
                to="#"
                className={
                  window.location.pathname == "#"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
              >
                Bank Details
              </Link>
            )}

            <Link
              to="/creator-downloadables"
              className={
                window.location.pathname == "/creator-downloadables"
                  ? "menu-btn bar_active"
                  : "menu-btn "
              }
            >
              Downloadables
            </Link>
            {tokenData?.role != 0 && (
              <Link
                to="/reports"
                className={
                  window.location.pathname == "/reports"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
              >
                Reports
              </Link>
            )}
            <Link
              to="/creator-preference"
              className={
                window.location.pathname == "/creator-preference"
                  ? "menu-btn bar_active"
                  : "menu-btn "
              }
            >
              Contact Preference
            </Link>

            <Link
              to="/creator-security"
              className={
                window.location.pathname == "/creator-security"
                  ? "menu-btn bar_active"
                  : "menu-btn "
              }
            >
              Password & Security
            </Link>
            <Link
              onClick={handleLogout}
              to="#"
              className={
                window.location.pathname == "/SignIn"
                  ? "menu-btn bar_active"
                  : "menu-btn "
              }
            >
              Logout
            </Link>
          </div>
        </div>
      </sidebar>
    </div>
  );
}
