import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Form, Link } from "react-router-dom";
import CreatorLayout from "../elements/CreatorLayout";
import { useDispatch, useSelector } from "react-redux";
import { downloadVideo, getAllDownloads } from "../redux/actions/userAction";
import useAuthToken from "../Hooks/useAuthToken";
import { getUserById } from "../redux/actions/userAuth";
import moment from "moment";
export default function CreatorDownloadables() {
  const dispatch = useDispatch();
  const tokenData = useAuthToken();
  const { downloadArr, getRegisterUser } = useSelector((state) => ({
    downloadArr: state.userMgmtSlice.downloadArr,
    getRegisterUser: state.userMgmtSlice.getRegisterUser,
  }));

  useEffect(() => {
    dispatch(getAllDownloads());
  }, []);

  useEffect(() => {
    dispatch(getUserById(tokenData?._id));
  }, []);

  console.log(downloadArr, "downloadArr");

  const handleDownload2 = async (downloadObj) => {
    if (downloadObj?.docfilename) {
      window.location.href = downloadObj?.docfilename;
    }
  };

  return (
    <CreatorLayout>
      <Container>
        <div className="profile-detail-box">
          <div className="course-top-box">
            <div className="box-left">
              <h2>Downloadables</h2>
              <p>All of your course documents in one place</p>
            </div>
            <div className="box-right">
              <p>{getRegisterUser?.full_name}</p>
              <span>
                Member Since:{" "}
                {moment(getRegisterUser?.createdAt).format("DD/MM/YYYY")}
              </span>
            </div>
          </div>
          <div className="invoice-list-box">
            {downloadArr?.map((item) => {
              return (
                <>
                  <div className="invoice-list-contain">
                    <div className="invoice-list-item">
                      <div className="box-left with-pro-text">
                        <img
                          src={
                            item?.course?.cover_image
                              ? item?.course?.cover_image
                              : require("../images/Videoimg1.png")
                          }
                        />

                        <div className="">
                          <h2>{item?.course?.course_title}</h2>
                          <p>By {item?.course?.creator_id?.full_name}</p>
                        </div>
                      </div>
                      <div className="box-right">
                        {/* <Link to="">Download</Link> */}
                      </div>
                    </div>
                    <div className="get-pdf-box">
                      {item?.downloads?.map((data) => {
                        const findDoc = data?.chapter_id?.doc?.find(
                          (item) =>
                            item?._id?.toString() == data?.doc_id?.toString()
                        );

                        const parts = findDoc?.docfilename?.split(".");
                        const lastPartWithExtension =
                          parts?.length > 0 ? parts[parts?.length - 1] : "";
                        console.log(parts, "partspartsparts");

                        return (
                          <div className="get-pdf-list">
                            <p>{findDoc?.title}</p>
                            <div className="downloadables-show">
                              <p className="mb-0 me-2">
                                {lastPartWithExtension}
                              </p>
                              <button
                                type="button"
                                onClick={() => handleDownload2(data, findDoc)}
                              >
                                <img
                                  src={
                                    require("../images/Download.svg").default
                                  }
                                />
                              </button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <hr />
                </>
              );
            })}
            {downloadArr?.length == 0 && <p>No data available yet!</p>}
          </div>
        </div>
      </Container>
    </CreatorLayout>
  );
}
