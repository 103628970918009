import update from "immutability-helper";
import { useCallback, useState } from "react";
import { useDrop } from "react-dnd";
import { DraggableBox } from "./DraggableBox.js";
import { ItemTypes } from "./ItemTypes.js";
import { snapToGrid as doSnapToGrid } from "./snapToGrid.js";
const styles = {
  width: "100%",

  border: "2px solid #dedede",
  borderStyle: "dashed",
  position: "relative",
};
export const Container = ({
  setSelectedFile,
  snapToGrid,
  setFieldValue,
  selectedFile,
  values,
  certStep,
}) => {
  const [boxes, setBoxes] = useState({
    a: { top: values?.certificate_y_axis || -150, left:  values?.certificate_x_axis || 80, title: "Drag me around" },
    b: { top: values?.certificate_y_axis2 || -100, left: values?.certificate_x_axis2 || 20, title: "Drag me too" },
  });
  const moveBox = useCallback(
    (id, left, top) => {
   
      
      setBoxes(
        update(boxes, {
          [id]: {
            $merge: { left, top },
          },
        })
      );
    },
    [boxes]
  );
  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.BOX,
      drop(item, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset();
        let left = Math.round(item.left + delta.x);
        let top = Math.round(item.top + delta.y);
        if (snapToGrid) {
          [left, top] = doSnapToGrid(left, top);
        }
        moveBox(item.id, left, top);
        return undefined;
      },
    }),
    [moveBox]
  );

  if (certStep == -1) {
    setSelectedFile(null);
    setFieldValue("certificate", "");
  }

  console.log(certStep, "certStep");
  return (
    <div ref={drop} style={styles} className="docs-upload-view edit-certificate">
      {/* Display preview if a file is selected */}
      {selectedFile ? (
        <div className="pdf-preview">
          <img src={URL.createObjectURL(selectedFile)} width="100%" />
        </div>
      ) : (
        <div className="pdf-preview">
          <img src={values?.certificate} width="100%" />
        </div>
      )}
      {/* {Object.keys(boxes).map((key) => (
        <DraggableBox key={key} id={key} {...boxes[key]} />
      ))} */}

      {certStep >= 1 && <DraggableBox values={values} setFieldValue={setFieldValue} key={"a"} id={"a"} {...boxes["a"]} />}
      {certStep == 2 && <DraggableBox values={values} setFieldValue={setFieldValue} key={"b"} id={"b"} {...boxes["b"]} />}
    </div>
  );
};
