import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import CreatorLayout from "../elements/CreatorLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  getHighestRatedCourse,
  getMostPurchasedCourse,
  getMostWatchedVideo,
  getVideoReport,
} from "../redux/actions/userAction";

export default function Reports() {
  const dispatch = useDispatch();
  const {
    videoReport,
    highestRatedCourse,
    mostWatchedVideo,
    mostPurchasedCourse,
  } = useSelector((state) => ({
    videoReport: state.userMgmtSlice.videoReport,
    highestRatedCourse: state.userMgmtSlice.highestRatedCourse,
    mostWatchedVideo: state.userMgmtSlice.mostWatchedVideo,
    mostPurchasedCourse: state.userMgmtSlice.mostPurchasedCourse,
  }));

  useEffect(() => {
    dispatch(getVideoReport());
    dispatch(getMostPurchasedCourse());
    dispatch(getMostWatchedVideo());
    dispatch(getHighestRatedCourse());
  }, []);

  console.log(
    videoReport,
    highestRatedCourse,
    mostWatchedVideo,
    mostPurchasedCourse,
    "videoReport"
  );

  return (
    <CreatorLayout>
      <Container>
        <div className="profile-detail-box">
          <div className="course-top-box">
            <div className="box-left">
              <h2>Reports</h2>
              <p>Here are the latest statistics</p>
            </div>
          </div>
          <div className="mt-4">
            <div className="report-list-point">
              <div className="left-s">
                <p>Total Views</p>
              </div>
              <div className="right-s">
                <p>{videoReport?.totalViewCount || 0}</p>
              </div>
            </div>
            <div className="report-list-point">
              <div className="left-s">
                <p>Total course Purchases</p>
              </div>
              <div className="right-s">
                <p>{videoReport?.totalPurchaseCount || 0}</p>
              </div>
            </div>
            <div className="report-list-point">
              <div className="left-s">
                <p>Total course likes</p>
              </div>
              <div className="right-s">
                <p>{videoReport?.totalLikeCount || 0}</p>
              </div>
            </div>
            <div className="report-list-point">
              <div className="left-s">
                <p>Total Earnings</p>
              </div>
              <div className="right-s">
                <p>£{mostPurchasedCourse?.totalAmount || 0}</p>
              </div>
            </div>
          </div>
          <hr />
          <div className="add-chapters-head">
            <h2>Most Watched Video</h2>
          </div>
          <div className="mt-3">
            <div className="add-chapters-list">
              <div className="left-s">
                <img
                  src={
                    mostWatchedVideo?.chapter?.cover_image
                      ? 
                        mostWatchedVideo?.chapter?.cover_image
                      : require("../images/imagesplaceholder.png")
                  }
                />
              </div>
              <div className="right-s">
                <h3>{mostWatchedVideo?.chapter?.chapter_title}</h3>
                <p>{mostWatchedVideo?.viewsCount || 0} Views</p>
              </div>
            </div>
          </div>
          <div className="add-chapters-head">
            <h2>Most Purchased Course</h2>
          </div>
          <div className="mt-3">
            <div className="add-chapters-list">
              <div className="left-s">
                <img
                  src={
                    mostPurchasedCourse?.courseDetail?.cover_image
                      ? 
                        mostPurchasedCourse?.courseDetail?.cover_image
                      : require("../images/imagesplaceholder.png")
                  }
                />
              </div>
              <div className="right-s">
                <h3>{mostPurchasedCourse?.courseDetail?.course_title}</h3>
                <p>{mostPurchasedCourse?.viewsCount || 0} Views</p>
              </div>
            </div>
          </div>
          <div className="add-chapters-head">
            <h2>Highest Liked Course</h2>
          </div>
          <div className="mt-3">
            <div className="add-chapters-list">
              <div className="left-s">
                <img
                  src={
                    highestRatedCourse?.courseDetail?.cover_image
                      ? 
                        highestRatedCourse?.courseDetail?.cover_image
                      : require("../images/imagesplaceholder.png")
                  }
                />
              </div>
              <div className="right-s">
                <h3>{highestRatedCourse?.courseDetail?.course_title}</h3>
                <p>{highestRatedCourse?.viewsCount || 0} Views</p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </CreatorLayout>
  );
}
