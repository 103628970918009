import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Form, Link, useNavigate, useParams } from "react-router-dom";
// import ProgressBar from "@ramonak/react-progress-bar";
import { Rating } from "react-simple-star-rating";
import Modal from "react-bootstrap/Modal";
import Navbar from "react-bootstrap/Navbar";
import CreatorLayout from "../elements/CreatorLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  editCourse,
  getChapters,
  getCourseById,
} from "../redux/actions/userAction";
import VideoThumbnail from "react-video-thumbnail";
import ParseDuration from "../components/ParseDuration";
import parseDuration from "../utils/parseDuration ";
import { Formik } from "formik";
import toast from "react-hot-toast";
import { DndProvider } from "react-dnd";
import Example from "../components/DraggableCertificate/Example";
import { HTML5Backend } from "react-dnd-html5-backend";
import html2canvas from "html2canvas";
export default function SettingsEditCourse() {
  const childRef = useRef();
  const dispatch = useDispatch();
  const { courseId } = useParams();
  const { chapters } = useSelector((state) => ({
    chapters: state.userMgmtSlice.chapters,
  }));
  console.log("chapters---", chapters);
  const [show, setShow] = useState(false);
  const [courseDetail, setCourseDetail] = useState();
  const [certStep, setCertStep] = useState(0);
  const [closeImg, setCloseImg] = useState(1);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showStamp, setShowStamp] = useState(false);

  const handleCloseStamp = () => setShowStamp(false);
  const handleShowStamp = () => setShowStamp(true);

  const [showView, setShowView] = useState(false);
  const [showPreviewCert, setShowPreviewCert] = useState(false);

  const handleCloseView = () => setShowView(false);
  const handleShowView = () => setShowView(true);

  const handleClosePreviewCert = () => setShowPreviewCert(false);
  const handleShowPreviewCert = () => setShowPreviewCert(true);

  const [file, setFile] = useState();
  const [selectedFile, setSelectedFile] = useState(null);

  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  const navigate = useNavigate();
  const navigateToContacts = () => {
    // 👇️ navigate to /contacts
    navigate(`/settings-add-chapter/${courseId}`);
  };

  useEffect(() => {
    dispatch(getChapters(courseId));
  }, [courseId]);

  // Function to handle file selection
  const handleFileChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    const fileType = file.type;
    if (fileType === "application/pdf") {
      console.log("The file is a PDF.");
    }

    setCertStep(0);
    if (file) {
      setSelectedFile(file);
      setFieldValue("certificate", file);
    } else {
      setSelectedFile(null);
      alert("Please select a PDF file.");
    }
  };
  useEffect(() => {
    if (courseId) {
      dispatch(getCourseById(courseId)).then((data) => {
        setCourseDetail(data?.payload?.data);
      });
    }
  }, [courseId]);

  const callChildMethod = () => {
    const returnVal = childRef.current.handleCapture();
    console.log(returnVal, "returnVal");
  };

  useEffect(() => {
    if (courseDetail?.certificate) {
      setCertStep(2);
    }
  }, [courseDetail]);

  console.log(courseDetail, "chapters courseDetail");

  return (
    <CreatorLayout>
      <Container>
        <div className="profile-top-box">
          <div className="box-left">
            <div className="backpage-arrow">
              <Link to="/settings-courses">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                  >
                    <path
                      d="M10 20.0003L8.93934 21.061L7.87868 20.0003L8.93934 18.9397L10 20.0003ZM30 18.5003C30.8284 18.5003 31.5 19.1719 31.5 20.0003C31.5 20.8288 30.8284 21.5003 30 21.5003V18.5003ZM15.606 27.7277L8.93934 21.061L11.0607 18.9397L17.7273 25.6063L15.606 27.7277ZM8.93934 18.9397L15.606 12.273L17.7273 14.3943L11.0607 21.061L8.93934 18.9397ZM10 18.5003H30V21.5003L10 21.5003L10 18.5003Z"
                      fill="#40413A"
                    />
                  </svg>
                </span>
                Back to the previous page.
              </Link>
            </div>
          </div>
        </div>
        <div className="edit-course-box">
          <div className="edit-cou-top">
            <div className="box-left">
              <h2>Edit Course</h2>
            </div>
          </div>
          <hr />
          <div className="edit-main">
            <button
              style={{ border: "none", background: "none" }}
              onClick={() =>
                navigate(`/settings-create-courses?id=${courseId}`)
              }
            >
              <img src={require("../images/edit-btn.svg").default} />
            </button>
          </div>

          <div className="row">
            <div className="col-lg-5">
              <div className="edit-crse-tp-img">
                <img
                  src={
                    chapters?.cover_image
                      ? chapters?.cover_image
                      : require("../images/user-img.png")
                  }
                />
              </div>
            </div>
            <div className="col-lg-7 d-flex align-items-center">
              <div className="course-right-sec">
                <h3>{chapters?.course_title}</h3>
                <p>Category: {chapters?.coursecategory?.title ?? "PMU"}</p>

                <p>{chapters?.course_desc}</p>
              </div>
            </div>
          </div>
          <hr />
          <div className="edit-cou-top">
            <div className="box-left">
              <h2>Add Chapters</h2>
              <p className="m-0">Create and manage course here</p>
            </div>
            <div className="box-right">
              <div className="add-curse">
                <button
                  type="buttom"
                  className="add-icon"
                  onClick={navigateToContacts}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                  >
                    <path
                      d="M12.5 6.25L12.5 18.75"
                      stroke="#8B8B8B"
                      stroke-width="2"
                      stroke-linecap="square"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M18.75 12.5L6.25 12.5"
                      stroke="#8B8B8B"
                      stroke-width="2"
                      stroke-linecap="square"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="chapter-list-full">
            {chapters?.chapters?.map((item) => {
              return (
                <>
                  <div className="mt-3">
                    <div className="add-chapters-list ">
                      <div className="left-s">
                        <Link
                          to={`/settings-chapter-view/${courseId}/${item?._id}`}
                        >
                          <img src={item?.cover_image || item?.chapter_cover_image} />
                          {item?.status == 0 && <span>Pending</span>}
                        </Link>
                      </div>
                      <div className="right-s">
                        <h3>{item?.chapter_title}</h3>
                        {item?.duration && parseDuration(item?.duration)}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
            {chapters?.chapters?.length == 0 && <p>No chapters yet!</p>}
          </div>
          <hr />
          <div className="edit-cou-top">
            <div className="box-left">
              <h2>{courseDetail?.certificate ? "Edit" : "Add"} Certificate</h2>
            </div>
          </div>

          <div className={`box-right d-flex ${courseDetail?.certificate ? `justify-content-start` : `justify-content-center`} mt-4`}>
            {
              courseDetail?.certificate &&
              <button
                type="button"
                className="add-cerft me-3"
                onClick={handleShowPreviewCert}
              >
                View Certificate
              </button>
            }
            <button
              type="button"
              className="add-cerft"
              onClick={handleShowView}
            >
              {courseDetail?.certificate ? "Edit" : "Add"} Certificate
            </button>
          </div>
        </div>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
          </Modal.Header>
          <Modal.Body className="text-center">
            <p>Where would you like to paste your students name?</p>
            <div className="pop-img-add"></div>
          </Modal.Body>
          <Modal.Footer className="mb-4">
            <Button
              variant="secondary"
              className="pop-btn-black"
              type="button"
              onClick={handleShowStamp}
            >
              Next
            </Button>
            <Button variant="primary" className="pop-btn-theme" type="button">
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showStamp} onHide={handleCloseStamp}>
          <Modal.Header closeButton>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
          </Modal.Header>
          <Modal.Body className="text-center">
            <p>Where would you like to paste the date stamp?</p>
            <div className="pop-img-add"></div>
          </Modal.Body>
          <Modal.Footer className="mb-4">
            <Button
              variant="secondary"
              className="pop-btn-black"
              type="button"
              onClick={handleShowView}
            >
              Next
            </Button>
            <Button variant="primary" className="pop-btn-theme" type="button">
              Delete
            </Button>
          </Modal.Footer>
        </Modal>


        {/* Modal Edit or Add Certificate */}

        <Modal
          show={showView}
          onHide={handleCloseView}
          className="document-popup"
        >
          <Modal.Header closeButton>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
          </Modal.Header>
          <Modal.Body className="text-center">
            {certStep == 1 && (
              <p>Where would you like to paste your students name?</p>
            )}
            {certStep == 2 && (
              <p>Where would you like to paste the date stamp?</p>
            )}
            <Formik
              enableReinitialize={true}
              initialValues={{
                certificate: courseDetail?.certificate || "",
                certificate_x_axis: courseDetail?.certificate_x_axis || 0,
                certificate_y_axis: courseDetail?.certificate_y_axis || 0,
                certificate_x_axis2: courseDetail?.certificate_x_axis2 || 0,
                certificate_y_axis2: courseDetail?.certificate_y_axis2 || 0,
              }}
              onSubmit={async (values, { setSubmitting, resetForm }) => { }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <form
                  onSubmit={(e) => {
                    // setValidateValue(true);
                    handleSubmit(e);
                  }}
                >
                  <div className="pop-img-view">
                    <div className="video-inner add-only-four px-0">
                      {/* Input for file selection */}
                      {(selectedFile || values?.certificate) && (
                        <button
                          onClick={() => {
                            setSelectedFile(null);
                            setFieldValue("certificate", "");
                            setFieldValue("certificate_x_axis", 80);
                            setFieldValue("certificate_y_axis", -150);
                            setFieldValue("certificate_x_axis2", 20);
                            setFieldValue("certificate_y_axis2", -100);
                            setCertStep(0);
                          }}
                          className="close-icon"
                        >
                          <img src={require("../images/close.png")} />
                        </button>
                      )}

                      {selectedFile || values?.certificate ? (
                        <DndProvider backend={HTML5Backend}>
                          <Example
                            courseDetail={courseDetail}
                            handleCloseView={handleCloseView}
                            handleSubmit={handleSubmit}
                            ref={childRef}
                            setSelectedFile={setSelectedFile}
                            setFieldValue={setFieldValue}
                            certStep={certStep}
                            values={values}
                            selectedFile={selectedFile}
                            type={courseDetail?.certificate ? "Edit" : "Add"}
                          />
                        </DndProvider>
                      ) : (
                        <div className="no-image-sec">
                          <p>No Certificate Added</p>
                        </div>
                      )}
                    </div>
                  </div>
                  {selectedFile || values?.certificate ? (
                    <>
                      {certStep == 2 ? (
                        <Button
                          variant="secondary"
                          className="pop-btn-black doc-btn mb-3"
                          //type="submit"
                          onClick={() => callChildMethod(handleSubmit)}
                        >
                          Submit
                        </Button>
                      ) : (
                        <Button
                          variant="secondary"
                          className="pop-btn-black doc-btn mb-3"
                          type="button"
                          onClick={() => setCertStep((prev) => prev + 1)}
                        >
                          Next
                        </Button>
                      )}

                      <Button
                        variant="danger"
                        className="pop-btn-black doc-btn mb-3"
                        type="button"
                        onClick={() => setCertStep((prev) => prev - 1)}
                      >
                        Previous
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        variant="secondary"
                        className="pop-btn-black doc-btn mb-3"
                        type="button"
                      >
                        {" "}
                        <input
                          className="form-control"
                          type="file"
                          id="formFile"
                          // accept=".pdf"
                          onChange={(e) => handleFileChange(e, setFieldValue)}
                        />
                        Upload Certificate
                      </Button>
                      <p>
                        * Certificates should be in Portrait Orientation <br />
                        up to 523 x 545 px
                      </p>
                    </>
                  )}
                </form>
              )}
            </Formik>
          </Modal.Body>
          <Modal.Footer className="mb-4"></Modal.Footer>
        </Modal>



        {/* PreView Certificate */}
        <Modal
          show={showPreviewCert}
          onHide={handleClosePreviewCert}
          className="document-popup"
        >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body className="text-center">
            <Formik
              enableReinitialize={true}
              initialValues={{
                certificate: courseDetail?.certificate || "",
                certificate_x_axis: courseDetail?.certificate_x_axis || 0,
                certificate_y_axis: courseDetail?.certificate_y_axis || 0,
                certificate_x_axis2: courseDetail?.certificate_x_axis2 || 0,
                certificate_y_axis2: courseDetail?.certificate_y_axis2 || 0,
              }}
              onSubmit={async (values, { setSubmitting, resetForm }) => { }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <form
                  onSubmit={(e) => {
                    handleSubmit(e);
                  }}
                >
                  <div className="pop-img-view">
                    <div className="video-inner add-only-four px-0">
                      {selectedFile || values?.certificate ? (
                        <DndProvider backend={HTML5Backend}>
                          <Example
                            courseDetail={courseDetail}
                            handleCloseView={handleCloseView}
                            handleSubmit={handleSubmit}
                            ref={childRef}
                            setSelectedFile={setSelectedFile}
                            setFieldValue={setFieldValue}
                            certStep={certStep}
                            values={values}
                            selectedFile={selectedFile}
                            type={courseDetail?.certificate ? "Edit" : "Add"}
                          />
                        </DndProvider>
                      ) : (
                        <div className="no-image-sec">
                          <p>No Certificate Added</p>
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </Modal.Body>
          <Modal.Footer className="mb-4"></Modal.Footer>
        </Modal>

      </Container>
    </CreatorLayout>
  );
}
