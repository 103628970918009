import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Form, Link } from "react-router-dom";
// import ProgressBar from "@ramonak/react-progress-bar";
import Layout from "../elements/Layout";
import Navbar from "react-bootstrap/Navbar";
import SettingLayout from "../elements/SettingLayout";

export default function SettingsSecurity() {
  const [file, setFile] = useState();
  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  return (
    <SettingLayout>
      <Container>
        <div className="profile-detail-box">
          <div className="course-top-box">
            <div className="box-left">
              <h2>Password & Security</h2>
              <p>Update your password or delete your account</p>
            </div>
            <div className="box-right">
              <p>Megan Bulmer</p>
              <span>Member Since: 12/06/2023</span>
            </div>
          </div>
          <div className="sign-form-inner mt-3">
            <form>
              <div class="form-group">
                <label for="exampleInputEmail1">Old Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Type your email here..."
                />
              </div>
              <div class="form-group mt-4 pt-2">
                <label>New Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="********"
                />
              </div>
              <div class="form-group mt-4 pt-2">
                <label>Confirm Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="********"
                />
              </div>
              <Link to="/confirm-account" type="submit" class="common-btn">
                UPDATE
              </Link>
            </form>
          </div>
          <hr />

          <div className="setting-delete-box">
            <h2>Delete Accounty</h2>
            <p>
              Once the account is deactivated, your account will not be able{" "}
              <br />
              to recovered again
            </p>
            <Link to="#" type="submit" class="common-btn">
              DELETE ACCOUNT
            </Link>
          </div>
        </div>
      </Container>
    </SettingLayout>
  );
}
