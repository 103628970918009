import { Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  resendOtp,
  updateEmail,
  verifyOtp,
} from "../../redux/actions/userAuth";
import toast from "react-hot-toast";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const UpdateEmail = ({ onNext }) => {
  const navigate = useNavigate();
  let query = useQuery();
  let userId = query.get("id");
  const dispatch = useDispatch();

  return (
    <>
      <h2 className="h-22">Made a mistake?</h2>

      <span>Please rewrite email for further process.</span>
      <Formik
        initialValues={{
          email: "",
        }}
        validate={(values) => {
          const errors = {};

          if (!values.email) {
            errors.email = "Required*";
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          values.id = userId;

          dispatch(updateEmail(values)).then((data) => {
            if (data?.payload?.success) {
              resetForm();
              toast.success(data?.payload?.message);

              onNext(values, 3);
              window.location.reload();
            }
            setSubmitting(false);
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form
            onSubmit={(e) => {
              // setValidateValue(true);
              handleSubmit(e);
            }}
          >
            <div class="form-group mt-4 pt-2">
              <label>Email</label>
              <input
                type="eamil"
                className="form-control"
                aria-describedby="emailHelp"
                placeholder="Enter Email"
                name="email"
                onChange={handleChange}
                value={values.email}
              />
              <span className="formik-errors">
                {errors.email && touched.email && errors.email}
              </span>
            </div>

            <button type="submit" class="common-btn">
              CONTINUE
            </button>
          </form>
        )}
      </Formik>
    </>
  );
};

export default UpdateEmail;
