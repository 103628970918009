import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import { resetPassword } from "../redux/actions/userAuth";
import { Container, Navbar } from "react-bootstrap";

const ResetPassword = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [validateValue, setValidateValue] = useState(false);

  const validate = (values) => {
    const errors = {};
    // if (!values.email) {
    //   errors.email = "Required*";
    // }
    if (!values.password) {
      errors.password = "Required";
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = "Required";
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = "Passwords doesn't match";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      //email: "",
      password: "",
      confirmPassword: "",
    },
    validate,
    validateOnChange: validateValue,
    onSubmit: async (values) => {
      setValidateValue(true);
      const dataVal = {
        //email: values.email,
        password: values.password,
        confirmPassword: values.confirmPassword,
        token: token,
      };

      console.log(dataVal, "dataVal");

      dispatch(resetPassword(dataVal)).then((data) => {
        if (data?.payload?.success) {
          toast.success(data?.payload?.message);
          navigate("/SignIn");
        } else {
          toast.error(data?.payload?.message);
        }
      });
    },
  });

  return (
    <>
      {/* Signup flow Start */}
      <Navbar expand="lg" className="bg-body-tertiary landing-nav">
        <Container fluid>
          <Navbar.Brand href="#home" className="logo-main-nav">
            <img src={require("../images/logo-mian.png")} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          {/* <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="#home">Home</Nav.Link>
              <Nav.Link href="#link">Contact Us</Nav.Link>
            </Nav>
            <div className="main-login-btn">
              <Link to="/SignIn">Login</Link>
            </div>
          </Navbar.Collapse> */}
        </Container>
      </Navbar>
      <div className="sign-forms-cmn-box">
        <div className="sign-form-inner">
          <h2 className="RecifeDisplay common-heading">Forgot Password</h2>

          <form onSubmit={formik.handleSubmit}>
            {/* <div className="logo-circle">
                            <img
                              src={require("../assets/img/logo-circle.png")}
                              alt="logo"
                            />
                          </div> */}

            {/* <div className="login-input mt-4">
                      <label>Enter Mail</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Email *"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.email && (
                        <span className="formik-errors">
                          {formik.errors.email}
                        </span>
                      )}
                    </div> */}
            <div className="login-input mt-4">
              <label>Enter Password</label>
              <input
                type="password"
                className="form-control"
                placeholder="Enter Password *"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
              />
              {formik.errors.password && (
                <span className="formik-errors">{formik.errors.password}</span>
              )}
            </div>
            <div className="login-input mt-4">
              <label>Re Enter Password</label>
              <input
                type="password"
                className="form-control"
                placeholder="Re Enter Password *"
                name="confirmPassword"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
              />
              {formik.errors.confirmPassword && (
                <span className="formik-errors">
                  {formik.errors.confirmPassword}
                </span>
              )}
            </div>

            <div className="login-btn">
              <button type="submit" className="common-btn">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* Signup flow End */}
    </>
  );
};
export default ResetPassword;
