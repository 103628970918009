import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getProductList } from "../../redux/actions/userAuth";

const MultiProductSelect = ({ setFieldValue, country }) => {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => ({
    products: state.userMgmtSlice.products,
  }));
  const [selectedCountry, setSelectedCountry] = useState(null);

  const handleChange = (selectedOption) => {
    console.log(selectedOption);
    const arr = selectedOption?.map((item) => {
      return item?.value;
    });
    setSelectedCountry(selectedOption);
    setFieldValue("products", arr);
  };
  useEffect(() => {
    setSelectedCountry({ label: country, value: country });
  }, [country]);

  useEffect(() => {
    dispatch(getProductList());
  }, []);

  const allproducts = products?.map((item) => {
    return { label: item?.name, value: item?._id };
  });
  return (
    <Select
      isMulti
      defaultValue={selectedCountry}
      onChange={handleChange}
      options={allproducts}
      placeholder="Select a product"
    />
  );
};

export default MultiProductSelect;
