import React, { useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Form, Link, useNavigate } from "react-router-dom";

import Navbar from "react-bootstrap/Navbar";
import { Formik } from "formik";
import { forgotPassword } from "../redux/actions/userAuth";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [validateValue, setValidateValue] = useState(false);

  return (
    <div>
      <Navbar expand="lg" className="bg-body-tertiary landing-nav">
        <Container fluid>
          <Navbar.Brand href="#home" className="logo-main-nav">
            <img src={require("../images/logo-mian.png")} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          {/* <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="#home">Home</Nav.Link>
              <Nav.Link href="#link">Contact Us</Nav.Link>
            </Nav>
            <div className="main-login-btn">
              <Link to="/SignIn">Login</Link>
            </div>
          </Navbar.Collapse> */}
        </Container>
      </Navbar>
      <Container>
        <div className="sign-forms-cmn-box">
          <div className="sign-form-inner">
            <h2 className="h-22">Forgot Your Password?</h2>

            <span>
              Type your email below and we’ll send
              <br /> you a reset password link.
            </span>

           <Formik
              initialValues={{
                email: "",
              }}
              validate={(values) => {
                const errors = {};

                if (!values.email) {
                  errors.email = "Required";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                    values.email
                  )
                ) {
                  errors.email = "Invalid email address";
                }

                return errors;
              }}
              validateOnChange={validateValue}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setValidateValue(true);
                values.type=1
                dispatch(forgotPassword(values)).then((data) => {
                  if (data?.payload?.success) {
                    resetForm();
                    toast.success(data?.payload?.message);
                    navigate(`/verifyOtp/${data?.payload?.data?._id}`);
                  } else {
                    toast.error(data?.payload?.message);
                  }
                });
                setSubmitting(false);
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
              <div class="form-group">
                <label>Email</label>
                <input
                   type="email"
                   className="form-control"
                   placeholder="Enter Email *"
                   name="email"
                   onChange={handleChange}
                   value={values.email}
                />
                {errors.email && (
                            <span className="formik-errors">
                              {errors.email}
                            </span>
                          )}
              </div>
              <button type="submit" class="common-btn">
                SEND
              </button>
              </form>
              )}
            </Formik>
          </div>
        </div>
      </Container>
    </div>
  );
}
