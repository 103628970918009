import { Navigate, Outlet } from "react-router-dom";
const PublicRoute = () => {
  const adminToken = window.localStorage.getItem("accessToken");
  return (
    !adminToken ? <Outlet /> : <Navigate to='/home' />
    )
};
export default PublicRoute;



