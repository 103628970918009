import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Form, Link } from "react-router-dom";
// import ProgressBar from "@ramonak/react-progress-bar";
import Navbar from "react-bootstrap/Navbar";
import CreatorLayout from "../elements/CreatorLayout";
import { Formik } from "formik";
import { getUserById, userEditProfile } from "../redux/actions/userAuth";
import { useDispatch, useSelector } from "react-redux";
import useAuthToken from "../Hooks/useAuthToken";
import toast from "react-hot-toast";
import moment from "moment";
export default function SettingsAdditional() {
  const [imagePreviews, setImagePreviews] = useState([]);
  const [file, setFile] = useState();
  const dispatch = useDispatch();
  const tokenData = useAuthToken();
  const { getRegisterUser } = useSelector((state) => ({
    getRegisterUser: state.userMgmtSlice.getRegisterUser,
  }));
  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  useEffect(() => {
    dispatch(getUserById(tokenData?._id));
  }, []);

  const handleAddImageBox = (setFieldValue, values) => {
    if (values?.gallery?.length < 4) {
      setImagePreviews((prevState) => [...prevState, ""]);
      setFieldValue("gallery", [...values.gallery, ""]);
    } else {
      return toast.error("You can upload max 4 images.");
    }
  };
  return (
    <CreatorLayout>
      <Container>
        <div className="profile-top-box">
          <div className="box-left">
            <h2>Profile</h2>
            <p>Edit your Personal Details</p>
          </div>
          <div className="box-right">
            <p>{getRegisterUser?.full_name}</p>
            <span>
              Member Since:{" "}
              {moment(getRegisterUser?.createdAt).format("DD/MM/YYYY")}
            </span>
          </div>
        </div>
        <div className="sign-forms-cmn-box">
          <div className="profile-s-step">
            <ul>
              <li>
                <Link to="/settings-profile">Profile Details</Link>
              </li>
              <li>
                <Link to="/settings-bio">Bio</Link>
              </li>
              <li>
                <Link to="/settings-expertise">Your Expertise</Link>
              </li>
              <li>
                <Link to="/settings-additional" className="active">
                  Additional Images
                </Link>
              </li>
            </ul>
          </div>

          <div className="sign-form-inner">
            <Formik
              enableReinitialize={true}
              initialValues={{
                gallery: (getRegisterUser?.gallery?.length &&
                  getRegisterUser?.gallery) || [""],
                webtype: 1,
              }}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                console.log(values, "prew----imng");
                values.id = tokenData?._id;
                const formData = new FormData();
                for (let key in values) {
                  if (key != "gallery") formData.append(key, values[key]);
                }
                values.gallery?.forEach((file, index) => {
                  formData.append(`gallery`, file);
                });
                dispatch(userEditProfile(formData)).then((data) => {
                  if (data?.payload?.success) {
                    // resetForm();
                    toast.success(data?.payload?.message);
                    window.location.reload();
                  }
                  setSubmitting(false);
                });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <form
                  onSubmit={(e) => {
                    // setValidateValue(true);
                    handleSubmit(e);
                  }}
                >
                  <div class="form-group mb-4">
                    <div className="add-addit">
                      <button
                        type="button"
                        onClick={() => handleAddImageBox(setFieldValue, values)}
                        className="add-icon"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="25"
                          viewBox="0 0 25 25"
                          fill="none"
                        >
                          <path
                            d="M12.5 6.25L12.5 18.75"
                            stroke="#8B8B8B"
                            stroke-width="2"
                            stroke-linecap="square"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M18.75 12.5L6.25 12.5"
                            stroke="#8B8B8B"
                            stroke-width="2"
                            stroke-linecap="square"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                    </div>

                    <div className="img-upload-additional mt-4">
                      {/* <div 
                      className="upload-img">
                    </div> */}

                      {/* <img className="show-img" src={file} /> */}
                      {values.gallery?.length &&
                        values.gallery.map((expertise, index) => (
                          <div key={index} className="additional-img-upload">
                            <div className="form-group pt-2 mb-3">
                              <input
                                type="file"
                                name={`gallery.${index}`}
                                onChange={(e) => {
                                  setImagePreviews((prevState) => {
                                    const newPreviews = [...prevState];
                                    newPreviews[index] = URL.createObjectURL(
                                      e.target.files[0]
                                    );
                                    return newPreviews;
                                  });
                                  setFieldValue(
                                    `gallery.${index}`,
                                    e.target.files[0]
                                  );
                                }}
                                className="form-control"
                                placeholder={`${index + 1}.`}
                              />
                            </div>
                            <div key={index} className="form-group pt-2 mb-3">
                              <img
                                className="show-img"
                                src={
                                  imagePreviews[index]
                                    ? imagePreviews[index]
                                    : expertise
                                }
                              />
                              <p>
                                <b>Upload Image</b>
                              </p>
                              <p className="dimension-label">
                                <b>400 * 200 (upto 1024kb)</b>
                              </p>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                  <button to="#" type="submit" class="common-btn">
                    UPDATE
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </Container>
    </CreatorLayout>
  );
}
