import { Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { resendOtp, verifyOtp } from "../../redux/actions/userAuth";
import toast from "react-hot-toast";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Otp = ({ onNext, getRegisterUser }) => {
  const navigate = useNavigate();
  let query = useQuery();
  let userId = query.get("id");
  const dispatch = useDispatch();
  const handleResendOtp = () => {
    dispatch(resendOtp({ user_id: userId, type: 1 })).then((data) => {
      if (data?.payload?.success) {
        toast.success(data?.payload?.message);
      }
    });
  };
  return (
    <>
      <h2 className="h-22">Confirm Your Account</h2>

      <span>
        We’ve sent a one time code to your {getRegisterUser?.email} Please enter
        it below to continue.
      </span>
      <Formik
        initialValues={{
          otp: "",
          user_id: "",
          type: 1,
        }}
        validate={(values) => {
          const errors = {};

          if (!values.otp) {
            errors.otp = "Required*";
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          values.user_id = userId;
          dispatch(verifyOtp(values)).then((data) => {
            if (data?.payload?.success) {
              resetForm();
              toast.success(data?.payload?.message);
              // localStorage.setItem("accessToken", data?.payload?.data?.token);
              // console.log(data?.payload?.data?.token);
              // navigate(
              //   `/sign-up?id=${data?.payload?.data?._id}&token=${data?.payload?.data?.token}`
              // );
              navigate(
                `/sign-up?id=${data?.payload?.data?._id}&accessToken=${data?.payload?.data?.token}`
              )
              onNext(values);
            }
            setSubmitting(false);
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form
            onSubmit={(e) => {
              // setValidateValue(true);
              handleSubmit(e);
            }}
          >
            <div class="form-group mt-4 pt-2">
              <label>OTP</label>
              <input
                type="password"
                className="form-control"
                aria-describedby="emailHelp"
                placeholder="****"
                name="otp"
                onChange={handleChange}
                value={values.otp}
              />
              <span className="formik-errors">
                {errors.otp && touched.otp && errors.otp}
              </span>
            </div>

            <div className="confirm-links mt-4">
              <span>Not Received?</span>
              <p style={{ cursor: "pointer" }} onClick={handleResendOtp}>
                Send Code Again
              </p>
              <span>Made a Mistake?</span>
              {/* <p>Update Email</p> */}
              <div className="mt-2">
                <button type="button" onClick={() => onNext({}, 6)}>
                  Update Email
                </button>
              </div>
            </div>
            <button type="submit" class="common-btn">
              CONTINUE
            </button>
          </form>
        )}
      </Formik >
    </>
  );
};

export default Otp;
