import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { getStatics, userEditProfile } from '../../redux/actions/userAuth';
import { Formik } from 'formik';
import toast from 'react-hot-toast';

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}
const YourInterests = ({ onNext }) => {
    let query = useQuery();
    let accessToken = query.get("accessToken");
    const dispatch = useDispatch();
    const { statics } = useSelector((state) => ({
        statics: state.userMgmtSlice.statics
    }));
    const handleNext = () => {
        onNext({});
    };

    useEffect(() => {
        dispatch(getStatics());
    }, []);

    console.log(statics, "statics");
    return (
        <>
            <h2 className="h-22">Tell us Your Interests</h2>

            <span>
                What kind of tutorials would you like to see in Buff Education?
                We’ll tailer your viewing experience accordingly
            </span>
            <Formik
                initialValues={{
                    tages: [],
                    courses: []
                }}
                validate={(values) => {
                    const errors = {};

                    if (!values.courses || values.courses.length === 0) {
                        errors.courses = "At least one interest must be selected";
                    }
                    if (!values.tages || values.tages.length === 0) {
                        errors.tages = "At least one tag must be selected";
                    }

                    return errors;
                }}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    values.token = accessToken;
                    console.log("values interest", values)
                    let res = await dispatch(userEditProfile(values, accessToken))
                    if (res) {
                        if (res?.payload?.success) {
                            resetForm();
                            toast.success(res?.payload?.message);
                            onNext(values)
                        }

                        setSubmitting(false);
                        console.log("data res-------", res)
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                }) => (
                    <form
                        onSubmit={(e) => {
                            // setValidateValue(true);
                            handleSubmit(e);
                        }}
                    >
                        <div className="select-inters-box d-flex">
                            {
                                statics?.coursecategories?.map((item) => {
                                    return (
                                        <div class="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="courses"
                                                id={item._id}
                                                value={item._id}
                                                checked={values.courses.includes(
                                                    item._id
                                                )}
                                                onChange={handleChange}
                                            />
                                            <label className="form-check-label" for="flexCheckDefault">
                                                {item?.title}
                                            </label>

                                        </div>

                                    )
                                })
                            }

                            <span className="formik-errors">
                                {errors.courses &&
                                    touched.courses &&
                                    errors.courses}
                            </span>
                        </div>
                        <div className="confirm-links mt-4">
                            <p>Refine your interests</p>
                        </div>

                        <div className="select-inters-box">
                            <ul>
                                {statics?.courseTagslists?.filter(tag => values?.courses?.includes(tag.coursecategory))?.map((item) => {
                                    return (
                                        <>
                                            <div class="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name="tages"
                                                    id={item._id}
                                                    value={item._id}
                                                    checked={values?.tages?.includes(
                                                        item._id
                                                    )}
                                                    onChange={handleChange}
                                                />
                                                <label className="form-check-label" for="flexCheckDefault">
                                                    {item?.title}
                                                </label>

                                            </div>

                                        </>
                                    )
                                })}

                            </ul>
                            <span className="formik-errors">
                                {errors.tages &&
                                    touched.tages &&
                                    errors.tages}
                            </span>
                        </div>

                        <button type="submit" class="common-btn">
                            CONTINUE
                        </button>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default YourInterests;