import ProgressBar from '@ramonak/react-progress-bar'
import React from 'react'

const BackgroundLoader = ({ prog, hide, toggle, video_title, setToggle, uploadTime, uploadSuccess }) => {
    console.log("compo","prog", prog, "hide", hide, "toggle", toggle, "video_title", video_title, "uploadTime", uploadTime, "uploadSuccess", uploadSuccess)
    return (
        <>
        <div>
            Upload Loader here {prog}
        </div>
            {prog != 0 && !hide && (
                <div className={toggle ? "chapter-upload" : "chapter-upload hide"}>
                    <h6>{video_title}</h6>
                    <button className="close-btn" onClick={() => setToggle(!toggle)}>
                        <img src={require("../images/arrow-left.svg").default} />
                    </button>
                    <div className="chapter-upload-header">
                        <h6>
                            {uploadSuccess
                                ? "Video Uploaded Successfully."
                                : "Uploading Video"}
                        </h6>

                        {/* <button><img src={require("./images/cancelbtn.svg").default}/></button> */}
                    </div>

                    <ProgressBar
                        completed={prog}
                        bgColor={uploadSuccess ? "#2b742b" : "black"}
                    />
                    <p>
                        Estimate Time Remaining : <b>{uploadTime}</b>
                    </p>
                    <h3>{prog?.originalname}</h3>
                    {/* {uploadSuccess == 1 && <img src={require("./images/check.png")} />} */}
                    {/* {
            hide && <div className="d-block text-center pb-2"> <p>UNDER PROGRESS</p></div>
          } */}
                </div>
            )}
        </>
    )
}

export default BackgroundLoader