import React, { useState } from "react";
import SettingSidebar from "./SettingSidebar";
import { Container } from "react-bootstrap";
import TopBar from "./TopBar";

const SettingLayout = ({ children }) => {
  const [isActive, setActive] = useState(false);

  return (
    <>
      <section className="cmn-side">
        <Container fluid className="px-0">
          <div className={isActive ? "hide main" : "main"}>
            <div>
              <SettingSidebar />
            </div>
            <div className="right-side">
              {" "}
              <TopBar setActive={setActive} isActive={isActive} />
              {children}
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default SettingLayout;
