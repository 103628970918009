import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Form, Link } from "react-router-dom";
// import ProgressBar from "@ramonak/react-progress-bar";
import Layout from "../elements/Layout";
import Navbar from "react-bootstrap/Navbar";
import SettingLayout from "../elements/SettingLayout";

export default function SettingsPreference() {
  const [file, setFile] = useState();
  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  return (
    <SettingLayout>
      <Container>
        <div className="profile-detail-box">
          <div className="course-top-box">
            <div className="box-left">
              <h2>Contact Preference</h2>
              <p>Set your contact preferences here.</p>
            </div>
            <div className="box-right">
              <p>Megan Bulmer</p>
              <span>Member Since: 12/06/2023</span>
            </div>
          </div>
          <div className="invoice-list-box">
            <div className="promotional-emails-box">
              <h2>Promotional Emails</h2>
              <div className="promotional-emails-check">
                <p>
                  {" "}
                  I’d like to receive offers and promotions from Buff Browz and
                  partners via email
                </p>
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
              </div>
            </div>
            <div className="promotional-emails-box">
              <h2>Push Notifications</h2>
              <div className="promotional-emails-check">
                <p>
                  {" "}
                  I’d like to receive offers and promotions from Buff Browz and
                  partners via email
                </p>
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
              </div>
            </div>
            <div className="promotional-emails-box">
              <h2>Push Emails</h2>
              <div className="promotional-emails-check">
                <p>
                  {" "}
                  I’d like to receive offers and promotions from Buff Browz and
                  partners via email
                </p>
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </SettingLayout>
  );
}
