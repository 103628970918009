import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import useAuthToken from "../Hooks/useAuthToken";
import { useDispatch, useSelector } from "react-redux";
import { getUserById } from "../redux/actions/userAuth";
import { autoSearchResults, getAllCourses, searchResults } from "../redux/actions/userAction";
import { Link, useNavigate } from "react-router-dom";

const pages = [
  "/home",
  "/channels",
  "/creators",
  "/my-courses",
  "/likes-courses",

  "/playback",
  "/quiz",
  "/questions",
  "/downloads",
  "/playback-Products",
  "/channels-details",
  "/course-overview",
  "/course-overview-products",
  "/course-overview-paid",
];

export default function TopBar({
  setActive,
  isActive,
  setActiveNew,
  isActiveNew,
}) {
  const navigate = useNavigate();
  const tokenData = useAuthToken();
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const [show, setShow] = useState(false);
  const [searchTxt, setSearchTxt] = useState("");
  const [autoSearch, setAutoSearch] = useState([]);
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
  const [isFocused, setIsFocused] = useState(false);
  const [isBlur, setIsBlur] = useState(false);
  const { getRegisterUser } = useSelector((state) => ({
    getRegisterUser: state.userMgmtSlice.getRegisterUser,
  }));
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const toggleClass = () => {
    setActive(!isActive);
  };

  const toggleClassNew = () => {
    setActiveNew(!isActiveNew);
  };

  useEffect(() => {
    dispatch(getUserById(tokenData?._id));
  }, []);

  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = "/";
  };

  useEffect(() => {
    //dispatch(getAllCourses({ search: searchTxt }));
    if (searchTxt.length >= 2) {
      dispatch(autoSearchResults({ search: searchTxt })).then((data) => {
        console.log(data, "data");
        if (data?.payload?.data?.length > 0) {
          setAutoSearch(data?.payload?.data);
        } else {
          setAutoSearch([])
        }
      });
    }
    setQuery(searchTxt);
  }, [searchTxt]);

  const getHighlightedText = (text, highlight) => {
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return <span>{parts.map((part, i) =>
      <span key={i} className={part.toLowerCase() === highlight.toLowerCase() ? 'highlight' : ''}>
        {part}
      </span>)
    }</span>;
  };

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowUp' && selectedItemIndex > 0) {
      setSelectedItemIndex(selectedItemIndex - 1);
    } else if (e.key === 'ArrowDown' && selectedItemIndex < autoSearch?.length - 1) {
      setSelectedItemIndex(selectedItemIndex + 1);
    } else if (e.key === 'Enter' && selectedItemIndex !== -1) {
      setQuery(autoSearch[selectedItemIndex]);
      setIsFocused(false);
    }
  };

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    console.log(isBlur, "isBlur");
    setIsFocused(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      navigate(`/search-results?search=${query}`)
    }
  };

  const handleSearchClick = (query) => {

    navigate(`/search-results?search=${query}`)
  }


  console.log(query, "query");

  return (
    <Container fluid>
      <div className={isActive ? "cmn-top-bar phone-add" : "cmn-top-bar"}>
        <Row>
          <Col
            lg={4}
            md={4}
            sm={4}
            xs={2}
            className="d-flex align-items-center"
          >
            <button type="button" className="toggle-btn" onClick={toggleClass}>
              <img src={require("../images/Toggle.svg").default} />
            </button>
            <div className="logo-main">
              <Link to="/home">
                <img src={require("../images/logo-mian.png")} />
              </Link>
            </div>
            <button
              type="button"
              className={
                pages.includes(window.location.pathname)
                  ? "toggle-btn-new d-none"
                  : "toggle-btn-new"
              }
              onClick={toggleClassNew}
            >
              <img src={require("../images/dotsmenu.svg").default} />
            </button>
          </Col>
          <Col
            lg={5}
            md={6}
            sm={6}
            xs={7}
            className="d-flex justify-content-center align-items-center"
          >
            <div className="search-field">
              <div className="form-group">
                <input
                  name="search"
                  type="search"
                  className="form-control"
                  placeholder="Search"
                  onChange={(e) => setSearchTxt(e.target.value)}
                  onKeyDown={handleKeyDown}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  onKeyPress={handleKeyPress}
                  value={query}
                  autoComplete="off"
                />
                <button onClick={() => handleSearchClick(query)}>
                  <img src={require("../images/search.svg").default} />
                </button>
              </div>
              {
                isFocused && Array.isArray(autoSearch) && autoSearch?.length > 0 && <ul>
                  {autoSearch?.map((item, index) => (
                    <>
                      {
                        index < 10 && <li
                          onMouseDown={() => {
                            setQuery(autoSearch[index]);
                            handleSearchClick(autoSearch[index])
                          }} className={index === selectedItemIndex ? 'selected' : ''} key={index}>
                          <img src={require("../images/search.svg").default} />
                          {getHighlightedText(item, query)}
                        </li>
                      }

                    </>
                  ))
                  }
                </ul>
              }

            </div>

          </Col>
          <Col
            lg={3}
            md={2}
            sm={2}
            xs={3}
            className="d-flex justify-content-end"
          >
            <div className="search-right-sec">
              {/* <button type="button" onClick={handleShow}>
                <img
                  className="bell-img"
                  src={require("../images/bell.svg").default}
                />
              </button> */}
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <img
                    className="profile-img"
                    src={
                      getRegisterUser?.profile_image
                        ?
                        getRegisterUser?.profile_image
                        : require("../images/profile.svg").default
                    }
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={handleLogout} href="#">
                    Log Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Col>
        </Row>
      </div>
      <Modal show={show} onHide={handleClose} className="notifications-popup">
        <Modal.Header closeButton>
          <Modal.Title>Notifications</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="notifications-show">
            <div className="single-notification">
              <p>This is a sample notification! Sample Notification.</p>
              <span>3 Days Ago</span>
            </div>
            <div className="single-notification">
              <p>This is a sample notification! Sample Notification.</p>
              <span>3 Days Ago</span>
            </div>
            <div className="single-notification">
              <p>This is a sample notification! Sample Notification.</p>
              <span>3 Days Ago</span>
            </div>
            <div className="single-notification">
              <p>This is a sample notification! Sample Notification.</p>
              <span>3 Days Ago</span>
            </div>
            <div className="single-notification">
              <p>This is a sample notification! Sample Notification.</p>
              <span>3 Days Ago</span>
            </div>
            <div className="single-notification">
              <p>This is a sample notification! Sample Notification.</p>
              <span>3 Days Ago</span>
            </div>
            <div className="single-notification">
              <p>This is a sample notification! Sample Notification.</p>
              <span>3 Days Ago</span>
            </div>
            <div className="single-notification">
              <p>This is a sample notification! Sample Notification.</p>
              <span>3 Days Ago</span>
            </div>
            <div className="single-notification">
              <p>This is a sample notification! Sample Notification.</p>
              <span>3 Days Ago</span>
            </div>
            <div className="single-notification">
              <p>This is a sample notification! Sample Notification.</p>
              <span>3 Days Ago</span>
            </div>
            <div className="single-notification">
              <p>This is a sample notification! Sample Notification.</p>
              <span>3 Days Ago</span>
            </div>
            <div className="single-notification">
              <p>This is a sample notification! Sample Notification.</p>
              <span>3 Days Ago</span>
            </div>
            <div className="single-notification">
              <p>This is a sample notification! Sample Notification.</p>
              <span>3 Days Ago</span>
            </div>
            <div className="single-notification">
              <p>This is a sample notification! Sample Notification.</p>
              <span>3 Days Ago</span>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Container>
  );
}
