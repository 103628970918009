import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Form, Link } from "react-router-dom";
// import ProgressBar from "@ramonak/react-progress-bar";
import Navbar from "react-bootstrap/Navbar";
import CreatorLayout from "../elements/CreatorLayout";
import { Formik } from "formik";
import { getUserById, userEditProfile } from "../redux/actions/userAuth";
import { useDispatch, useSelector } from "react-redux";
import useAuthToken from "../Hooks/useAuthToken";
import toast from "react-hot-toast";
import moment from "moment";
export default function SettingsBio() {
  const dispatch = useDispatch();
  const tokenData = useAuthToken();
  const [file, setFile] = useState();
  const { getRegisterUser } = useSelector((state) => ({
    getRegisterUser: state.userMgmtSlice.getRegisterUser,
  }));
  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  useEffect(() => {
    dispatch(getUserById(tokenData?._id));
  }, []);
  return (
    <CreatorLayout>
      <Container>
        <div className="profile-top-box">
          <div className="box-left">
            <h2>Profile</h2>
            <p>Edit your Personal Details</p>
          </div>
          <div className="box-right">
            <p>{getRegisterUser?.full_name}</p>
            <span>
              Member Since:{" "}
              {moment(getRegisterUser?.createdAt).format("DD/MM/YYYY")}
            </span>
          </div>
        </div>
        <div className="sign-forms-cmn-box">
          <div className="profile-s-step">
            <ul>
              <li>
                <Link to="/settings-profile">Profile Details</Link>
              </li>
              <li>
                <Link to="/settings-bio" className="active">
                  Bio
                </Link>
              </li>
              <li>
                <Link to="/settings-expertise">Your Expertise</Link>
              </li>
              <li>
                <Link to="/settings-additional">Additional Images</Link>
              </li>
            </ul>
          </div>
          <div className="sign-form-inner wider">
            <Formik
              enableReinitialize={true}
              initialValues={{
                bio: getRegisterUser?.bio || "",
              }}
              validate={(values) => {
                const errors = {};

                if (!values.bio) {
                  errors.bio = "Required*";
                }

                return errors;
              }}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                values.id = tokenData?._id;
                const formData = new FormData();
                for (let key in values) {
                  formData.append(key, values[key]);
                }
                dispatch(userEditProfile(formData)).then((data) => {
                  if (data?.payload?.success) {
                    resetForm();
                    toast.success(data?.payload?.message);
                    dispatch(getUserById(tokenData?._id));
                  }
                  setSubmitting(false);
                });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <form
                  onSubmit={(e) => {
                    // setValidateValue(true);
                    handleSubmit(e);
                  }}
                >
                  <div class="form-group mt-4 pt-2 mb-5">
                    <label>Bio</label>
                    <textarea
                      rows={5}
                      type="text"
                      class="form-control"
                      placeholder="Type Here...."
                      name="bio"
                      onChange={handleChange}
                      value={values.bio}
                    />
                    <span className="formik-errors">
                      {errors.bio && touched.bio && errors.bio}
                    </span>
                  </div>
                  <button to="#" type="submit" class="common-btn">
                    UPDATE
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </Container>
    </CreatorLayout>
  );
}
