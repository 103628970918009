import React, { useEffect, useState } from "react";
import { Col, Container, InputGroup } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CreatorLayout from "../elements/CreatorLayout";
import CountrySelect from "../components/CountrySelect/CountrySelect";
import useAuthToken from "../Hooks/useAuthToken";
import { getUserById, userEditProfile } from "../redux/actions/userAuth";
import toast from "react-hot-toast";
import { Field, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import useGetAddressIO from "../Hooks/getAddressIo";
import { accountChangeRequestAction } from "../redux/actions/userAction";

const intialData = {
  full_name: "",
  dob: "",
  phone_number: "",
  address: "",
  post_code: "",
  country: "",
  profile_image: "",
  channel_icon: "",
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function SettingsProfile() {
  const navigate = useNavigate();
  let query = useQuery();
  let accountAdded = query.get("success");
  const dispatch = useDispatch();
  const tokenData = useAuthToken();
  const [file, setFile] = useState();
  const [prevImg, setPrevImg] = useState();
  const [prevImg2, setPrevImg2] = useState();
  const { addressData, getAddress } = useGetAddressIO();
  const [postcode, setPostcode] = useState();

  const [editableData, setEditableData] = useState(intialData);

  const { getRegisterUser } = useSelector((state) => ({
    getRegisterUser: state.userMgmtSlice.getRegisterUser,
  }));

  console.log("addressData----", addressData)
  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  useEffect(() => {
    dispatch(getUserById(tokenData?._id));
  }, []);
  console.log(tokenData, "tokenData");

  useEffect(() => {
    if (getRegisterUser?._id) {
      setEditableData({ ...getRegisterUser, post_code: getRegisterUser.post_code })
    } else {
      setEditableData(intialData)
    }

  }, [getRegisterUser])

  useEffect(() => {
    if (accountAdded == 'true') {
      toast.success("Account added successfully!");
      navigate(`/settings-profile`);
    }
  }, [accountAdded]);

  useEffect(() => {
    if (getRegisterUser?.post_code) {
      handleKeyEnter()
    }
  }, [getRegisterUser?.post_code]);

  const handleKeyEnter = () => {
    if (postcode) {
      getAddress(postcode);
    } else {
      getAddress(getRegisterUser?.post_code);
    }
  };

  const handleSearch = (e, setFieldValue) => {
    const data = addressData?.addresses?.find(
      (element) =>
        element?.formatted_address?.filter((item) => item !== "").join(", ") ==
        e.target.value?.toString()
    );

    setFieldValue("city", data?.town_or_city);
    //setFieldValue("country", data?.country);
    setFieldValue("lat", addressData?.latitude);
    setFieldValue("long", addressData?.longitude);
  };

  console.log("dssfsd", editableData)

  const accountChangeRequest = async () => {
    let res = await dispatch(accountChangeRequestAction())
    if (res?.payload?.success) {
      toast.success(res?.payload?.message)
    } else {
      toast.error(res?.payload?.message)
    }
  }
  return (
    <CreatorLayout>
      <Container>
        <div className="profile-top-box">
          <div className="box-left">
            <h2>Profile</h2>
            <p>Edit your Personal Details</p>
          </div>
          <div className="box-right">
            <p>{getRegisterUser?.full_name}</p>
            <span>
              Member Since:{" "}
              {moment(getRegisterUser?.createdAt).format("DD/MM/YYYY")}
            </span>
          </div>
        </div>
        <div className="sign-forms-cmn-box">
          <div className="profile-s-step">
            <ul>
              <li>
                <Link to="/settings-profile" className="active">
                  Profile Details
                </Link>
              </li>

              {(tokenData?.role == 1 || tokenData?.role == 2) && (
                <>
                  <li>
                    <Link to="/settings-bio">Bio</Link>
                  </li>
                  <li>
                    <Link to="/settings-expertise">Your Expertise</Link>
                  </li>
                  <li>
                    <Link to="/settings-additional">Additional Images</Link>
                  </li>
                </>
              )}
            </ul>
          </div>
          <div
            className={
              tokenData?.role == 2
                ? "sign-form-inner cover-set"
                : "sign-form-inner"
            }
          >
            <Formik
              enableReinitialize={true}
              initialValues={editableData || intialData}
              validate={(values) => {
                const errors = {};

                if (!values.full_name) {
                  errors.full_name = "Required*";
                }
                // if (!values.dob) {
                //   errors.dob = "Required*";
                // }

                // if (!values.phone_number) {
                //   errors.phone_number = "Required*";
                // }
                if (!values.post_code) {
                  errors.post_code = "Required";
                }
                if (!values.address) {
                  errors.address = "Required*";
                }
                if (!values.country) {
                  errors.country = "Required*";
                }

                return errors;
              }}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                values.id = tokenData?._id;
                const formData = new FormData();
                for (let key in values) {
                  if (key == "profile_image") {
                    formData.append(key, values[key]);
                  } else if (key == "channel_icon") {
                    formData.append(key, values[key]);
                  }
                }
                formData.append('full_name', values?.full_name);
                formData.append('dob', values?.dob);
                formData.append('phone_number', values?.phone_number);
                formData.append('address', values?.address);
                formData.append('country', values?.country);
                formData.append('post_code', values?.post_code);
                dispatch(userEditProfile(formData)).then((data) => {
                  if (data?.payload?.status_code == 200) {
                    toast.success(data?.payload?.message);
                    // setTimeout(() => {
                    //   window.location.reload();
                    // }, 1000);
                    dispatch(getUserById(tokenData?._id));
                  } else {
                    toast.error(data?.payload?.message);
                  }
                  setSubmitting(false);
                });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (

                <form
                  onSubmit={(e) => {
                    // setValidateValue(true);
                    handleSubmit(e);
                  }}
                >
                  {tokenData?.role == 2 && (
                    <div class="form-group mb-4">
                      <div className="img-upload-main cover-img">
                        <div className="img-upload">
                          <img
                            className="upload-img"
                            src={require("../images/imagesplaceholder.png")}
                          />
                          <input
                            type="file"
                            name="channel_icon"
                            onChange={(e) => {
                              setPrevImg2(
                                URL.createObjectURL(e.target.files[0])
                              );
                              setFieldValue("channel_icon", e.target.files[0]);
                            }}
                          ></input>
                          {prevImg2 ? (
                            <img className="show-img" src={prevImg2} />
                          ) : (
                            values.channel_icon && (
                              <img
                                className="show-img"
                                src={values.channel_icon}
                              />
                            )
                          )}
                        </div>
                        {prevImg2 == 0 && <p>Add a Cover Image </p>}
                      </div>
                    </div>
                  )}

                  <div
                    class={
                      tokenData?.role == 2
                        ? "form-group mb-4 profile-img-set"
                        : "form-group mb-4"
                    }
                  >
                    <div className="img-upload-main">
                      <div className="img-upload">
                        <img
                          className="upload-img"
                          src={require("../images/imageupload.svg").default}
                        />
                        <input
                          type="file"
                          name="profile_image"
                          onChange={(e) => {
                            setPrevImg(URL.createObjectURL(e.target.files[0]));
                            setFieldValue("profile_image", e.target.files[0]);
                          }}
                        ></input>
                        {prevImg ? (
                          <img className="show-img" src={prevImg} />
                        ) : (
                          values.profile_image && (
                            <img
                              className="show-img"
                              src={values.profile_image}
                            />
                          )
                        )}
                      </div>
                      {/* <p>Add a Profile image</p> */}
                    </div>
                  </div>

                  <div class="form-group mt-4 pt-4">
                    <label for="exampleInputEmail1">{tokenData?.role == 2 ? "Channel Name" : "First Name"}</label>
                    <input
                      type="text"
                      className="form-control"
                      aria-describedby="emailHelp"
                      placeholder="Type your name here..."
                      name="full_name"
                      onChange={handleChange}
                      value={values.full_name}
                    />
                    <span className="formik-errors">
                      {errors.full_name &&
                        touched.full_name &&
                        errors.full_name}
                    </span>
                  </div>
                  {
                    tokenData?.role != 2 &&
                    <>
                      <div class="form-group mt-4 pt-2">
                        <label>Date of Birth</label>
                        <input
                          type="date"
                          class="form-control"
                          placeholder="--/--/----"
                          name="dob"
                          onChange={handleChange}
                          value={values.dob}
                          max={new Date().toISOString().split("T")[0]}
                        />
                        {/* <span className="formik-errors">
                      {errors.dob && touched.dob && errors.dob}
                    </span> */}
                      </div>
                      <div class="form-group mt-4 pt-2">
                        <label>Mobile Number</label>
                        <input
                          type="number"
                          class="form-control"
                          placeholder="(+44)"
                          name="phone_number"
                          onChange={handleChange}
                          value={values.phone_number}
                        />
                        {/* <span className="formik-errors">
                      {errors.phone_number &&
                        touched.phone_number &&
                        errors.phone_number}
                    </span> */}
                      </div>

                    </>
                  }

                  {/* Post Code */}
                  <Col lg={12} className="mt-4">
                    <div className="sign-detail-input" style={{ textAlign: "left" }}>
                      <label>Postcode</label>
                      <div className="d-flex">
                        <InputGroup className="">
                          <input
                            type="search"
                            className="form-control post-code-srch"
                            placeholder="Type Here..."
                            name="post_code"
                            onChange={(e) => {
                              setPostcode(e.target.value);
                              handleChange(e);
                            }}
                            value={values.post_code}
                          />
                        </InputGroup>
                        <span className="formik-errors">
                          {errors.post_code && touched.post_code && errors.post_code}
                        </span>
                        <button type="button" onClick={handleKeyEnter}>
                          Search
                        </button>
                      </div>
                    </div>
                  </Col>

                  {/* Address */}
                  <div class="form-group mt-4 pt-2">
                    <div className="sign-detail-input post-code-types">
                      <label>Address</label>
                      <Field
                        as="select"
                        name="address"
                        aria-label="Default select example"
                        className="form-control form-select"
                        onChange={(e) => {
                          handleChange(e);
                          handleSearch(e, setFieldValue);
                        }}
                      >
                        <option>Select Address From List</option>
                        {addressData?.addresses?.map((item) => {
                          return (
                            <option
                              value={item?.formatted_address
                                ?.filter((item) => item !== "")
                                .join(", ")}
                            >
                              {item?.formatted_address
                                ?.filter((item) => item !== "")
                                .join(", ")}
                            </option>
                          );
                        })}
                      </Field>
                      <span className="formik-errors">
                        {errors.address && touched.address && errors.address}
                      </span>
                    </div>
                  </div>


                  <div class="form-group mt-4 pt-2">
                    <label>Country</label>
                    <CountrySelect
                      setFieldValue={setFieldValue}
                      country={values.country}
                    />

                    <span className="formik-errors">
                      {errors.country && touched.country && errors.country}
                    </span>
                  </div>
                  <button type="submit" class="common-btn">
                    UPDATE
                  </button>

                  {
                    tokenData?.role == 0 &&
                    <p className="mt-3">Want to upload content? <b onClick={accountChangeRequest}>Apply Now</b></p>
                  }

                </form>
              )}
            </Formik>
          </div>
        </div>
      </Container>
    </CreatorLayout >
  );
}
