import React, { useEffect } from "react";
import Layout from "../elements/Layout";
import { Container, Form } from "react-bootstrap";
import { useState } from "react";
import { Rating } from "react-simple-star-rating";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getStatics } from "../redux/actions/userAuth";
import { getAllCourses, getCoursesByWatchCount, getCoursesLastSevenDays, getLikedCreatorCourses } from "../redux/actions/userAction";
import parseDuration from "../utils/parseDuration ";
import useAuthToken from "../Hooks/useAuthToken";
export default function HomePage() {
  const [activeTab, setActiveTab] = useState("All");
  const [selectedFilterState, setSelectedFilterState] = useState("")
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tokenData = useAuthToken();

  const { statics, allCourses } = useSelector((state) => ({
    statics: state.userMgmtSlice.statics,
    allCourses: state.userMgmtSlice.allCourses,
  }));

  useEffect(() => {
    dispatch(getStatics());
    dispatch(getAllCourses({}));
  }, []);

  console.log(allCourses, "statics");
  const handleClick = (id) => {
    navigate(`/course-overview/${id}`);
  };

  const handleCourse = (id) => {
    setActiveTab(id);
    dispatch(getAllCourses({ categoryid: id }));
    setSelectedFilterState("")
  };

  const handleFilter = (value) => {
    setSelectedFilterState(value)
    setActiveTab("All")
    if (value === '1') {
      dispatch(getLikedCreatorCourses({ userId: tokenData?._id }))
    } else if (value === '2') {
      dispatch(getCoursesByWatchCount({ userId: tokenData?._id }))
    } else if (value === '3') {
      dispatch(getCoursesLastSevenDays({ userId: tokenData?._id }))
    } else {
      dispatch(getAllCourses({}));
    }
  }

  return (
    <Layout>
      <Container fluid>
        <div className="videos-sec-main">
          <div className="videos-tab d-flex justify-content-between align-items-center">
            <ul className="mb-0">
              <li
                onClick={() => {
                  setActiveTab("All");
                  dispatch(getAllCourses({}));
                }}
              >
                <button
                  type="button"
                  className={`filt-tabs ${activeTab == "All" && "active"}`}
                >
                  All
                </button>
              </li>
              {statics?.coursecategories?.map((item, i) => {
                return (
                  <>
                    <li onClick={() => handleCourse(item?._id)}>
                      <button
                        type="button"
                        className={`filt-tabs ${activeTab == item?._id && "active"
                          }`}
                      >
                        {item?.title}
                      </button>
                    </li>
                  </>
                );
              })}
            </ul>
            <Form.Select
              onChange={(e) => handleFilter(e.target.value)}
              aria-label="Default select example"
              value={selectedFilterState}
            >
              <option value="">All Courses</option>
              <option value="1">Most Liked </option>
              <option value="2">Highest Viewed</option>
              <option value="3">Most Viewed (last 7days)</option>
            </Form.Select>
          </div>

          <div className="videos-view">
            {allCourses?.courses?.map((item, i) => {
              const totalRatingSum = item?.rates?.reduce(
                (sum, rating) => sum + rating.rating,
                0
              );
              const averageRating = totalRatingSum / item?.rates?.length;
              const courseDuration = item?.chaptersarr?.reduce((sum, res) => sum + res.duration, 0);

              return (
                <>
                  <div className="video-inner">
                    <img
                      onClick={() => handleClick(item?._id)}
                      className="thumb-img"
                      src={
                        item?.cover_image
                          ? item?.cover_image
                          : require("../images/imagesplaceholder.png")
                      }
                    />
                    <div className="video-dec">
                      <div className="user-logo">
                        <img
                          src={
                            item?.creator_details?.profile_image
                              ? item?.creator_details?.profile_image
                              : require("../images/userico.png")
                          }
                        />
                      </div>
                      <div className="user-desc">
                        <h3>{item?.course_title}</h3>
                        <div className="bottom-desc">
                          <div className="bottom-first">
                            <p>{item?.creator_details?.full_name}</p>
                            <p>{item?.chapterViews} views</p>
                            <p>
                              {item?.cost == 0 ? "Free" : `£ ${item?.cost}`}
                            </p>
                          </div>
                          <div className="bottom-first">
                            <p>
                              {item?.chaptersarr?.length == 1
                                ? "1 Video,"
                                : item?.chaptersarr?.length + " Videos,"}{" "}
                              {parseDuration(courseDuration)}
                            </p>
                            <Rating initialValue={averageRating || 0} />
                            <p>({item?.rates?.length} Reviews)</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
            {allCourses?.courses?.length == 0 && (
              <div className="no-data">
                <p>No content available yet!</p>
              </div>
            )}
          </div>
        </div>
      </Container>
    </Layout>
  );
}
