import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Form, Link } from "react-router-dom";
import CreatorLayout from "../elements/CreatorLayout";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { changePass } from "../redux/actions/userAuth";
import toast from "react-hot-toast";
import moment from "moment";
import useAuthToken from "../Hooks/useAuthToken";

export default function CreatorSecurity() {
  const tokenData = useAuthToken();
  const dispatch = useDispatch();
  const [validateValue, setValidateValue] = useState(false);
  const validate = (values) => {
    const errors = {};
    if (!values.new_password) {
      errors.new_password = "Required";
    } else if (values.new_password != values.confirm_password) {
      errors.confirm_password = "Password Doesn't match";
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    validate,
    validateOnChange: validateValue,
    onSubmit: (values) => {
      setValidateValue(true);
      dispatch(
        changePass({
          newpassword: values?.confirm_password,
          oldpassword: values?.old_password,
        })
      ).then((data) => {
        if (data?.payload?.success) {
          formik.resetForm();
          toast.success(data?.payload?.message);
        }
        if (!data?.payload?.success) {
          toast.error(data?.payload?.message);
        }
      });
    },
  });
  return (
    <CreatorLayout>
      <Container>
        <div className="profile-detail-box">
          <div className="course-top-box">
            <div className="box-left">
              <h2>Password & Security</h2>
              <p>Update your password or delete your account</p>
            </div>
            <div className="box-right">
              <p>{tokenData?.full_name}</p>
              <span>
                Member Since:{" "}
                {moment(tokenData?.createdAt).format("DD/MM/YYYY")}
              </span>
            </div>
          </div>
          <div className="sign-form-inner mt-3">
            <form onSubmit={formik.handleSubmit}>
              <div class="form-group">
                <label for="exampleInputEmail1">Old Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Type your email here..."
                  name="old_password"
                  onChange={formik.handleChange}
                  value={formik.values.old_password}
                />
                {formik.errors.old_password && (
                  <div className="error_valid">
                    {formik.errors.old_password}
                  </div>
                )}
              </div>
              <div class="form-group mt-4 pt-2">
                <label>New Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="********"
                  name="new_password"
                  onChange={formik.handleChange}
                  value={formik.values.new_password}
                />
                {formik.errors.new_password && (
                  <div className="error_valid">
                    {formik.errors.new_password}
                  </div>
                )}
              </div>
              <div class="form-group mt-4 pt-2">
                <label>Confirm Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="********"
                  name="confirm_password"
                  onChange={formik.handleChange}
                  value={formik.values.confirm_password}
                />
                {formik.errors.confirm_password && (
                  <div className="error_valid">
                    {formik.errors.confirm_password}
                  </div>
                )}
              </div>
              <button type="submit" class="common-btn mt-4">
                UPDATE
              </button>
            </form>
          </div>
          <hr />

          {/* <div className="setting-delete-box">
            <h2>Delete Accounty</h2>
            <p>
              Once the account is deactivated, your account will not be able{" "}
              <br />
              to recovered again
            </p>
            <Link to="#" type="submit" class="common-btn">
              DELETE ACCOUNT
            </Link>
          </div> */}
        </div>
      </Container>
    </CreatorLayout>
  );
}
