import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import SignIn from "./pages/Signln";
import SignUp from "./pages/SignUp";
import ForgotPassword from "./pages/ForgotPassword";
import CreateAccount from "./pages/CreateAccount";
import ConfirmAccount from "./pages/ConfirmAccount";
import YourInterests from "./pages/YourInterests";
import ThankYou from "./pages/ThankYou";
import HomePage from "./pages/HomePage";
import Playback from "./pages/Playback";
import PlaybackProducts from "./pages/PlaybackProducts";
import Channels from "./pages/Channels";
import ChannelsDetail from "./pages/ChannelsDetail";
import CourseOverviewUnpaid from "./pages/CourseOverviewUnpaid";
import SettingsProfile from "./pages/SettingsProfile";
import SettingsBio from "./pages/SettingsBio";
import SettingsExpertise from "./pages/SettingsExpertise";
import SettingsAdditional from "./pages/SettingsAdditional";
import CourseOverviewpaid from "./pages/CourseOverviewPaid";
import CourseOverviewProducts from "./pages/CourseOverviewProducts";
import SettingsCertificates from "./pages/SettingsCertificates";
import SettingsInvoice from "./pages/SettingsInvoice";
import SettingsDownloadables from "./pages/SettingsDownloadables";
import SettingsPreference from "./pages/SettingsPreference";
import SettingsSecurity from "./pages/SettingsSecurity";
import UserProfileSettings from "./pages/UserProfileSettings";
import SettingsMyCourses from "./pages/SettingsMyCourses";
import SettingsCreateCourse from "./pages/SettingsCreateCourse";
import SettingsEditCourse from "./pages/SettingsEditCourse";
import SettingsAddChapter from "./pages/SettingsAddChapter";
import SettingsAddDocument from "./pages/SettingsAddDocument";
import SettingsChapterView from "./pages/SettingsChapterView";
import SettingsCreateQuiz from "./pages/SettingsCreateQuiz";
import SettingsAddQuestion from "./pages/SettingsAddQuestion";
import QuizSetting from "./pages/QuizSetting";
import ViewQuizChapter from "./pages/ViewQuizChapter";
import Reports from "./pages/Reports";
import CreatorCertificates from "./pages/CreatorCertificates";
import CreatorInvoice from "./pages/CreatorInvoice";
import CreatorDownloadables from "./pages/CreatorDownloadables";
import CreatorPreference from "./pages/CreatorPreference";
import CreatorSecurity from "./pages/CreatorSecurity";
import Quiz from "./pages/Quiz";
import Questions from "./pages/Questions";
import Downloads from "./pages/Downloads";
import Creators from "./pages/Creators";
import SingleCreatorDetail from "./pages/SingleCreatorDetail";
import LikesCourses from "./pages/LikesCourses";
import LikesCreators from "./pages/LikesCreators";
import MyCourses from "./pages/MyCourses";
import CreatorsShowAll from "./pages/CreatorsShowAll";
import VerifyOtp from "./pages/VerifyOtp";
import ResetPassword from "./pages/ResetPassword";
import PrivateRoute from "./PrivateRouteHoc.js/PrivateRoute";
import FileUpload from "./FileUpload ";
import ProgressBar from "@ramonak/react-progress-bar";
import { useContext, useEffect, useState } from "react";
import { SocketContext } from "./context/socket";
import { useSelector } from "react-redux";
import SettingsCourseView from "./pages/SettingsCourseView";
import SearchResults from "./pages/SearchResults";
import Wallet from "./pages/Wallet";
import PublicRoute from "./PrivateRouteHoc.js/PublicRoute";
import SettingsEditQuiz from "./pages/SettingsEditQuiz";
import LikeVideos from "./pages/LikeVideos";
import DownloadPopup from "./elements/DownloadPopup"
import BackgroundLoader from "./components/BackgroundLoader";
import MultipleLoader from "./components/MultipleLoader";
import useFirebaseToken from "./Hooks/useFirebaseToken";
import { initializeApp } from "@firebase/app";
import { getMessaging, getToken, onMessage } from "@firebase/messaging";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const firebaseConfig = {
  apiKey: "AIzaSyBzSAjC4eQN1teyzQKmxye5WomTQRrVpiw",
  authDomain: "buff-educatioin.firebaseapp.com",
  projectId: "buff-educatioin",
  storageBucket: "buff-educatioin.appspot.com",
  messagingSenderId: "755487534661",
  appId: "1:755487534661:web:ccb557438779c929a77d2c",
  measurementId: "G-D63C5J809M"
};

function App() {
  const { uploadProgress, uploadTime, video_title } = useSelector((state) => ({
    uploadProgress: state.userMgmtSlice.uploadProgress.percent,
    uploadTime: state.userMgmtSlice.uploadProgress.uploadTime,
    video_title: state.userMgmtSlice.uploadProgress.video_title,
  }));

  const uploads = useSelector(state => state.userMgmtSlice.multipleLoader);

  console.log("multiple", uploads)

  console.log("uploadProgress", uploadProgress);

  const [prog, setProg] = useState(0);
  const [toggle, setToggle] = useState(0);
  const [hide, setHide] = useState();
  const [lastHide, setLastHide] = useState();
  const [uploadSuccess, setUploadSuccess] = useState(1);
  const socketContext = useContext(SocketContext);
  const adminToken = window.localStorage.getItem("accessToken");

  console.log(video_title, "uploadTime----uploadTime");

  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);


  // useEffect(() => {

  //   socketContext.on('s3-progress', (progress) => {
  //     console.log("s3-progress",progress);
  //     if(progress == 'success') return setLastHide("remove");
  //     console.log(progress, "get-users");
  //     const progressPercentage = Math.round((progress.loaded / progress.total) * 100);
  //     console.log(`Upload progress: ${progressPercentage}%`);
  //     setProg(progressPercentage);
  //     setToggle(1);
  //     setHide();
  //     setUploadSuccess();
  //   })
  // }, [socketContext])

  useEffect(() => {
    if (uploadProgress) {
      if (uploadProgress == "success") return setLastHide("remove");
      const progressPercentage = uploadProgress;
      console.log(`Upload progress: ${progressPercentage}%`);
      setProg(progressPercentage);
      setToggle(1);
      setHide();
      setUploadSuccess();
    }
  }, [uploadProgress]);

  useEffect(() => {
    if (prog == 100) {
      setUploadSuccess(1);
      setTimeout(() => {
        setHide("hide");
      }, 2000);
    }
  }, [prog]);


  useEffect(() => {
    Notification.requestPermission()
      .then(permission => {
        if (permission === 'granted') {
          console.log('Notification permission granted.');
          getToken(messaging, { vapidKey: 'BMsmBNxZHUa_CZvYWkZJ4XncT8RxQnnK9BkCp1v3grwSnS-V4sQh6eMyaYLSbjQnEmek1ncPrxAtRWFwZMWiJ98' })
            .then(currentToken => {
              if (currentToken) {
                console.log('FCM Token:', currentToken);
                // You can send this token to your server to register the device
              } else {
                console.log('No registration token available. Request permission to generate one.');
              }
            })
            .catch(err => {
              console.log('An error occurred while retrieving token. ', err);
            });
        } else {
          console.log('Unable to get permission to notify.');
        }
      });
    // Handle foreground messages

    onMessage(messaging, payload => {
      console.log("messaging", messaging)
      console.log('Message received. ', payload);
      toast.info(`${payload.notification.title}: ${payload.notification.body}`);
    });
  }, []);

  console.log(uploadTime, video_title, "uploadProgressuploadProgress");
  return (
    <div className="App">
      {/* {prog != 0 && !hide && (
        <div className={toggle ? "chapter-upload" : "chapter-upload hide"}>
          <h6>{video_title}</h6>
          <button className="close-btn" onClick={() => setToggle(!toggle)}>
            <img src={require("./images/arrow-left.svg").default} />
          </button>
          <div className="chapter-upload-header">
            <h6>
              {uploadSuccess
                ? "Video Uploaded Successfully."
                : "Uploading Video"}
            </h6>
          </div>
          <ProgressBar
            completed={prog}
            bgColor={uploadSuccess ? "#2b742b" : "black"}
          />
          <p>
            Estimate Time Remaining : <b>{uploadTime}</b>
          </p>
          <h3>{prog?.originalname}</h3>
        </div>
      )} */}
      <MultipleLoader uploads={uploads} />

      <BrowserRouter>
        <DownloadPopup />
        <Routes>
          <Route element={<PublicRoute />}>
            <Route path="/" element={<SignIn />}></Route>
            <Route path="/SignIn" element={<SignIn />}></Route>
            <Route path="/forgot-password" element={<ForgotPassword />}></Route>
            <Route path="/sign-up" element={<SignUp />}></Route>
            <Route path="/verifyOtp/:userId" element={<VerifyOtp />}></Route>
            <Route path="/resetPass/:token" element={<ResetPassword />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Home />}></Route>
            <Route path="/home" element={<HomePage />}></Route>
            <Route path="/uploadFile" element={<FileUpload />} />
            <Route
              path="/playback/:courseId/:chapterId"
              element={<Playback />}
            ></Route>
            <Route
              path="/creators-show-all"
              element={<CreatorsShowAll />}
            ></Route>
            <Route
              path="/settings-courses"
              element={<SettingsMyCourses />}
            ></Route>
            <Route
              path="/settings-create-courses"
              element={<SettingsCreateCourse />}
            ></Route>
            <Route
              path="/settings-edit-course/:courseId"
              element={<SettingsEditCourse />}
            ></Route>
            <Route
              path="/settings-add-chapter/:courseId"
              element={<SettingsAddChapter />}
            ></Route>
            <Route
              path="/settings-add-document"
              element={<SettingsAddDocument />}
            ></Route>
            <Route
              path="/settings-chapter-view/:courseId/:chapterId"
              element={<SettingsChapterView />}
            ></Route>
            <Route
              path="/settings-create-quiz/:courseId/:chapterId"
              element={<SettingsCreateQuiz />}
            ></Route>
            <Route
              path="/settings-edit-quiz/:courseId/:chapterId"
              element={<SettingsEditQuiz />}
            ></Route>
            <Route
              path="/settings-add-question"
              element={<SettingsAddQuestion />}
            ></Route>
            <Route path="/quiz-setting" element={<QuizSetting />}></Route>
            <Route
              path="/view-quiz-chapter/:courseId/:chapterId"
              element={<ViewQuizChapter />}
            ></Route>
            <Route path="/reports" element={<Reports />}></Route>

            <Route
              path="/creator-certificates"
              element={<CreatorCertificates />}
            ></Route>
            <Route path="/creator-invoice" element={<CreatorInvoice />}></Route>
            <Route
              path="/creator-downloadables"
              element={<CreatorDownloadables />}
            ></Route>
            <Route
              path="/creator-preference"
              element={<CreatorPreference />}
            ></Route>
            <Route
              path="/creator-security"
              element={<CreatorSecurity />}
            ></Route>
            <Route
              path="/user-profile-settings"
              element={<UserProfileSettings />}
            ></Route>

            <Route
              path="/settings-security"
              element={<SettingsSecurity />}
            ></Route>

            <Route
              path="/settings-certificates"
              element={<SettingsCertificates />}
            ></Route>
            <Route
              path="/settings-invoice"
              element={<SettingsInvoice />}
            ></Route>
            <Route
              path="/settings-downloadables"
              element={<SettingsDownloadables />}
            ></Route>
            <Route
              path="/settings-preference"
              element={<SettingsPreference />}
            ></Route>

            <Route
              path="/playback-Products"
              element={<PlaybackProducts />}
            ></Route>
            <Route path="/channels" element={<Channels />}></Route>
            <Route
              path="/channels-details/:id"
              element={<ChannelsDetail />}
            ></Route>

            <Route
              path="/settings-profile"
              element={<SettingsProfile />}
            ></Route>
            <Route
              path="/course-overview/:courseId"
              element={<CourseOverviewUnpaid />}
            ></Route>
            <Route path="/Settings-bio" element={<SettingsBio />}></Route>
            <Route
              path="/settings-expertise"
              element={<SettingsExpertise />}
            ></Route>
            <Route
              path="/settings-additional"
              element={<SettingsAdditional />}
            ></Route>
            <Route
              path="/course-overview-paid/:courseId"
              element={<CourseOverviewpaid />}
            ></Route>
            <Route
              path="/course-overview-products"
              element={<CourseOverviewProducts />}
            ></Route>
            <Route path="/quiz" element={<Quiz />}></Route>
            <Route path="/questions" element={<Questions />}></Route>
            <Route path="/downloads" element={<Downloads />}></Route>
            <Route path="/creators" element={<Creators />}></Route>
            <Route
              path="/single-creator-detail/:id"
              element={<SingleCreatorDetail />}
            ></Route>
            <Route path="/likes-courses" element={<LikesCourses />}></Route>
            <Route path="/likes-creators" element={<LikesCreators />}></Route>
            <Route path="/likes-videos" element={<LikeVideos />}></Route>
            <Route path="/my-courses" element={<MyCourses />}></Route>
            <Route
              path="/setting-course-view"
              element={<SettingsCourseView />}
            ></Route>
            <Route path="/search-results" element={<SearchResults />}></Route>
            <Route path="/wallet" element={<Wallet />}></Route>
          </Route>
        </Routes>
        <ToastContainer limit={1} />
      </BrowserRouter>
    </div>
  );
}

export default App;
