import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Form, Link } from "react-router-dom";
// import ProgressBar from "@ramonak/react-progress-bar";
import Layout from "../elements/Layout";
import Navbar from "react-bootstrap/Navbar";
import CreatorLayout from "../elements/CreatorLayout";
import { Field, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import useAuthToken from "../Hooks/useAuthToken";
import { getUserById, userEditProfile } from "../redux/actions/userAuth";
import toast from "react-hot-toast";
import moment from "moment";

export default function CreatorPreference() {
  const dispatch = useDispatch();
  const tokenData = useAuthToken();
  const [file, setFile] = useState();
  const { getRegisterUser } = useSelector((state) => ({
    getRegisterUser: state.userMgmtSlice.getRegisterUser,
  }));
  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  useEffect(() => {
    dispatch(getUserById(tokenData?._id))
  }, []);
  return (
    <CreatorLayout>
      <Container>
        <div className="profile-detail-box">
          <div className="course-top-box">
            <div className="box-left">
              <h2>Contact Preference</h2>
              <p>Set your contact preferences here.</p>
            </div>
            <div className="box-right">
          <p>{getRegisterUser?.full_name}</p>
              <span>Member Since: {moment(getRegisterUser?.createdAt).format("DD/MM/YYYY")}</span>
          </div>
          </div>
          <Formik
            enableReinitialize={true}
            initialValues={{
              offersandpromotion: getRegisterUser?.offersandpromotion || false,
               pushnotificationfornewcouses: getRegisterUser?.pushnotificationfornewcouses ||  0,
               pushemailfornewcouses:  getRegisterUser?.pushemailfornewcouses || 0
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              console.log(values, "values---00");
              values.id = tokenData?._id;

              dispatch(userEditProfile(values)).then((data) => {
                if (data?.payload?.success) {
                  resetForm();
                  toast.success(data?.payload?.message);
                  window.location.reload()
                }
                setSubmitting(false);
              });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <form
                onSubmit={(e) => {
                  // setValidateValue(true);
                  handleSubmit(e);
                }}
              >
                <div className="invoice-list-box">
                  <div className="promotional-emails-box">
                    <h2>Promotional Emails</h2>
                    <div className="promotional-emails-check">
                      <p>
                        {" "}
                        I’d like to receive offers and promotions from Buff Browz and
                        partners via email
                      </p>
                     
                      <Field
                        className="form-check-input"
                        type="checkbox"
                        name="offersandpromotion"
                        value={values.offersandpromotion} 
                        onChange={(e) => {
                          setFieldValue("offersandpromotion", e.target.checked ? 1 : 0); 
                        }}
                        checked={values.offersandpromotion === 1} 
                      />
                    </div>
                  </div>
                  <div className="promotional-emails-box">
                    <h2>Push Notifications</h2>
                    <div className="promotional-emails-check">
                      <p>
                        {" "}
                        I’d like to receive offers and promotions from Buff Browz and
                        partners via email
                      </p>
                    
                      <Field
                        className="form-check-input"
                        type="checkbox"
                        name="pushnotificationfornewcouses"
                        value={values.pushnotificationfornewcouses} 
                        onChange={(e) => {
                          setFieldValue("pushnotificationfornewcouses", e.target.checked ? 1 : 0); 
                        }}
                        checked={values.pushnotificationfornewcouses === 1} 
                      />
                    </div>
                  </div>
                  <div className="promotional-emails-box">
                    <h2>Push Emails</h2>
                    <div className="promotional-emails-check">
                      <p>
                        {" "}
                        I’d like to receive offers and promotions from Buff Browz and
                        partners via email
                      </p>
                     
                       <Field
                        className="form-check-input"
                        type="checkbox"
                        name="pushemailfornewcouses"
                        value={values.pushemailfornewcouses} 
                        onChange={(e) => {
                          setFieldValue("pushemailfornewcouses", e.target.checked ? 1 : 0); 
                        }}
                        checked={values.pushemailfornewcouses === 1} 
                      />
                    </div>
                  </div>
                </div>
                <button to="#" type="submit" class="common-btn">
                  UPDATE
                </button>

              </form>

            )}
          </Formik>
        </div>
      </Container>
    </CreatorLayout>
  );
}
