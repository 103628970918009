import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Sidebar() {
  return (
    <div>
      <sidebar>
        {/* <div className="logo-main">
          <img src={require("../images/logo-mian.png")} />
        </div> */}
        <div className="btm-btns">
          <div>
            <Link
              to="/home"
              className={
                window.location.pathname == "/home"
                  ? "menu-btn bar_active"
                  : "menu-btn "
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M9.00036 17.9986C7.06009 17.9986 5.11773 18.0028 3.17746 17.9965C1.65445 17.9902 0.41309 17.0016 0.07928 15.5281C0.0229495 15.2826 0.00417273 15.0244 0.00417273 14.7704C-0.00208622 12.2726 0.00208631 9.77488 0 7.27711C0 6.10379 0.488198 5.19494 1.4479 4.53376C3.3381 3.23241 5.22831 1.92895 7.11851 0.627593C8.33066 -0.207796 9.6659 -0.209897 10.8801 0.625492C12.7703 1.92685 14.6605 3.22821 16.5528 4.53166C17.5104 5.19284 18.0007 6.10379 17.9986 7.27501C17.9986 9.77278 18.0028 12.2706 17.9965 14.7683C17.9924 16.4223 16.9179 17.6943 15.3052 17.9629C15.1133 17.9944 14.913 17.9944 14.7169 17.9944C12.8121 18.0007 10.9073 17.9986 9.00036 17.9986ZM9.00662 14.8355C9.70971 14.8355 10.4107 14.8376 11.1138 14.8334C11.2119 14.8334 11.3141 14.8292 11.4038 14.7977C11.5957 14.7305 11.698 14.5815 11.698 14.3779C11.698 14.1743 11.5957 14.0274 11.4038 13.9581C11.3266 13.9308 11.239 13.9245 11.1555 13.9224C9.71597 13.9203 8.27641 13.9203 6.83685 13.9224C6.75966 13.9224 6.68038 13.9308 6.60945 13.9539C6.41542 14.019 6.31527 14.1638 6.30276 14.3653C6.29233 14.5605 6.41959 14.7452 6.60736 14.7998C6.69916 14.8271 6.80139 14.8313 6.89736 14.8334C7.60044 14.8376 8.30353 14.8355 9.00662 14.8355Z"
                  fill="#B8B8B8"
                />
              </svg>
              Home
            </Link>
            <Link
              to="/channels"
              className={
                window.location.pathname == "/channels"
                  ? "menu-btn bar_active"
                  : "menu-btn "
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M7.47583 3.33567C7.4005 3.25475 7.35823 3.20694 7.3123 3.1628C6.64163 2.49157 5.97096 1.82033 5.30029 1.1491C4.98057 0.827276 4.95117 0.452118 5.22679 0.181786C5.50057 -0.0885471 5.8699 -0.0554449 6.19329 0.268219C7.07343 1.14726 7.95173 2.0263 8.83004 2.90718C8.88148 2.95867 8.92007 3.01936 9.0542 3.11867C9.08728 3.0543 9.10749 2.97523 9.15526 2.92557C10.0538 2.02079 10.9578 1.11783 11.86 0.214886C12.0401 0.0346644 12.2495 -0.0444105 12.5013 0.0273104C12.7438 0.0971924 12.8926 0.257188 12.9459 0.501775C12.9992 0.748201 12.9055 0.94681 12.7309 1.11968C12.0437 1.80378 11.3602 2.48973 10.6767 3.17384C10.6363 3.2143 10.5977 3.25843 10.5242 3.33567C10.6289 3.33567 10.6969 3.33567 10.763 3.33567C12.1044 3.33567 13.4457 3.33567 14.7871 3.33567C16.4353 3.3375 17.7196 4.43539 17.9714 6.0629C17.9934 6.21002 17.9971 6.36266 17.9971 6.51345C17.9989 9.28298 18.0026 12.0525 17.9971 14.8221C17.9934 16.3558 17.0085 17.5916 15.5294 17.9244C15.2887 17.9778 15.037 17.9962 14.7907 17.9962C10.9302 17.9998 7.06976 18.0017 3.2111 17.998C1.56107 17.9962 0.293229 16.9148 0.0304731 15.2928C0.00474875 15.1384 0.00291137 14.9802 0.00291137 14.8239C0.00107392 12.0544 -0.00260099 9.28483 0.00291137 6.51529C0.00658627 4.96318 1.0227 3.71266 2.52941 3.40187C2.74623 3.35774 2.9704 3.34118 3.19089 3.34118C4.53223 3.33567 5.87357 3.33935 7.21491 3.33935C7.28657 3.33567 7.35823 3.33567 7.47583 3.33567ZM6.60672 10.6512C6.60672 11.1477 6.60672 11.6461 6.60672 12.1426C6.60488 12.7513 6.85661 13.2221 7.39131 13.5145C7.92601 13.8069 8.46071 13.7646 8.96784 13.4299C9.83512 12.858 10.7006 12.2842 11.5605 11.7013C12.5252 11.0466 12.4425 9.64711 11.4061 9.11196C10.5352 8.66324 9.66056 8.22372 8.77859 7.79708C7.74961 7.29687 6.61407 8.01592 6.60672 9.15977C6.60304 9.6563 6.60672 10.1547 6.60672 10.6512Z"
                  fill="#0F0F0F"
                />
                <path
                  d="M7.8617 10.6604C7.8617 10.1767 7.85986 9.69308 7.8617 9.20942C7.86353 8.9299 8.02156 8.82508 8.26777 8.94645C9.10382 9.36022 9.93802 9.77767 10.7722 10.197C11.0387 10.3312 11.0478 10.5317 10.7961 10.7009C9.97109 11.2544 9.14791 11.8061 8.31739 12.3486C8.23286 12.4038 8.07852 12.4405 8.00318 12.4001C7.92601 12.3596 7.87088 12.2125 7.86905 12.1114C7.85435 11.6277 7.8617 11.1441 7.8617 10.6604Z"
                  fill="#0F0F0F"
                />
              </svg>
              Channels
            </Link>
            <Link
              to="/creators"
              className={
                window.location.pathname == "/creators"
                  ? "menu-btn bar_active"
                  : "menu-btn "
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="18"
                viewBox="0 0 17 18"
                fill="none"
              >
                <path
                  d="M12.4327 10.4629C11.7085 10.4629 11.0087 10.4629 10.3068 10.4629C9.83409 10.4629 9.3878 10.5642 8.98614 10.8117C8.25584 11.2618 7.86027 11.9067 7.85012 12.7386C7.8339 14.1453 7.84607 15.5501 7.84607 16.9568C7.84607 17.3192 7.95764 17.6504 8.15847 17.9855C8.0753 17.9913 8.01241 17.9991 7.94953 17.9991C5.83978 17.9991 3.72801 18.0011 1.61827 17.9991C0.634404 17.9972 0.00350968 17.3835 0.00350968 16.4346C0.00350968 14.7727 -0.0066333 13.1127 0.00756688 11.4507C0.0197385 9.78101 1.48033 8.38794 3.20261 8.37235C3.47038 8.37041 3.67527 8.46393 3.83147 8.67045C3.99579 8.88672 4.16011 9.10299 4.33457 9.31341C4.69566 9.74984 5.16832 10.007 5.75053 10.0362C6.15827 10.0557 6.56805 10.0538 6.97377 10.0284C7.47889 9.99533 7.89881 9.77322 8.22744 9.40693C8.44045 9.16923 8.63113 8.91399 8.82385 8.66071C8.96382 8.47951 9.14234 8.38015 9.37563 8.3743C10.6536 8.33923 11.9722 9.20235 12.4104 10.3577C12.4185 10.3792 12.4206 10.4045 12.4327 10.4629Z"
                  fill="#B8B8B8"
                />
                <path
                  d="M12.8526 17.9972C11.8728 17.9972 10.893 17.9991 9.91118 17.9972C9.16263 17.9952 8.71634 17.5705 8.71634 16.8535C8.71431 15.5267 8.71431 14.1979 8.71634 12.8711C8.71836 11.9222 9.35331 11.3085 10.3352 11.3066C12.0169 11.3046 13.6986 11.3046 15.3782 11.3066C16.3662 11.3085 16.9991 11.9164 16.9991 12.8652C17.0011 14.1999 16.9991 15.5325 16.9991 16.8671C16.9991 17.5647 16.5548 17.9952 15.8327 17.9972C14.8386 17.9991 13.8467 17.9972 12.8526 17.9972ZM12.2096 14.6441C12.2055 14.9889 12.4916 15.2734 12.8466 15.2792C13.2077 15.2851 13.516 14.9948 13.514 14.648C13.5119 14.309 13.2137 14.0245 12.8608 14.0245C12.5017 14.0226 12.2136 14.2992 12.2096 14.6441Z"
                  fill="#B8B8B8"
                />
                <path
                  d="M9.98421 3.47421C9.98421 4.37045 10.0755 5.26279 9.96392 6.13176C9.76918 7.66316 8.44653 8.7367 6.8338 8.78735C6.23739 8.80489 5.6349 8.83411 5.0466 8.68409C3.64484 8.32949 2.63663 7.09619 2.6204 5.70702C2.61431 5.15759 2.61837 4.60815 2.61837 4.05287C3.53327 4.29641 4.45831 4.15224 5.37524 4.17562C6.29419 4.199 7.21315 4.18341 8.1321 4.17757C8.77517 4.17367 9.34317 3.9613 9.84424 3.57747C9.89089 3.54435 9.93755 3.50928 9.98421 3.47421Z"
                  fill="#B8B8B8"
                />
                <path
                  d="M6.89668 0.00226009C7.69189 0.00226009 8.48913 0.000311743 9.28434 0.00226009C9.77526 0.00226009 10.0228 0.241907 10.0228 0.715356C10.0207 1.06801 10.0471 1.4265 9.99232 1.77136C9.84626 2.68903 9.03888 3.34172 8.06718 3.34562C6.49502 3.35342 4.92286 3.34952 3.35272 3.34757C2.88615 3.34757 2.61634 3.08455 2.61837 2.63837C2.6204 2.27988 2.60214 1.91554 2.65692 1.56289C2.79283 0.678337 3.57181 0.0275884 4.507 0.00810489C5.30221 -0.00748188 6.09945 0.00420885 6.89466 0.00420885C6.89668 0.00420885 6.89668 0.00420844 6.89668 0.00226009Z"
                  fill="#B8B8B8"
                />
              </svg>
              Creators
            </Link>
            <Link
              to="/my-courses"
              className={
                window.location.pathname == "/my-courses"
                  ? "menu-btn bar_active"
                  : "menu-btn "
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="18"
                viewBox="0 0 21 18"
                fill="none"
              >
                <path
                  d="M10.5092 0C13.2173 0 15.9254 0 18.6335 0C19.7409 0 20.5906 0.592042 20.8865 1.57878C20.9629 1.83024 20.9947 2.10398 20.9947 2.36499C21.0011 5.28382 21.0011 8.20583 20.9979 11.1247C20.9947 12.5379 20.0432 13.4897 18.6335 13.4897C13.2109 13.4928 7.7852 13.4928 2.36264 13.4897C0.949725 13.4897 0.00459658 12.5379 0.00141433 11.1247C0.00141433 8.18992 -0.00176792 5.25199 0.00141433 2.31724C0.00141433 1.14907 0.73015 0.248276 1.84394 0.0350133C2.0476 0 2.25445 0.00318302 2.45811 0.00318302C5.14075 0 7.82657 0 10.5092 0ZM8.24981 6.73846C8.24981 7.46737 8.25299 8.19629 8.24981 8.92838C8.24663 9.25623 8.36755 9.51406 8.66032 9.66366C8.95627 9.81326 9.23631 9.75915 9.49725 9.56499C10.4615 8.83926 11.4321 8.11989 12.3931 7.39098C12.8768 7.02175 12.8736 6.47427 12.3899 6.10822C11.4321 5.38568 10.471 4.6695 9.51316 3.94695C9.24585 3.74642 8.96263 3.67958 8.65714 3.83873C8.36119 3.99151 8.24663 4.24934 8.24981 4.57719C8.25299 5.29337 8.24981 6.01591 8.24981 6.73846Z"
                  fill="#B8B8B8"
                />
                <path
                  d="M13.8506 17.252C11.7376 17.252 9.62772 17.252 7.51471 17.2488C7.37151 17.2488 7.27604 17.2806 7.18057 17.4048C6.5664 18.2005 5.41761 18.1973 4.8098 17.4016C4.71433 17.2775 4.61886 17.2488 4.47566 17.2488C3.26004 17.252 2.04124 17.252 0.825617 17.2488C0.357826 17.2488 0.0236901 16.9623 0.00141431 16.5517C-0.0240437 16.0965 0.30691 15.7496 0.78743 15.7464C2.00305 15.7432 3.22185 15.7432 4.43747 15.7464C4.60295 15.7464 4.71433 15.7114 4.82889 15.5682C5.43033 14.7979 6.57595 14.8042 7.17103 15.5745C7.27604 15.7114 7.38105 15.7464 7.54335 15.7464C11.7439 15.7432 15.9413 15.7432 20.1419 15.7432C20.5779 15.7432 20.877 15.9469 20.9693 16.2971C21.0934 16.7714 20.7592 17.2202 20.2564 17.2424C20.0146 17.2552 19.7696 17.2456 19.5277 17.2456C17.6343 17.252 15.744 17.252 13.8506 17.252Z"
                  fill="#B8B8B8"
                />
              </svg>
              My Courses
            </Link>
            <Link
              to="/likes-courses"
              className={
                window.location.pathname == "/likes-courses"
                  ? "menu-btn bar_active"
                  : "menu-btn "
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="18"
                viewBox="0 0 20 18"
                fill="none"
              >
                <path
                  d="M20 5.61745C20 5.91948 20 6.2235 20 6.52553C19.9901 6.56954 19.9743 6.61354 19.9703 6.65955C19.8674 7.85966 19.4378 8.93975 18.7924 9.94185C18.1134 10.9979 17.284 11.928 16.3615 12.7601C14.5264 14.4163 12.6616 16.0404 10.8028 17.6686C10.2782 18.1286 9.69222 18.1046 9.14387 17.6286C7.72252 16.3944 6.29918 15.1603 4.88575 13.9162C3.68611 12.8601 2.52211 11.768 1.56794 10.4739C0.841424 9.48581 0.281197 8.41371 0.095115 7.18359C-0.199845 5.24341 0.174299 3.45325 1.4551 1.93911C2.7359 0.424971 4.39877 -0.175084 6.34669 0.0749387C7.6077 0.236954 8.62719 0.883012 9.4804 1.8131C9.66055 2.00912 9.82287 2.21914 10.005 2.43716C10.0941 2.32115 10.1713 2.21514 10.2524 2.11513C10.3395 2.00912 10.4286 1.90311 10.5217 1.8031C12.0717 0.154945 13.9424 -0.397105 16.0863 0.286958C18.0956 0.927017 19.2695 2.40915 19.7961 4.43734C19.8971 4.82338 19.9347 5.22341 20 5.61745Z"
                  fill="#B8B8B8"
                />
              </svg>
              Likes
            </Link>
            <Link
              to="/settings-profile"
              className={
                window.location.pathname == "/settings-profile"
                  ? "menu-btn bar_active"
                  : "menu-btn "
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
              >
                <path
                  d="M6.64997 18C6.50962 17.9679 6.36326 17.9519 6.22893 17.9018C5.22246 17.5349 4.31023 17.0036 3.4862 16.32C3.06317 15.9691 2.96292 15.4559 3.18547 14.9046C3.57843 13.9263 3.43007 13.0281 2.73235 12.2382C2.34741 11.8032 1.8522 11.5546 1.27478 11.4744C0.553008 11.3742 0.218186 11.0574 0.103906 10.3277C-0.0604973 9.28919 -0.0264137 8.2507 0.182098 7.22024C0.292368 6.67293 0.677313 6.33813 1.25072 6.26195C2.88673 6.04544 3.84308 4.4817 3.28772 2.92999C3.07319 2.33056 3.21554 1.84941 3.71877 1.46248C4.4646 0.88711 5.2786 0.432022 6.15876 0.099227C6.7061 -0.107266 7.18528 0.0110162 7.56621 0.45207C8.64486 1.70306 10.3671 1.70506 11.4417 0.458084C11.8307 0.00500187 12.3018 -0.109271 12.8632 0.103237C13.8276 0.468108 14.7057 0.97933 15.5017 1.63289C15.9588 2.00779 16.0951 2.47089 15.9047 3.02622C15.3834 4.54986 16.2535 6.04944 17.8314 6.34214C18.4188 6.4504 18.7476 6.7852 18.8559 7.36659C19.0584 8.44316 19.0424 9.51573 18.8399 10.5903C18.7416 11.1075 18.3507 11.4744 17.8194 11.5566C16.1894 11.8032 15.2671 13.379 15.8465 14.9267C16.055 15.482 15.9247 15.9651 15.4676 16.342C14.7278 16.9475 13.9158 17.4326 13.0316 17.7955C12.5003 18.014 11.981 17.8757 11.6161 17.4246C10.5235 16.0694 8.67092 16.0934 7.61433 17.4788C7.37174 17.7975 7.06097 17.9739 6.64997 18ZM12.6707 8.97443C12.6707 7.24831 11.2472 5.8229 9.52101 5.8209C7.7627 5.8209 6.33519 7.23428 6.3372 8.97844C6.3392 10.7346 7.75067 12.148 9.50497 12.152C11.2533 12.154 12.6707 10.7306 12.6707 8.97443Z"
                  fill="#B8B8B8"
                />
              </svg>
              Settings
            </Link>
          </div>
          <div></div>
        </div>
      </sidebar>
    </div>
  );
}
