import React, { useEffect, useState } from "react";
import Select from "react-select";
import { countryList } from "../../utils/rawData";

const countries = countryList?.map((item) => {
  return { label: item?.name, value: item?.name };
});

const CountrySelect = ({ setFieldValue, country }) => {
  const [selectedCountry, setSelectedCountry] = useState(null);

  const handleChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setFieldValue("country", selectedOption?.value);
  };

  useEffect(() => {
    if (country) {
      setSelectedCountry({ label: country, value: country });
    }
  }, [country]);

  return (
    <div className="country-select-signup">
      <Select
        value={selectedCountry}
        onChange={handleChange}
        options={countries}
        placeholder="Please select"
      />
    </div>
  );
};

export default CountrySelect;
