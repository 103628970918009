import React, { useEffect } from "react";
import Layout from "../elements/Layout";
import { Container, Row, Col } from "react-bootstrap";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Rating } from "react-simple-star-rating";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllCompletedCourses, getAllCourses, getCompletedCourse } from "../redux/actions/userAction";
import parseDuration from "../utils/parseDuration ";

export default function MyCourses() {
  const dispatch = useDispatch();
  const [rating, setRating] = useState(0);
  const navigate = useNavigate();
  const { allCourses, allCompletedCourses, completeCourse } = useSelector((state) => ({
    allCourses: state.userMgmtSlice.allCourses,
    allCompletedCourses: state.userMgmtSlice.allCompletedCourses,
    completeCourse: state.userMgmtSlice.completeCourse
  }));

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    dispatch(getAllCourses({ purchases: "yes" }));
    dispatch(getAllCompletedCourses({}));
    dispatch(getCompletedCourse({}))
  }, []);

  console.log(completeCourse, "completeCourse");

  const handleClick = (id) => {
    navigate(`/course-overview/${id}`);
  };

  let courseArr = allCompletedCourses?.courses?.filter((item) => item?.courseCompleted);
  let inProgressCourse = allCompletedCourses?.courses?.filter((item) => item?.inProgress);

  console.log(inProgressCourse, "inProgressCourse");

  return (
    <Layout>
      <Container fluid>
        <div className="details-videos">
          <h2 className="mb-2">Current Courses</h2>
          <p className="sub-heading">
            {inProgressCourse?.length} Courses in Progress
          </p>

          <div className="videos-sec-main mt-4">
            <div className="videos-view likes-courses">
              {inProgressCourse?.map((item, i) => {
                const totalRatingSum = item?.rates?.reduce(
                  (sum, rating) => sum + rating.rating,
                  0
                );
                const averageRating = totalRatingSum / item?.rates?.length;

                return (
                  <>
                    <div className="video-inner">
                      <img
                        onClick={() => handleClick(item?._id)}
                        className="thumb-img"
                        src={
                          item?.cover_image
                            ?
                            item?.cover_image
                            : require("../images/imagesplaceholder.png")
                        }
                      />
                      <div className="video-dec">
                        <div className="user-logo">
                          <img
                            src={
                              item?.creator_details?.profile_image
                                ?
                                item?.creator_details?.profile_image
                                : require("../images/userico.png")
                            }
                          />
                        </div>
                        <div className="user-desc">
                          <h3>{item?.course_title}</h3>
                          <div className="bottom-desc">
                            <div className="bottom-first">
                              <p>{item?.creator_name}</p>
                              <p>{item?.viewcount} views</p>
                              <p>
                                {item?.cost == 0 ? "Free" : `£ ${item?.cost}`}
                              </p>
                            </div>
                            <div className="bottom-first">
                              <p>
                                {item?.chapters == 1
                                  ? item?.chapters + " Video,"
                                  : item?.chapters + " Videos,"}{" "}
                                {parseDuration(item?.duration)}
                              </p>
                              <Rating initialValue={averageRating || 0} />
                              <p>({`${item?.rates?.length} ${item?.rates?.length == 1 ? `Review` : `Reviews`}`})</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            <div className="creator-show-div">
              <p>Show All</p>
            </div>
          </div>
        </div>
        <div className="details-videos mt-4">
          <div className="d-flex justify-content-between">
            <div>
              <h2 className="mb-2">Completed Courses</h2>
              <p className="sub-heading">Well Done! {completeCourse?.courses?.length} Courses Completed</p>
            </div>
            <div>
              {/* <button className="filter-btn">
                <img src={require("../images/Filter_big.svg").default} />
              </button> */}
            </div>
          </div>
          <div className="videos-sec-main mt-4">
            <div className="videos-view likes-courses">

              {completeCourse?.courses?.map((item, i) => {
                const totalRatingSum = item?.rates?.reduce(
                  (sum, rating) => sum + rating.rating,
                  0
                );
                const averageRating = totalRatingSum / item?.rates?.length;

                return (
                  <>
                    {
                      <div className="video-inner">
                        <img
                          onClick={() => handleClick(item?._id)}
                          className="thumb-img"
                          src={
                            item?.cover_image
                              ?
                              item?.cover_image
                              : require("../images/imagesplaceholder.png")
                          }
                        />
                        <div className="video-dec">
                          <div className="user-logo">
                            <img
                              src={
                                item?.creator_details?.profile_image
                                  ?
                                  item?.creator_details?.profile_image
                                  : require("../images/userico.png")
                              }
                            />
                          </div>
                          <div className="user-desc">
                            <h3>{item?.course_title}</h3>
                            <div className="bottom-desc">
                              <div className="bottom-first">
                                <p>{item?.creator_name}</p>
                                <p>{item?.viewcount} views</p>
                                <p>
                                  {item?.cost == 0 ? "Free" : `£ ${item?.cost}`}
                                </p>
                              </div>
                              <div className="bottom-first">
                                <p>
                                  {item?.chapters == 1
                                    ? item?.chapters + " Video,"
                                    : item?.chapters + " Videos,"}{" "}
                                  {parseDuration(item?.duration)}
                                </p>
                                <Rating initialValue={averageRating || 0} />
                                <p>({`${item?.rates?.length} ${item?.rates?.length == 1 ? `Review` : `Reviews`}`})</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }

                  </>
                );
              })}

              {
                completeCourse?.courses?.length == 0 && <p>No data available yet!</p>
              }

            </div>
          </div>
        </div>
      </Container>
      <Modal show={show} onHide={handleClose} className="social-detail-popup">
        <Modal.Header closeButton>
          <Modal.Title>About</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="creator-bio-desc">
            <p>
              This is a sample bio. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Pellentesque id commodo nunc. Fusce enim ligula,
              consectetur et velit nec, rutrum hendrerit risus. Proin non
              tristique ipsum. Quisque nec ante dignissim, lobortis tortor at,
              bibendum tellus. Praesent ultrices odio urna, et volutpat ex
              placerat id. Aliquam ante eros, scelerisque et leo id, convallis
              rhoncus odio. Donec elit nibh, eleifend sit amet dignissim sed,
              semper vitae leo. Lorem Ipsum is simply dummy text of the printing
              and typesetting industry. Lorem Ipsum has been the industry's
              standard dummy text ever since the 1500s, when an unknown printer
              took a galley of type and scrambled it to make a type specimen
              book. It has survived not only five centuries, but also the leap
              into electronic typesetting, remaining essentially unchanged. It
              was popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>

            <h3>Expertise</h3>
            <ul>
              <li>Brow Lamination</li>
              <li>HD Brows</li>
              <li>Combination Brows</li>
            </ul>

            <div className="social-links mt-5">
              <h4>Links</h4>
              <div className="social-inner">
                <img src={require("../images/instas.svg").default} />

                <div className="social-desc">
                  <h4>Instagram</h4>
                  <Link to="#">Instagram.com/alleybostan</Link>
                </div>
              </div>
              <div className="social-inner">
                <img src={require("../images/faceb.svg").default} />

                <div className="social-desc">
                  <h4>Facebook</h4>
                  <Link to="#">Facebook.com/alleybostan</Link>
                </div>
              </div>
              <div className="social-inner">
                <img src={require("../images/social.svg").default} />

                <div className="social-desc">
                  <h4>Instagram</h4>
                  <Link to="#">Instagram.com/alleybostan</Link>
                </div>
              </div>
            </div>

            <div className="status">
              <h3>Stats</h3>
              <ul>
                <li>Joined: June 2023</li>
                <li>Videos: 23</li>
                <li>Views: 23,312 </li>
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  );
}
