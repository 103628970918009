import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { EditQuestion, getQuizlists } from "../redux/actions/userAction";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

export default function SettingsEditQuestion({
    setPage,
    setFieldValue,
    values,
    quesById
}) {
    console.log("values", values)
    console.log("quesById", quesById)
    const { courseId, chapterId } = useParams();
    const dispatch = useDispatch();
    const [stateVal, setStateVal] = useState({
        questiontitle: "",
        option: [{ optiontitle: "", answer: 0 }],
    });

    const [errors, setErrors] = useState({
        questiontitle: "",
        option: [{ title: "", error: "" }],
    });

    useEffect(() => {
        setStateVal(quesById)
    }, [quesById])

    const handleStateVal = (e) => {
        const { name, value } = e.target;
        setStateVal((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: value.trim() ? "" : "Question title is required.",
        }));
    };

    const handleOptionChange = (index, value) => {
        const updatedOptions = stateVal.option.map((opt, i) =>
            i === index ? { ...opt, optiontitle: value } : opt
        );

        setStateVal((prevState) => ({
            ...prevState,
            option: updatedOptions,
        }));

        setErrors((prevErrors) => {
            const updatedOptionErrors = prevErrors.option.map((err, i) =>
                i === index ? { ...err, title: value, error: value.trim() ? "" : "Option is required." } : err
            );
            return { ...prevErrors, option: updatedOptionErrors };
        });
    };


    const handleRadioChange = (index) => {
        const updatedOptions = stateVal.option.map((opt, i) => ({
            ...opt,
            answer: i === index,
        }));
        setStateVal((prevState) => ({
            ...prevState,
            option: updatedOptions,
        }));
    };

    const handleSubmit = (e) => {
        console.log("ahndleSubmit", e)
        e.preventDefault();
        // Validate question title and all options
        const newErrors = {
            questiontitle: stateVal.questiontitle.trim()
                ? ""
                : "Question title is required.",
            option: stateVal.option.map((opt) => ({
                title: opt.optiontitle,
                error: opt.optiontitle.trim() ? "" : "Option is required.",
            })),
        };

        if (
            newErrors.questiontitle ||
            newErrors.option.some((opt) => opt.error !== "")
        ) {
            setErrors(newErrors);
            return;
        }

        // Check if at least one option is marked as correct
        if (!stateVal.option.some((opt) => opt.answer)) {
            alert("Please select a correct answer.");
            return;
        }

        // Proceed with form submission
        console.log("Form submitted:", stateVal);

        dispatch(EditQuestion(stateVal)).then((data) => {
            if (data?.payload?.success) {
                dispatch(getQuizlists({ chapterId }));
                toast.success(data?.payload?.message);
                setFieldValue(`question`, [...values.question, stateVal]);
                // Reset form fields or navigate to another page
                setStateVal({
                    questiontitle: "",
                    option: [{ optiontitle: "", answer: 0 }],
                });
                setErrors({
                    questiontitle: "",
                    option: [{ title: "", error: "" }],
                });
                setPage(0); // Navigate to another page, if needed
            }
        });


    };

    console.log("stateVal-----", stateVal)

    return (
        <>
            <div className="profile-top-box">
                <div className="backpage-arrow">
                    <Link to={`/settings-chapter-view/${courseId}/${chapterId}`}>
                        <span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                viewBox="0 0 40 40"
                                fill="none"
                            >
                                <path
                                    d="M10 20.0003L8.93934 21.061L7.87868 20.0003L8.93934 18.9397L10 20.0003ZM30 18.5003C30.8284 18.5003 31.5 19.1719 31.5 20.0003C31.5 20.8288 30.8284 21.5003 30 21.5003V18.5003ZM15.606 27.7277L8.93934 21.061L11.0607 18.9397L17.7273 25.6063L15.606 27.7277ZM8.93934 18.9397L15.606 12.273L17.7273 14.3943L11.0607 21.061L8.93934 18.9397ZM10 18.5003H30V21.5003L10 21.5003L10 18.5003Z"
                                    fill="#40413A"
                                />
                            </svg>
                        </span>
                        Back to the previous page.
                    </Link>
                </div>
            </div>
            <div className="edit-course-box">
                <div className="edit-cou-top">
                    <div className="box-left">
                        <h2>Edit Quiz</h2>
                    </div>
                </div>
                <hr />
                <div className="edit-cou-top">
                    <div className="box-left">
                        <p className="m-0">Edit your quiz questions here</p>
                    </div>
                    <div className="box-right"></div>
                </div>
                <div className="sign-form-inner">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group mt-4 pt-2">
                            <label htmlFor="questionTitle">Question Title</label>
                            <input
                                type="text"
                                className="form-control"
                                id="questionTitle"
                                placeholder="Type here..."  
                                name="questiontitle"
                                value={stateVal.questiontitle}
                                onChange={handleStateVal}
                            />
                            {errors.questiontitle && (
                                <small className="text-danger">{errors.questiontitle}</small>
                            )}
                        </div>

                        {Array.isArray(stateVal?.option) && stateVal?.option?.length > 0 && stateVal?.option.map((option, index) => (
                            <div className="form-group mt-4 pt-2" key={option?._id}>
                                <label htmlFor={`option${index + 1}`}>{`Answer Option ${index + 1
                                    }`}</label>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id={`option${index + 1}`}
                                        placeholder="Type here..."
                                        value={option?.optiontitle}
                                        onChange={(e) => handleOptionChange(index, e.target.value)}
                                    />

                                    <div className="form-check ml-3 check-correct">
                                        <input
                                            type="radio"
                                            className="form-check-input correct-input"
                                            id={`correctOption${index + 1}`}
                                            checked={option?.answer}
                                            onChange={() => handleRadioChange(index)}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor={`correctOption${index + 1}`}
                                        >
                                            Correct
                                        </label>
                                    </div>
                                </div>

                                {/* {errors?.option[index].error && (
                                    <small className="text-danger">
                                        {errors.option[index].error}
                                    </small>
                                )} */}
                            </div>
                        ))}

                        <button type="submit" className="common-btn">
                            EDIT QUESTION
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
}
