import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Form, Link } from "react-router-dom";
// import ProgressBar from "@ramonak/react-progress-bar";
import Layout from "../elements/Layout";
import Navbar from "react-bootstrap/Navbar";
import SettingLayout from "../elements/SettingLayout";
export default function SettingsInvoice() {
  const [file, setFile] = useState();
  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  return (
    <SettingLayout>
      <Container>
        <div className="profile-detail-box">
          <div className="course-top-box ">
            <div className="box-left">
              <h2>Invoice</h2>
              <p>See all of your purchases</p>
            </div>
            <div className="box-right">
              <p>Megan Bulmer</p>
              <span>Member Since: 12/06/2023</span>
            </div>
          </div>
          <div className="invoice-list-box">
            <div className="invoice-list-item">
              <div className="box-left">
                <h2>Tami Lips & Tricks</h2>
                <p>By Megan Bulmer</p>
              </div>
              <div className="box-right">
                <p>£20.00</p>
                <span>01/04/2024</span>
              </div>
            </div>
            <hr />
            <div className="invoice-list-item">
              <div className="box-left">
                <h2>Tami Lips & Tricks</h2>
                <p>By Megan Bulmer</p>
              </div>
              <div className="box-right">
                <p>£20.00</p>
                <span>01/04/2024</span>
              </div>
            </div>
            <hr />
            <div className="invoice-list-item">
              <div className="box-left">
                <h2>Tami Lips & Tricks</h2>
                <p>By Megan Bulmer</p>
              </div>
              <div className="box-right">
                <p>£20.00</p>
                <span>01/04/2024</span>
              </div>
            </div>
            <hr />
            <div className="invoice-list-item">
              <div className="box-left">
                <h2>Tami Lips & Tricks</h2>
                <p>By Megan Bulmer</p>
              </div>
              <div className="box-right">
                <p>£20.00</p>
                <span>01/04/2024</span>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </SettingLayout>
  );
}
