import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Form, Link } from "react-router-dom";
// import ProgressBar from "@ramonak/react-progress-bar";
import Navbar from "react-bootstrap/Navbar";
import SettingLayout from "../elements/SettingLayout";
export default function UserProfileSettings() {
  const [file, setFile] = useState();
  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  return (
    <SettingLayout>

      <Container>
        <div className="profile-detail-box">
          <div className="course-top-box">
            <div className="box-left">
              <h2>Profile</h2>
              <p>Edit your Personal Details</p>
            </div>
            <div className="box-right">
              <p>Megan Bulmer</p>
              <span>Member Since: 12/06/2023</span>
            </div>
          </div>
          <div className="sign-form-inner">
            <form>
              <div class="form-group mb-4">
                <h2>Update Profile Picture</h2>
                <div className="img-upload-main">
                  <div className="img-upload">
                    <img
                      className="upload-img"
                      src={require("../images/imageupload.svg").default}
                    />
                    <input type="file" onChange={handleChange}></input>

                    <img className="show-img" src={file} />
                  </div>
                  <p>Add a profile picture </p>
                </div>
              </div>
              <hr />
              <h2 className="mt-5">Update Profile Picture</h2>
              <div class="form-group">
                <label for="exampleInputEmail1">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  aria-describedby="emailHelp"
                  placeholder="Type your name here..."
                />
              </div>
              <div class="form-group mt-4 pt-2">
                <label>Date of Birth</label>
                <input
                  type="date"
                  class="form-control"
                  placeholder="--/--/----"
                />
              </div>

              <div class="form-group mt-4 pt-2">
                <label>Mobile Number</label>
                <input type="number" class="form-control" placeholder="(+44)" />
              </div>
              <div class="form-group mt-4 pt-2">
                <label>Address</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Type the first line of your address to search"
                />
              </div>
              <div class="form-group mt-4 pt-2">
                <label>Country</label>
                <select class="form-select" aria-label="Default select example">
                  <option selected>select</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
              <Link to="#" type="submit" class="common-btn">
                UPDATE
              </Link>
            </form>
          </div>
        </div>
      </Container>
    </SettingLayout>
  );
}
