import { Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { resendOtp, verifyOtp } from "../redux/actions/userAuth";
import { Container, Navbar } from "react-bootstrap";

const VerifyOtp = () => {
  const navigate = useNavigate();
  let { userId } = useParams();
  const dispatch = useDispatch();
  const handleResendOtp = () => {
    dispatch(resendOtp({ user_id: userId, type: 1 })).then((data) => {
      if (data?.payload?.success) {
        toast.success(data?.payload?.message);
      }
    });
  };
  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary landing-nav">
        <Container fluid>
          <Navbar.Brand href="#home" className="logo-main-nav">
            <img src={require("../images/logo-mian.png")} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          {/* <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="#home">Home</Nav.Link>
              <Nav.Link href="#link">Contact Us</Nav.Link>
            </Nav>
            <div className="main-login-btn">
              <Link to="/SignIn">Login</Link>
            </div>
          </Navbar.Collapse> */}
        </Container>
      </Navbar>
      <div className="sign-forms-cmn-box">
        <div className="sign-form-inner">
          <h2 className="h-22">OTP Verify </h2>

          {/* <span>
                We’ve sent a one time code to your {getRegisterUser?.email} Please enter it
                below to continue.
            </span> */}
          <Formik
            initialValues={{
              otp: "",
              user_id: "",
              type: 1,
            }}
            validate={(values) => {
              const errors = {};

              if (!values.otp) {
                errors.otp = "Required*";
              }

              return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              values.user_id = userId;

              dispatch(verifyOtp(values)).then((data) => {
                if (data?.payload?.success) {
                  resetForm();
                  toast.success(data?.payload?.message);
                  navigate(`/resetPass/${data?.payload?.data?.token}`);
                }
                setSubmitting(false);
              });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <form
                onSubmit={(e) => {
                  // setValidateValue(true);
                  handleSubmit(e);
                }}
              >
                <div class="form-group mt-4 pt-2">
                  <label>OTP</label>
                  <input
                    type="password"
                    className="form-control"
                    aria-describedby="emailHelp"
                    placeholder="****"
                    name="otp"
                    onChange={handleChange}
                    value={values.otp}
                  />
                  <span className="formik-errors">
                    {errors.otp && touched.otp && errors.otp}
                  </span>
                </div>

                <div className="confirm-links mt-4">
                  <span>Not Received?</span>
                  <p style={{ cursor: "pointer" }} onClick={handleResendOtp}>
                    Send Code Again
                  </p>
                </div>
                <button type="submit" class="common-btn">
                  CONTINUE
                </button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default VerifyOtp;
